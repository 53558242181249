<template>
   <div>
      <div class="pinspy-load" v-if = "loading"><span class="load"></span></div>
      <div class="login-form" :style="{backgroundImage: `url(${backgroundUrl})`}" >
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-lg-6">
                  <div class="card px-5">
                     <div class="card-header email-header text-center bg-transparent">
                        <router-link class="mr-4 ml-0" :to="{name: 'password-reset'}">
                           <img :src="require('@/assets/images/arrow-left.svg')">
                        </router-link>
                        <div class="for-email mt-3">
                           <h1 class="heading-enter-email">Create Password</h1>
                           <p class="Enter-email"> Please create your new password.</p>
                        </div>
                     </div>
                     <div class="card-body py-4 px-0">
                        <Form @submit="onSubmit" >
                           <div class="alert alert-danger" v-if="showError">{{errorMsg}}</div>
                           <div class="alert alert-success" v-if="showSuccess">{{message}}</div>
                           <div class="form-group" >
                              <label for="name">New Password</label>
                              <Field type="password" class="form-control shadow-none"  name="password" :rules="password" placeholder="Enter your password"/>
                              <ErrorMessage name="password" />
                           </div>
                           <div class="form-group">
                              <label for="name" class="error-mes-color">Confirm Password</label>
                              <Field type="password" class="form-control shadow-none error-color-inpt" :rules="confirm_password" name="confirm_password" placeholder="Confirm your password"/>
                              <ErrorMessage name="confirm_password" />
                           </div>
                           <div class="login-btn">
                              <button type="submit" class="btn btn-done">Submit
                              <img :src="require('@/assets/images/arrow-right.svg')">
                              </button>
                           </div>
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import backgroundUrl from "@/assets/images/login-banner.png";
import logoImage from "@/assets/images/logo-1.png";
import backArrow from "@/assets/images/back_arrow.svg"
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
   
   export default {
     name: 'CreatePassword',
     components: {
       Form,
       Field,
       ErrorMessage
     },
     data() {
       return {
         backgroundUrl,
         logoImage,
         backArrow,
         password: yup.string().required().min(8),
         confirm_password: yup.string().required().min(8),
         showError: false,
         showSuccess: false,
         errorMsg: '',
         loading: false,
         message: "", 
       }
     },
     created(){
         localStorage.removeItem("filters");
     },
     methods: {
       onSubmit(data) {
         var self = this
         let parameters = this.$route.query
         data.token = parameters.token;
         this.loading = true;
         this.$store.dispatch("auth/resetpassword", data).then(
             (response) => {
                if(response.status==true){
                  this.showError = false;
                  this.loading = false;
                  this.errorMsg = '';
                  this.showSuccess = true;
                  this.message = response.message; 
                  setTimeout(function(){ self.$router.push("/login"); }, 3000);
   
                }else{
                 this.loading = false;
                  this.showSuccess = false;
                  this.showError = true;
                  this.errorMsg = response.message; 
                  this.message = ''
                }
             }
           );
       },
     }
   }
</script>