<template>
  <div>
    <div class="login-form" :style="{backgroundImage: `url(${backgroundUrl})`}" >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="card px-5">

            <div class="card-header email-header text-center px-0 bg-transparent">
              <router-link class="mr-4 ml-0" :to="{name: 'forget-password'}">
                <img :src="require('@/assets/images/arrow-left.svg')">
              </router-link>
              <div class="for-email mt-3 ">
                <h1 class="heading-enter-email">Password Reset</h1>
                <p class="password-reset"> Reset instructions have been sent to your email. Please follow the instructions to reset your password.</p>
              </div>
            </div>
            <div class="card-body py-4">
              <Form @submit="onSubmit">
                <div class="login-btn">
                  <router-link class="m-0" :to="{name: 'create-password'}">
                    <button type="submit" class="btn btn-done">Done
                      <img :src="require('@/assets/images/arrow-right.svg')">
                    </button>
                  </router-link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import backgroundUrl from "@/assets/images/login-banner.png";
import logoImage from "@/assets/images/logo-1.png";
import backArrow from "@/assets/images/back_arrow.svg"
import { Form, } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'PasswordReset',
  components: {
    Form
  },
  data() {
    return {
      backgroundUrl,
      logoImage,
      backArrow,
      passwordRules: yup.string().required().min(8),
      emailRules: yup.string().required(),
    }
  },
  methods: {
    onSubmit() {
    },
  }
}
</script>

<style scoped>
</style>
