<template>
   <div>
      <div class="pinspy-load" v-if = "loading"><span class="load"></span></div>
      <div class="login-form" :style="{backgroundImage: `url(${backgroundUrl})`}" >
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-lg-6">
                  <div class="card px-5">
                     <div class="card-header text-center bg-transparent" >
                        <router-link :to="{'name': 'home'}" style="border:none;">
                           <img  :src="logoImage" class="mt-4 logo_image_setting">
                        </router-link>
                        <p class="welcome-heading">Welcome!</p>
                        <p class="welcome-login">Please login to your account.</p>
                        <div class="alert alert-danger mb-0" v-if="showError">Username or Password is invalid</div>
                     </div>
                     <div class="card-body py-4">
                        <Form @submit="handleLogin" >
                           <div class="form-group" >
                              <label for="name">Username or Email</label>
                              <Field type="text" name="username"  class="form-control shadow-none error-color-inpt" :rules="usernameRules" required placeholder="Enter your username or email"/>
                              <ErrorMessage name="username" />
                           </div>
                           <div class="form-group"  >
                              <label for="name">Password</label>
                              <Field type="password" name="password" class="form-control shadow-none error-color-inpt" :rules="passwordRules" required placeholder="Enter your password"/>
                              <ErrorMessage name="password" />
                           </div>
                           <div class="login-btn">
                              <a class="m-0">
                              <button type="submit" class="btn">
                              Login
                              <img :src="require('@/assets/images/arrow-right.svg')">
                              </button>
                              </a>
                              <div class="row forget-psw pt-2">
                                 <div class="col-6 text-left p-0">
                                    <router-link class="mr-0" :to="{name: 'register'}">
                                       Dont have a account ?
                                    </router-link>
                                 </div>
                                 <div class="col-6 text-right p-0">
                                    <router-link class="ml-0" :to="{name: 'forget-password'}">
                                       Forgot password?
                                    </router-link>
                                 </div>
                              </div>


                           </div>
                           <div class="form-group">
                              <div v-if="message" class="alert alert-danger" role="alert">
                                 {{ message }}
                              </div>
                           </div>
                            <!-- <div class="wrapper">
                              <input type="range" min="0" max="1500" :step="loanRangeStep" v-model="value" @input="setSteps"> 
                              <input type="number" v-model="value"/>
                              <span v-text="total"></span>
                           </div> -->
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
  
</template>
<script>
   import backgroundUrl from "@/assets/images/login-banner.png";
   import logoImage from "@/assets/images/logo_red.svg";
   import { Form, Field, ErrorMessage  } from 'vee-validate';
   import * as yup from 'yup';
   import { mapActions } from "vuex";


   export default {
     name: 'Login',
     components: {
       Form,
       Field,
       ErrorMessage,
     },
     data() {
       return {
         backgroundUrl,
         logoImage,
         passwordRules: yup.string().required().min(8),
         usernameRules: yup.string().required(),
         showError: false,
         errorMsg: '',
         loading: false,
         message: "",
         value: 50
       }
     },
     computed: {
        total: function () {
      return this.value * 10
    },
       isFormDirty() {
         return Object.keys(this.fields).some(key => this.fields[key].dirty);
       },
       loggedIn() {
         return this.$store.state.auth.initialState.status.loggedIn;
       },
     },
     created() {

     },
     methods: {
       handleLogin(user) {
           this.loading = true;
           this.$store.dispatch("auth/login",user).then(
             (response) => {
                if(response.status==true){
                   if(response.data.subscription==1){
                       this.$router.push("/search-ad");
                   }else{
                       this.$router.push("/subscription");
                   }
                  this.showError = false;
                  this.errorMsg = '';
                  this.loading = false;
                }else{
                  this.showError = true;
                  this.errorMsg = response.message;
                  this.loading = false;
                }
             }
           );
       },
       setSteps() {
            if (this.value < 300) {
                return this.loanRangeStep = 10;
            } else if (this.value >= 300 && this.loanValue < 500) {
                return this.loanRangeStep = 50;
            } else if (this.value >= 500) {
                return this.loanRangeStep = 100;
            } else {
                return this.loanRangeStep = 100;
            }
        },
     },
   }
</script>
