import { createWebHistory, createRouter } from "vue-router";
import Login from "../components/Login";
import ForgetPassword from "../components/ForgetPassword";
import PasswordReset from "@/components/PasswordReset";
import CreatePassword from "@/components/CreatePassword";
import SearchAd from "@/components/SearchAd";
import SearchAdInfo from "../components/SearchAdInfo";
import Subscription from "../components/Subscription";
import MyAccount from "../components/MyAccount";
import Home from "../components/home/home";
import Register from "../components/Register";
import Privacy from "../components/static_page/privacy_policy";
import Terms from "../components/static_page/terms";
import Payment from "../components/static_page/payment";
import HelpCenter from "../components/Helpcenter";
import Thankyou from "../components/static_page/thankYou"
import Example from "../components/Example";
const routes = [
    {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/',
        component: Home,
        name: 'home',
        meta: {
            title: 'Home'
        }
    },
    {
        path: '/forget-password',
        component: ForgetPassword,
        name: 'forget-password',
        meta: {
            title: 'Forget Password'
        }
    },
    {
        path: '/password-reset',
        component: PasswordReset,
        name: 'password-reset',
        meta: {
            title: 'Password Reset'
        }
    },
    {
        path: '/create-password',
        component: CreatePassword,
        name: 'create-password',
        meta: {
            title: 'Create Password'
        }
    },
    {
        path: '/search-ad',
        component: SearchAd,
        name: 'Ad Search',
        meta: {
            title: 'Ad Search'
        }
    },
    {
        path: '/search-ad-info',
        component: SearchAdInfo,
        name: 'search-ad-info',
        meta: {
            title: 'Search Ad Info'
        }
    },
    {
        path: '/subscription',
        component: Subscription,
        name: 'subscription',
        meta: {
            title: 'Subscription'
        }
    },
    {
        path: '/my-account',
        component: MyAccount,
        name: 'My Account',
        meta: {
            title: 'My Account'
        }

    },
    {
        path: '/search-ad/plan',
        component: SearchAd,
        name: 'plan',
        meta: {
            title: 'Plan'
        }
    },
    {
        path: '/register',
        component: Register,
        name: 'register',
        meta: {
            title: 'Register'
        }
    },
    {
        path: '/privacy-policy',
        component: Privacy,
        name: 'privacy-policy',
        meta: {
            title: 'Privacy Policy'
        }
    },
    {
        path: '/terms',
        component: Terms,
        name: 'terms',
        meta: {
            title: 'Terms'
        }
    },
    {
        path: '/helpcenter',
        component: HelpCenter,
        name: 'Help Center',
        meta: {
            title: 'Help Center'
        }
        
    },
    {
        path: '/example',
        component: Example,
        name: 'example'
    },
    {
        path: '/payment/plan',
        component: Payment,
        name: 'payment',
        meta: {
            title: 'Payment'
        }
    },
    {
        path: '/thank-you/:id',
        component: Thankyou,
        name: 'thankyou'
    },
]

const router = new createRouter({
    history: createWebHistory(),
    routes // short for `routes: routes`
})


router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['payment','Login', 'register', 'forget-password', 'password-reset', 'create-password', 'home', 'privacy-policy', 'terms', 'subscription']
    const authRequired = !publicPages.includes(to.name)
    const loggedIn = JSON.parse(localStorage.getItem('user'));

   

    console.log('is auth required : ' + authRequired);
    console.log('logged in user : ')
    console.log(loggedIn);

    if (authRequired && !loggedIn) {
      return next('/login')
    }
    next()
  })


export default router;
