<template>
   <headerSection />
   <section class="drop-my-accnt simple-content--bg">
      <div class="container pt-40">
         <div class="row">
            <div class="col-lg-12">
               <div class="my-acnt-text">
                  <h1 class="heading-account-text">Privacy Policy PinSpy (part of Pinterest Mastery B.V.)</h1>
               </div>
            </div>
         </div>
         <div class="row ">
            <div class="col-md-12 cls-dropdown simple-description-text">
               <p>
                  PinSpy is committed to protecting your privacy. This Statement of Privacy applies to pinterestmastery.nl. Website and governs data collection and usage at all PinSpy sites and services; it does not apply to other online or offline sites, products or services. Personal information of all users is collected, used and disclosed as described in this Statement of Privacy.
               </p>
               <h2 class="d-block mt-4 mb-3 txt-h2">Personal Information</h2>
               <h3 class="d-block txt-h3 mb-2"><strong>Personal Information We May Collect</strong></h3>
               <p> 
                  "Personal Information" is information that identifies you as an individual. In addition to Personal Information, Other Information (as defined below) may be collected in connection with the Services; the collection, use and disclosure of Other Information is addressed separately below, under the heading "OTHER INFORMATION".
               </p>
               <p> We and our third-party service providers may collect the following Personal Information from you for:</p>
               <ul class="list-style-dot">
                  <li>Name.</li>
                  <li>Postal address.</li>
                  <li>Email address.</li>
                  <li>Credit and debit card numbers.</li>
                  <li>Other personally identifiable information about you and third parties that you may choose to transmit through the Services, also including personally identifiable information contained within files, video or audio calls or text messages.</li>
               </ul>
               <p>We do not request, solicit or intend to collect any other personally identifiable information that we don’t need for our services, and you should not disclose any such information in connection with the Services. IF YOU SUBMIT ANY PERSONAL INFORMATION OF OR RELATING TO THIRD PARTIES TO US OR OUR THIRD-PARTY SERVICE PROVIDERS IN CONNECTION WITH THE SERVICES, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO DO SO.</p>
               <h3 class="d-block  txt-h3 my-3"><strong>How We May Use Personal Information</strong></h3>
               <p>We and our third-party service providers may use Personal Information:</p>
               <ul class="list-style-dot">
                  <li>To respond to your inquiries and fulfil your requests, such as to send newsletters to you, complete your commercial transactions and operate the Services.</li>
                  <li>To send to your administrative information, including information concerning the Services and any changes to our terms, conditions and policies.</li>
                  <li>To send to you marketing communications that we believe may be of interest to you, unless you opt-out in accordance with the "CHOICES AND ACCESS" section below.</li>
                  <li>For our business purposes, such as quality assessment, data analysis, audits, developing new products, improving the Services, identifying usage trends and determining the effectiveness of our promotional campaigns.</li>
                  <li>As we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public or government authorities, including public or government authorities outside your country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, or that of our affiliates, service providers, you or others, including through credit-fraud protection and risk reduction; (g) at our discretion under emergency circumstances, to notify emergency services or your family members, personal representative or other individuals involved in your care of your location and condition; or (h) to allow us to pursue available remedies or limit the damages that we may incur.</li>
               </ul>
               <h3 class="d-block  my-4 txt-h3"><strong>How Personal Information May Be Revealed</strong></h3>
               <p>Your Personal Information may be revealed:</p>
               <ul class="list-style-dot">
                  <li>To our third-party service providers that may provide services such as hosting of the Services, data analysis, IT services and infrastructure, customer service, email delivery, auditing, payment processing and other similar services. Please note that we may use cloud service providers in connection with the hosting of the Services and the storage of Personal Information, and we may have limited or no opportunity or ability to impose contractual restrictions on these and other service providers.</li>
                  <li>By you, through messages (including files, video and audio calls and text messages), chats, message boards, forums, public profile pages, user directories and blogs and other functionality that allow you to share and post information and materials. Please note that any information and materials that you post or reveal using such functionality will become public information, and may be available for other users of the Services and the general public to record, access, use, alter, manipulate or reveal to other individuals or social networking sites such as Facebook or YouTube. We are not responsible for such recording, access, use, alteration, manipulation or disclosure. We recommend you to be very careful when deciding to disclose your Personal Information, or any other information, in connection with the Services.</li>
                  <li>To a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or shares (including in connection with any bankruptcy or similar proceeding).</li>
                  <li>As we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public or government authorities, including public or government authorities outside your country of residence; (d) to enforce our</li>
               </ul>
               <p>terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, or that of our affiliates, service providers, you or others, including through credit-fraud protection and risk reduction; (g) at our discretion under emergency circumstances, to notify emergency services or your family members, personal representative or other individuals involved in your care of your location and condition; or (h) to allow us to pursue available remedies or limit the damages that we may sustain.</p>
               <h2 class="d-block mt-4 mb-3  txt-h2"><strong>Other Information</strong></h2>
               <h3 class="d-block  txt-h3 mb-2"><strong>Other Information We May Collect</strong></h3>
               <p>"Other Information" is any information that does not disclose your specific identity, such as:</p>
               <ul class="list-style-dot">
                  <li>Browser information</li>
                  <li>Server log files</li>
                  <li>Statistics concerning messages (including files, video and audio calls and text messages) sent or received through the Services, such as the length of such calls or messages, the IP Addresses involved and other technical parameters</li>
                  <li>Information collected through cookies, pixel tags and other technologies</li>
                  <li>Demographic information</li>
                  <li>Aggregated information</li>
               </ul>
               <h3 class="d-block my-4  txt-h3"><strong>How We May Collect Other Information</strong></h3>
               <p>We and our third-party service providers may collect Other Information in a variety of ways, including:</p>
               <ul class="list-style-dot">
                  <li>Through your device: Certain information is collected automatically through your device, such as your operating system name and version, device manufacturer and model, language, and the name and version of the software application you are using. We may also generate a unique device identifier for use by PinSpy to operate the Services.</li>
                  <li>Through server log files: Your "IP Address" is a number that is automatically assigned to the device that you are using by your Internet Service Provider (ISP). An IP Address is identified and logged automatically in our server log files whenever a user uses the Services, along with the time of the use and the pages or areas visited within the Services. Collecting IP Addresses is standard practice on the Internet and is done automatically by many websites. We and our third-party service providers use IP Addresses for purposes such as helping to calculate usage levels of the Services diagnose server problems and administer the Services.</li>
               </ul>
               <ul class="list-style-dot">
                  <li>cookies. If you choose to decline cookies, some or all of the features, functionality and promotions available through the Services may not be available to you.</li>
                  <li>Using pixel tags and other similar technologies: Pixel tags (also known as web beacons and clear GIFs) and other comparable technologies may be used in connection with some pages and areas of the Services and HTML-formated email messages to, among other things, track the actions of users of the Services nd email receivers, measure the success of our marketing campaigns and compile statistics about usage and response rates of the Services.</li>
                  <li>Physical Location: We may collect the physical location of your device by, for example, using satellite, cell phone tower or Wi-Fi signals. We may use your device's physical location to provide you with personalized location-based services and content. We may also share your device's physical location, combined with information about what advertisements you viewed and other information we collect, with our marketing partners to enable them to provide you with more personalized content and to study the effectiveness of advertising campaigns. In some instances, you may be permitted to allow or deny such uses and/or sharing of your device's location, but if you choose to deny such uses and/or sharing, we and/or our marketing partners may not be able to provide you with the applicable personalized services and content.</li>
                  <li>From you: We may collect demographic information such as your location, as well as other information, such as your preferred means of communication, when you voluntarily provide such information to us. Unless combined with Personal Information, this information does not personally identify you or any other user of the Services.</li>
                  <li>By aggregating information: Personal Information does not personally identify you or any other user of the Services. For example, we may aggregate Personal Information to calculate the percentage of our users who have a particular telephone area code.</li>
               </ul>
               <h3 class="d-block my-4  txt-h3"><strong>How We May Use and Reveal Other Information</strong></h3>
               <p>Because ‘’Other Information’’ does not personally identify you, we may use and reveal ‘’Other Information ‘’for any purpose. In some instances, we may combine ‘’Other Information’’ with ‘’Personal Information’’ (such as combining your zip code with your name). If we combine any ‘’Other Information’’ with ‘’Personal Information’’, the combined information will be treated by us as Personal Information as long as it is so combined.</p>
               <h4 class="txt-h4 text-left"><strong>Security</strong></h4>
               <p>The security of your personal information is important to us. When you enter sensitive billing information on our order forms, we encrypt the transmission of that information using secure socket layer technology (SSL). If you choose to use a third-party payment option to pay for your order, its privacy statement and security practices will apply to your information. We encourage you to read that privacy statement before providing your information.</p>
               <p>We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and after receive. However, no method of transmission over the internet or method of electronic storage is 100% secure and we cannot warrant its absolute security.</p>
               <h2 class="d-block mt-4 mb-3  txt-h2"><strong>Choices and Access</strong></h2>
               <h3 class="d-block my-4  txt-h3"><strong>Your choices regarding our use and reveal of your Personal Information for marketing purposes:</strong></h3>
               <p>We give you many choices regarding our use and reveal of your Personal Information for marketing purposes. You may opt-out from:</p>
               <p>Receiving marketing-related e-mails from us based upon your Personal Information: If you no longer want to receive marketing-related e-mails from us on a going-forward basis based upon Personal Information collected pursuant to this Privacy Policy, you may opt-out of receiving such marketing-related e-mails by accessing te "Email Subscriptions" page on the Site, or by clicking the "unsubscribe" link that is included at the end of such marketing-related e-mails.</p>
               <p>We will attempt to comply with your request(s) as soon as reasonably possible. Please note that if you opt-out as described above, we will not be able to remove your Personal Information from the databases of third parties with which we have already shared such information (i.e., to which we have already provided such information as of the date that we implement your opt-out request). Please also note that if you do opt-out of receiving marketing-related e-mails from us, we may still send administrative messages to you; you cannot opt-out from receiving administrative messages.</p>
               <h3 class="d-block my-4  txt-h3"><strong>How you can access your Personal Information</strong></h3>
               <p>If you would like to review or update your Personal Information that has been previously provided to us, you may do so by logging into your account within the Services, or, for certain Personal Information that is not reviewable through the Services, you may contact us in accordance with the "CONTACTING US" section below.</p>
               <p>We will endeavour to comply with your request to update your Personal Data as soon as reasonably possible. We are not responsible for changing information from the databases of third parties with whom we have already shared your Personal Information.</p>
               <h2 class="d-block mt-4 mb-3  txt-h2"><strong>Children’s Privacy Policy.</strong></h2>
               <p class="my-4"><strong>The Site is not intended for individuals under the age of thirteen. We do not permit use of the Site by persons under the age of 13, and we do not knowingly, directly, or passively collect information from children under age 13. If you are under 13 years of age, you should discontinue use of this Site.</strong></p>
               <h2 class="d-block mt-4 mb-3  txt-h2"><strong>Retention Period</strong></h2>
               <p>We will retain your Personal Information for the period necessary to fulfil the purposes described in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
               <h2 class="d-block mt-4 mb-3  txt-h2"><strong>Contacting Us</strong></h2>
               <p>If you have any questions about this Privacy Policy, please contact us by sending an email to <a class="support-email" href="mailto:support@pinterestmastery.nl">support@pinterestmastery.nl</a>. Please note that information you submit throgh email may not be secure; so please do not mention your credit card information or other sensitive information in your messages to us.</p>
            </div>
         </div>
      </div>
   </section>
   <footerSection />

</template>
<script>
import axios from 'axios';
import headerSection from "../header/header.vue";
import footerSection from "../footer/footer.vue";
export default {
	components: {
		headerSection,
		footerSection
	},
}
</script>
<style scoped>
   .artiical-heading {
   font-size: 1.75rem;
   color: #4E4E4E;
   font-weight: 700;
   margin: 15px 0px;
   }
   .section-title {
   color: #4E4E4E;
   position: relative;
   overflow: hidden;
   display: block;
   }
   .title1 {
   font-size: 13pt;
   font-weight: 500;
   margin: 0;
   }
   .section-title:after {
   content: '';
   position: absolute;
   bottom: .6rem;
   width: 100%;
   height: 0;
   line-height: 0;
   border-bottom: 2px dotted #4E4E4E;
   margin-left: 8px;
   }
   .section-title:hover {
   color: #E9191F;
   text-decoration: none;
   }
   .pg {
   font-style: normal;
   padding-top: 10px;
   font-weight: normal;
   }
   .sub-head {
   margin: 15px 0px 10px 0px;
   font-weight: 700;
   }
   .para {
   margin: 0;
   }
   .sub-head {
   margin: 15px 0px 10px 0px;
   font-weight: 700;
   }
   .simple-content--bg, body section.simple-content--bg {
   background: url('~@/assets/images/privacy-bg.png');
   background-size: 100%;
   font-size: 18px !important;
   color: #4E4E4E !important;
   line-height: 32px;
   }
   .list-style-dot li {
   list-style: disc;
   }
   .support-email {
   margin: 0;
   }
   strong {
   font-weight: 700;
   }
   h3 {
   font-size: 28px;
   }
</style>