<template>
<headerSection />
<section class="drop-my-accnt simple-content--bg">
	<div class="container pt-40">
        <div class="row">
            <div class="col-12 text-center heading-account-text success-message">
                <p class="text-center mt-90 h3" v-if="success">{{responce_message}} </p>
                <p class="text-center text" v-if="success">You will be redirected to search shortly.</p>
                <p class="mb-3" v-if="success">
                    <img :src="require('@/assets/images/Heart.svg')" class="m-auto" style="height: 200px;">
                </p>
                <p class="mt-5 mb-0" v-if="success!=='' && success==false">
                    <img :src="require('@/assets/images/warning.png')" class="m-auto" style="height: 200px;">
                </p>
                <p class="text-center  h3" v-if="success!=='' && success==false">{{responce_message}} </p>
                <p class="text-center text" v-if="success!=='' && success==false">Click on the below link to try again.</p>
                <p class="d-flex" v-if="success!=='' && success==false" > <router-link :to="{'name': 'subscription'}" class="button-red btn-large theme-btn m-auto">Try Again</router-link></p>
            </div>
        </div>
    </div>
</section>
<footerSection />
</template>
<script>
import axios from 'axios';
import headerSection from "../header/header.vue";
import footerSection from "../footer/footer.vue";
import authHeader from '../../services/auth-header';
export default {
	components: {
        headerSection,
        footerSection
	},
	data() {
        return {
            responce_message: '',
            success: ''
        }
    },
    mounted(){
        localStorage.removeItem("filters");
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        console.log(params)
        if(params && params.order_id!=undefined){
           this.get_status();
        }else if(params && params.token!=undefined){
            this.get_status_paypal();
        }else{
           var sub = localStorage.getItem('subscription')
           if (sub !=1 ) {  
             return this.$router.push("/subscription");
           }
        }
    },
    methods: {
        get_status(data) {
            var self = this;
            var sub = localStorage.getItem('subscription');
            if(sub!=1){
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if(params && params.order_id!=undefined){
                    axios.get('get-status?order_id='+params.order_id, { headers: authHeader() }).then(
                    (response) => {
                        if(response && response.data && response.data.status =='paid'){
                            localStorage.removeItem("jwtToken");
                            localStorage.setItem('subscription', 1);
                            localStorage.setItem('jwtToken', response.data.jwtToken);
                            this.loading = false;
                            this.success = true;
                            self.$store.commit('updateSubscriptionStatus', 1);
                            this.responce_message = "Payment successfully completed. You will be redirected to search shortly."
                            setTimeout(function(){ self.$router.push("/search-ad"); self.success=false }, 5000);
                        }else if(response && response.data && response.data.status!='paid'){
                            this.loading = false;
                            this.success = false
                            this.responce_message = "Payment is not completed."
                        }
                    }
                 );
               }
            }
        },

        get_status_paypal(data) {
            var self = this;
            var sub = localStorage.getItem('subscription');
            if(sub!=1){
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                if(params && params.token!=undefined){
                    axios.get('paypal-subscribe-return?token='+params.token, { headers: authHeader() }).then(
                    (response) => {
                        if(response && response.status==true){
                            localStorage.removeItem("jwtToken");
                            localStorage.setItem('subscription', 1);
                            localStorage.setItem('jwtToken', response.data.jwtToken);
                            this.success = true;
                            self.$store.commit('updateSubscriptionStatus', 1);
                            this.responce_message = "Payment successfully completed. You will be redirected to search shortly."
                            this.loading = false;
                            setTimeout(function(){ self.$router.push("/search-ad"); self.success=false }, 5000);
                        }else if(response && response.data){
                            this.success = false
                            this.loading = false;
                            this.responce_message = "Payment is not completed."
                        }
                    }
                 );
               }
            }
        }
    }
}
</script>