<template>
   <div>
      <div class="pinspy-load" v-if = "loading"><span class="load"></span></div>
      <!-- <section>
         <div class="navigation-wrap bg-light-1 start-header my-account-header">
            <div class="container">
               <div class="row">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-6 ">
                     <div class="site-logo site-top-logo">
                        <div class="logo-icon">
                           <router-link :to="{'name': 'home'}">
                              <img :src="require('@/assets/home/images/logo-1.png')"/>
                           </router-link>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-10 col-lg-10 col-md-10 col-6">
                     <nav class="navbar navbar-expand-md navbar-light">
                        <button class="navbar-toggler bg-transparent border-0" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                           <ul class="navbar-nav top-menu">
                              <li class="nav-item logout-btn" v-if="!loggedIn">
                                 <a class="nav-link my-account-logout red-button btn-large" @click.prevent="logout()">Login</a>
                              </li>
                              <li class="nav-item logout-btn" v-if="loggedIn">
                                 <a class="nav-link my-account-logout red-button btn-large" @click.prevent="logout()">Logout</a>
                              </li>
                           </ul>
                        </div>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section> -->
      <section class="sticky-header">
      <div class="navigation-wrap bg-light-1 start-header my-account-header">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-3 col-lg-2 align-self-center d-none d-md-block">
                  <div class="site-logo site-top-logo">
                     <div class="logo-icon">
                        <router-link :to="{'name': 'Ad Search'}" style="border:none;"  v-if="loggedIn">
                           <img :src="require('@/assets/images/logo_red.svg')">
                        </router-link>
                        <router-link :to="{'name': 'home'}" style="border:none;" v-else>
                           <img :src="require('@/assets/images/logo_red.svg')"/>
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class=" col-md-9 col-lg-10  align-self-center">
                  <nav class="navbar navbar-expand-md navbar-light">
                     <router-link :to="{'name': 'home'}" style="border:none;" class="d-md-none ">
                        <img :src="require('@/assets/images/logo_red.svg')"/>
                     </router-link>
                     <button class="navbar-toggler bg-transparent border-0 ml-auto theme-navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                     <span class="navbar-toggler-icon"></span>
                     </button>
                     <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="loggedIn">
                        <ul class="navbar-nav top-menu">
                           <li class="nav-item" v-if="subscription">
                              <router-link class="nav-link nav-link-my-acunt " :to="{'name': 'Ad Search'}">Ad Search</router-link>
                           </li>
                           <li class="nav-item" v-else>
                              <router-link class="nav-link nav-link-my-acunt" :to="{'name': 'subscription'}">Ad Search</router-link>
                           </li>
                           <li class="nav-item">
                              <router-link class="nav-link nav-link-my-acunt " :to="{'name': 'My Account'}">My Account</router-link>
                           </li>
                           <li class="nav-item">
                              <a href="https://pinspy.com/help-center/" class="nav-link nav-link-my-acunt" target="_blank" test="test">Help Center</a>
                              <!-- <router-link class="nav-link nav-link-my-acunt" :to="{ path: 'pinspy.com/help-center/' }" target="_blank" >Help Center</router-link> -->
                           </li>
                           <!-- <li class="nav-item">
                              <router-link class="nav-link nav-link-my-acunt" :to="{'name': 'example'}">Example</router-link>
                           </li> -->
                           <li class="nav-item logout-btn">
                              <a class="nav-link my-account-logout red-button btn-large" @click.prevent="logout()">Log out</a>
                           </li>
                        </ul>
                     </div>
                     <div class="collapse navbar-collapse" id="navbarSupportedContent" v-else>
                        <ul class="navbar-nav top-menu">
                           <li class="nav-item logout-btn" v-if="loggedIn">
                              <a class="nav-link my-account-logout red-button btn-large" @click.prevent="logout()">Logout</a>
                           </li>
                           <li class="nav-item  red-link" v-else>
                              <a class="nav-link " @click.prevent="logout()">Login</a>
                           </li>
                           <li class="nav-item logout-btn"  v-if="loggedIn">
                              <router-link class="nav-link mx-0 ml-3 button-white btn-large theme-btn red-outline-btn" :to="{'name': 'subscription'}">Get started</router-link>
                           </li>
                           <li class="nav-item logout-btn" v-else>
                              <router-link class="nav-link mx-0 ml-3 my-account-logout red-button btn-large" :to="{'name': 'register'}">Get started</router-link>
                           </li>
                        </ul>
                     </div>
                  </nav>
               </div>
            </div>
         </div>
      </div>
   </section>
      <div class="subscription-area">
         <div class="container">
            <div class="col-lg-6 subscribe-align-text">
               <div class="subscribe-text-content" v-if="!showpaymentMethods">
                  <div class="sub-text mt-3 pb-4">
                     <p class="subscribe">Subscribe to Pinspy</p>
                     <span class="subscription-time mb-3 d-block">Try <b>14 days</b> for <b>FREE!</b></span>
                      <!-- <span class="subscription-time mb-2 d-block">After the trail <span class="line-through" style="text-decoration: line-through;">$99 USD</span> <b>$49USD</b>/month</span> -->
                       <span class="subscription-time d-block"><b>$49</b> for unlimited virtual access</span>
                       <!-- <span class="subscription-time mb-2 d-block ">1 year discount during the Beta phase</span> -->
                  </div>
                  <div class="list-check">
                     <ul class="mb-4 mt-3 px-3">
                        <li><i class="fas fa-check"></i><span>Get</span> full access</li>
                        <li><i class="fas fa-check"></i><span>Virtually</span> Unlimited usage.</li>
                     </ul>
                     <p class="text-after-list">Sign up now and get full access to all the amazing Pin Spy features. Find your competitor’s most successful ads with just one click. Search for keywords, demographics, affiliate platforms, landing page websites and more. Pin Spy is the only tool that gives you instant access to all the data you need to grow your business with Pinterest.</p>
                  </div>
                  <div class="terms-condition">
                     <input type="checkbox" name="terms_conditions" v-on:click="myFunction()" id="myCheck">
                     <label for="vehicle3" class="check-lable ml-2">
                        I agree to the
                        <router-link  class="m-0 onhover-red" :to="{'name': 'terms'}"><span>Terms and Condition</span></router-link>
                     </label>
                     <br>
                     <span style= "color:red; font-size:12px; margin-bottom: 10px; position: absolute; margin: auto; left:-103px; right: 0px; right: 0;" v-if="showError">Please accept terms and conditions.</span>
                     <br>
                  </div>
                  <div class="btn-purchase">
                     <a v-on:click="onSubmit()" class="button-red d-inline-flex m-0 theme-btn btn-xs">purchase</a>
                  </div>
                  <div class="login-or-not" v-if="!loggedIn">
                     <router-link class="m-0" :to="{'name': 'Login'}">
                        <p class="alredy-member">Already a member? <span>Login</span></p>
                     </router-link>
                  </div>
               </div>
               <div class="subscribe-text-content" v-if="showpaymentMethods">
                  <div class="sub-text mt-3 pb-4 border-0">
                     <p class="subscribe">Select payment method</p>
                      <!-- <span class="subscription-time mb-2 d-block line-through">$99/Month</span> -->
                      <span class="subscription-time mb-2 d-block ">$49/Month</span>
                       <!-- <span class="subscription-time mb-2 d-block ">1 year discount during the Beta phase</span> -->
                  </div>
                  <div class="form-group w-75 mx-auto">
                              <label for="name">  Coupon code</label>
                              <input type="text" class="form-control-data shadow-none" @keyup="validateCoupon($event.target.value)" id="couponCode">
                              <span style= "color:red; font-size:12px; margin-bottom: 10px;" v-if="showCouponError">Please enter a valid coupon.</span>

                           </div>
                  <div class="list-check pay-method d-md-flex mx-auto justify-content-center" :class="disabledClass" >
                     <q-card class="mr-2 py-1 col-md-4 pointer">
                        <q-card-section class="p-0 mt-2" >
                           <stripe-checkout ref="checkoutSepaRef" :pk="publishableKey" :session-id="stripeIdealSession.id"/>
                              <button @click="stripeSepaSubmit"><img :src="require('@/assets/images/sepa.svg')" class="m-auto img-fluid" ></button>
                        </q-card-section>
                     </q-card>
                     <q-card class="mr-2 py-1 col-md-4 pointer">
                        <q-card-section class="p-0">
                           <stripe-checkout ref="checkoutCCRef" :pk="publishableKey" :session-id="stripeCCSession.id" />
                           <button @click="stripeCreditCardSubmit"><img :src="require('@/assets/images/Creditcard.png')" class="m-auto img-fluid" ></button>

                      </q-card-section>
                     </q-card>
                  </div>

               </div>
            </div>
         </div>
      </div>
      <footerSection />
   </div>
</template>
<style scoped>
.disabledCard {
    pointer-events: none;
    opacity: 0.4;
}
</style>
<script>
   import { StripeCheckout } from '@vue-stripe/vue-stripe';
   import backgroundUrl from "@/assets/images/banner-image.png";
   import logoImage from "@/assets/images/logo.png";
   import * as yup from 'yup';
   import { mapActions } from "vuex";
   import axios from 'axios';
   import authHeader from '../services/auth-header';
   import footerSection from "./footer/footer.vue";


   export default {
     name: 'Subscription',
     components: {
       footerSection,
       StripeCheckout,
     },
     data() {
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
       return {
         backgroundUrl,
         logoImage,
         showpaymentMethods: false,
         showError: false,
         showCouponError: false,
         showSuccess: false,
         disabledClass:'',
         errorMsg: '',
         loading: false,
         stripeCCSession: false,
         stripeIdealSession: false,
         message: "",
         term: false,
         formData: {
           amount: "99",
           type: 'monthly',
         },
         // subscription:false

       }
     },
     computed: {
       loggedIn() {
         return this.$store.state.auth.initialState.status.loggedIn;
       },
       subscription() {
         return localStorage.getItem('subscription');
       }
     },
     async created() {
        localStorage.removeItem("filters");
        await axios.get('check-subscription', { headers: authHeader() }).then(
             (response) => {
                if(response.status==true){
                     if(response.data.subscription==1 ){
                        this.$router.push("/search-ad");
                     }
                }
             }
         );

         // axios.get('stripe-cc-session', { headers: authHeader() }).then(
         //     (response) => {
         //        if(response.status==true){
         //           this.stripeCCSession = response.data;
         //        }
         //     }
         // );

         // axios.get('stripe-ideal-session', { headers: authHeader() }).then(
         //     (response) => {
         //        if(response.status==true){
         //           this.stripeIdealSession = response.data;
         //        }
         //     }
         // );
     },
     methods: {
       cardSession(){
         this.loading=false
          axios.get('stripe-cc-session', { headers: authHeader() }).then(
             (response) => {
               this.loading=false
                if(response.status==true){
                   this.stripeCCSession = response.data;
                   this.$refs.checkoutCCRef.redirectToCheckout();
                }
             }
         );
       },
      idealSession(){
         this.loading=true
         axios.get('stripe-ideal-session', { headers: authHeader() }).then(
             (response) => {
               this.loading=false
                if(response.status==true){
                   this.stripeIdealSession = response.data;
                   this.$refs.checkoutSepaRef.redirectToCheckout();
                }
             }
         );
       },
       myFunction() {
           var checkBox = document.getElementById("myCheck");
           if (checkBox.checked == true){
               this.term = true;
               this.showError = false
           } else {
               this.term = false;
           }
       },
       validateCoupon(val) {
         this.showCouponError = false
         this.disabledClass =''
            axios.get('validate-coupon/'+val, { headers: authHeader() }).then(
               (response) => {
                  if(response.status==true){
                     this.disabledClass=''
                     this.showCouponError =false;
                  }else{
                     this.disabledClass=''
                     this.disabledClass='disabledCard'
                     this.showCouponError =true;
                  }
               }
            );
       },
       onSubmit(data) {
         if(this.term==false){
             this.showError = true
             return
         }
         if(!this.loggedIn){
             this.$router.push("/register");
             return
         }
         this.showpaymentMethods = true
       },
       mollie(type){
         this.showError = false
         this.loading = true;
         axios.post('stripe-payment?method='+type, this.formData, { headers: authHeader() }).then(
             (response) => {
                if(response.status==true){
                  this.showError = false;
                  this.loading = false;
                  this.errorMsg = '';
                  this.showSuccess = true;
                  this.message = response.message;
                  window.location.href =response.data;
                }else{
                  this.loading = false;
                  this.showSuccess = false;
                  this.showError = true;
                  this.errorMsg = response.message;
                  this.message = ''
                }
             }
         );
       },
      stripeCreditCardSubmit () {
         if(this.showCouponError){
            return
         }
         this.cardSession()
         // You will be redirected to Stripe's secure checkout page
         this.$refs.checkoutCCRef.redirectToCheckout();
      },
      stripeSepaSubmit () {
         if(this.showCouponError){
            return
         }
         this.idealSession()
         // You will be redirected to Stripe's secure checkout page
         this.$refs.checkoutSepaRef.redirectToCheckout();
      },
       checkSubscription(){

       },
       paypal(){
         this.showError = false
         this.loading = true;
         axios.get('paypal-subscribe', { headers: authHeader() }).then(
             (response) => {
                if(response.status==true){
                  this.showError = false;
                  this.loading = false;
                  this.errorMsg = '';
                  this.showSuccess = true;
                  this.message = response.message;
                  window.location.href =response.data;
                }else{
                  this.loading = false;
                  this.showSuccess = false;
                  this.showError = true;
                  this.errorMsg = response.message;
                  this.message = ''
                }
             }
         );
       },
       logout() {
           this.$store.dispatch('auth/logout').then(() => this.$router.push("/login"));
         },
     }
   }
</script>
<style scoped>
   .navbar-toggler-icon:after, .navbar-toggler-icon:before {
   background: #E9191F;
   }
   .navbar-toggler-icon {
   border-color: #E9191F;
   }
   a.my-account-logout.red-button:hover {
   color: #E9191F !important;
   }
   .pointer{
      cursor: pointer;
   }
   .line-through{
      text-decoration: line-through;
   }
</style>
