<template>
  <div>
    <div class="pinspy-load" v-if = "loading"><span class="load"></span></div>
    <div class="login-form" :style="{backgroundImage: `url(${backgroundUrl})`}" >

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="card px-5">

            <div class="card-header email-header text-center bg-transparent">
              <router-link class="mr-4 ml-0" :to="{name: 'Login'}">
                <img :src="require('@/assets/images/arrow-left.svg')">
              </router-link>
              <div class="for-email mt-3 ">
                <h1 class="heading-enter-email">Enter Email</h1>
                <p class="Enter-email"> Please enter your email.</p>
              </div>
              
            </div>
            <div class="card-body py-4 px-0">
              <Form @submit="onSubmit" >
                <div class="alert alert-danger" v-if="showError">{{errorMsg}}</div>
                  <div class="alert alert-success" v-if="showSuccess">{{message}}</div>
                <div class="form-group">

                  <Field type="text" name="email" v-model="formData.email" class="form-control shadow-none" :rules="emailRules" required placeholder="Enter email"/>
                  <ErrorMessage name="email" />

                </div>

                <div class="login-btn ">
                  <button type="submit" class="btn btn-done">Submit
                    <img :src="require('@/assets/images/arrow-right.svg')">
                  </button>

                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import backgroundUrl from "@/assets/images/login-banner.png";
import logoImage from "@/assets/images/logo-1.png";
import backArrow from "@/assets/images/back_arrow.svg"
import { Form, Field, ErrorMessage  } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'ForgetPassword',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      backgroundUrl,
      logoImage,
      backArrow,
      passwordRules: yup.string().required().min(8),
      emailRules: yup.string().required(),
      showError: false,
      showSuccess: false,
      errorMsg: '',
      loading: false,
      message: "", 
      formData: {
                    email: '',
                },
    }
  },
  methods: {
    onSubmit(data) {
      this.loading = true;
      var self = this;
      this.$store.dispatch("auth/forgotpassword", data).then(
          (response) => {
             if(response.status==true){
               this.showError = false;
               this.errorMsg = '';
               this.showSuccess = true;
               this.loading = false;
               this.message = response.message; 
               setTimeout(function(){ self.showSuccess=''; }, 3000);
             }else{
               this.showSuccess = false;
               this.showError = true;
               this.loading = false;
               this.errorMsg = response.message; 
               this.message = ''
             }
          }
        );
    },
  }
}
</script>


