<template>
   <section class="footer">
      <div class="container-fluid">
         <div class="row text-center text-sm-left">
            <div class="col-12 offset-0 col-lg-10 offset-lg-1">
               <div class="d-sm-flex">
                  <!--  -->
                  <div class=" align-self-center ml-14">
                     <router-link style="border-bottom:none;" class="mb-4 ml-0 mb-sm-0 d-flex p-0 justify-content-sm-start justify-center ml-0" v-if="loggedIn">
                        <img :src="require('@/assets/images/logo-2.png')" />
                     </router-link>
                     <a style="border-bottom:none;" @click="moveUp" class="mb-4 ml-0 mb-sm-0 d-flex p-0 justify-content-sm-start justify-center" v-else>
                     <img :src="require('@/assets/images/logo-2.png')" />
                     </a>
                     <ul>
                        <li class="mb-1">JT Solutions B.V</li>
                        <li class="mb-1">KVK: 89482301</li>
                        <li class="mb-1">BTW: NL864995659B01</li>
                     </ul>
                  </div>
                  <div class=" m-sm-auto footer-center align-self-center ">
                     <!-- <ul class="mb-0">
                        <li class="mb-1">Telderslaan 23</li>
                          <li class="mb-1">3527KD, Utrecht
                        </li>
                        <li class="mb-1"><a href="mailto:support@pinspy.com" class="para m-0" data-v-a9fcb6d0="">support@pinspy.com</a></li>
                     </ul> -->
                  </div>
                  <!-- <div class="col-md-3 align-self-center ">
                     <ul class="mb-0">

                     </ul>
                     </div> -->
                  <div class=" align-self-center ">
                     <ul class="mb-0">
                        <li>
                           <a class="m-0 onhover-red" href="https://pinspy.com/privacy-policy/"> Privacy Policy </a>
                        </li>
                        <li>
                           <a class="m-0 onhover-red" href="https://pinspy.com/terms-of-service/"> Terms and conditions </a>
                        </li>
                     </ul>
                  </div>
                  <!--  -->
               </div>
            </div>
         </div>
         <!--  -->
      </div>
   </section>
</template>
<script>
   export default {
     methods: {
      moveUp(){
           window.scrollTo(0,0);
      }
     }
   }
   
</script>
<style scoped></style>
