<template>
    <div>
        <div class="pinspy-load" v-if="loading"><span class="load"></span></div>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W6HDLLR" height="0" width="0" style="display:none;visibility:hidden"></iframe>
   <section class="sticky-header">
      <div class="navigation-wrap bg-light-1 start-header my-account-header">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-3 col-lg-2 align-self-center d-none d-md-block">
                  <div class="site-logo site-top-logo">
                     <div class="logo-icon">
                        <router-link :to="{'name': 'Ad Search'}" style="border:none;"  v-if="loggedIn">
                           <img :src="require('@/assets/images/logo_red.svg')">
                        </router-link>
                        <router-link :to="{'name': 'home'}" style="border:none;" v-else>
                           <img :src="require('@/assets/images/logo_red.svg')"/>
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class=" col-md-9 col-lg-10  align-self-center">
                  <nav class="navbar navbar-expand-md navbar-light">
                     <router-link :to="{'name': 'home'}" style="border:none;" class="d-md-none ">
                        <img :src="require('@/assets/images/logo_red.svg')"/>
                     </router-link>
                     <button class="navbar-toggler bg-transparent border-0 ml-auto theme-navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                     <span class="navbar-toggler-icon"></span>
                     </button>
                     <div class="collapse navbar-collapse" id="navbarSupportedContent" >
                        <ul class="navbar-nav top-menu">
                           <li class="nav-item ">
                              <router-link class="nav-link nav-link-my-acunt " :to="{'name': 'Ad Search'}">Ad Search</router-link>
                           </li>
                           <li class="nav-item">
                              <router-link class="nav-link nav-link-my-acunt " :to="{'name': 'My Account'}">My Account</router-link>
                           </li>
                           <li class="nav-item">
                              <a href="https://pinspy.com/help-center/" class="nav-link nav-link-my-acunt" target="_blank">Help Center</a>
                              <!-- <router-link class="nav-link nav-link-my-acunt" :to="{ path: 'pinspy.com/help-center/' }" target="_blank">Help Center</router-link> -->
                           </li>
                           <!-- <li class="nav-item">
                              <router-link class="nav-link nav-link-my-acunt" :to="{'name': 'example'}">Example</router-link>
                           </li> -->
                           <li class="nav-item logout-btn">
                              <a class="nav-link my-account-logout red-button btn-large" @click.prevent="logout()">Log out</a>
                           </li>
                        </ul>
                     </div>
                  </nav>
               </div>
            </div>
         </div>
      </div>
   </section>
        <section class="drop-my-accnt simple-content--bg">
            <div class="container pt-40">
                <div class="row">
                    <div class="col-md-8 mx-auto heading-account-text">
                        <div class="mt-5 pt-5"></div>
                        <h2 class="text-center heading mb-5">Thank You</h2>
                        <div class="thank-you-information">
                            <p class="subscription-p">Subscription</p>
                            <p class="paragraph-text"><b>Free trail </b>{{trail_start }} - {{trail_end}}</p>
                            <p class="paragraph-text"><b>Paid amount:</b> $0</p>
                            <p class="paragraph-text italic">Your subscription will automatically renew on {{renew}}</p>
                        </div>
                    </div>
                    <div class="col-md-8 mx-auto heading-account-text text-center">
                        <router-link class="search-add-btn text-nowrap" :to="{'name': 'Ad Search'}">
                            Start searching ads
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="footer">
      <div class="container-fluid">
         <div class="row text-center text-sm-left">
            <div class="col-12 offset-0 col-lg-10 offset-lg-1">
               <div class="d-sm-flex">
                  <!--  -->
                  <div class=" align-self-center ml-14">
                     <router-link style="border-bottom:none;" class="mb-4 ml-0 mb-sm-0 d-flex p-0 justify-content-sm-start justify-center ml-0" v-if="loggedIn">
                        <img :src="require('@/assets/images/logo-2.png')" />
                     </router-link>
                     <a style="border-bottom:none;" @click="moveUp" class="mb-4 ml-0 mb-sm-0 d-flex p-0 justify-content-sm-start justify-center" v-else>
                     <img :src="require('@/assets/images/logo-2.png')" />
                     </a>
                     <ul>
                        <li class="mb-1">JT Solutions B.V</li>
                        <li class="mb-1">KVK: 89482301</li>
                        <li class="mb-1">BTW: NL864995659B01</li>
                     </ul>
                  </div>
                  <div class=" m-sm-auto footer-center align-self-center ">
                     <!-- <ul class="mb-0">
                        <li class="mb-1">Telderslaan 23</li>
                          <li class="mb-1">3527KD, Utrecht
                        </li>
                        <li class="mb-1"><a href="mailto:support@pinspy.com" class="para m-0" data-v-a9fcb6d0="">support@pinspy.com</a></li>
                     </ul> -->
                  </div>
                  <!-- <div class="col-md-3 align-self-center ">
                     <ul class="mb-0">

                     </ul>
                     </div> -->
                  <div class=" align-self-center ">
                     <ul class="mb-0">
                       <li>
                           <a class="m-0 onhover-red" href="https://pinspy.com/privacy-policy/"> Privacy Policy </a>
                        </li>
                        <li>
                           <a class="m-0 onhover-red" href="https://pinspy.com/terms-of-service/"> Terms and conditions </a>
                        </li>
                     </ul>
                  </div>
                  <!--  -->
               </div>
            </div>
         </div>
         <!--  -->
      </div>
   </section>
    </div>
    </template>
    <script>
    import axios from 'axios';
    import authHeader from '../../services/auth-header';
    import moment from 'moment';

    export default {

        data() {
            return {
                responce_message: '',
                success: '',
                trail_start:'',
                trail_end:'',
                renew:'',
                loading:true
            }
        },
        computed:{
            loggedIn() {
                return this.$store.state.auth.initialState.status.loggedIn;
            },
        },
        mounted(){
            // console.log('hello thank you page');
            // console.log(this.$route.params.id);
            // localStorage.removeItem("filters");
            // const urlSearchParams = new URLSearchParams(window.location.search);
            // const params = Object.fromEntries(urlSearchParams.entries());
            // console.log(params)
            // if(params && params.order_id!=undefined){
            //    this.get_status();
            // }else if(params && params.token!=undefined){
            //     this.get_status_paypal();
            // }else{
            // //    var sub = localStorage.getItem('subscription')
            // //    if (sub !=1 ) {
            // //      return this.$router.push("/subscription");
            // //    }
            // }
            this.get_subscription(this.$route.params.id);
            this.checkPlan();
        },
        methods: {
            logout() {
                this.$store.dispatch('auth/logout').then(() => this.$router.push("/login"));
            },
            get_subscription(id){
                axios.get('get-subscription/'+id,{ headers: authHeader() }).then((response) => {
                    // console.log(response);
                    this.loading = false;
                    this.trail_start = this.dateFormatter(response.trial_start);
                    this.trail_end = this.dateFormatter(response.trial_end);
                    this.renew = this.dateFormatter(response.start_date);
                })
            },
            checkPlan() {
                axios
                    .get("check-subscription", { headers: authHeader() })
                    .then((response) => {
                    if (response.status == true) {
                        if (response.data.subscription == 1) {
                            let user = JSON.parse(localStorage.getItem('user'))
                            user.subscription = "1";
                            localStorage.setItem('user', JSON.stringify(user));
                            localStorage.setItem('jwtToken', response.data.jwtToken);
                            localStorage.setItem('subscription', 1);
                        }
                    }
                });
            },
            dateFormatter(timestamp){
                return new Date((timestamp * 1000)).toLocaleDateString('en-us', {day:"numeric"})+' '+new Date((timestamp * 1000)).toLocaleDateString('en-us', {month:"short"})+'. '+new Date((timestamp * 1000)).toLocaleDateString('en-us', {year:"numeric"})
            }
        }
    }
    </script>
    <style scoped>
        .heading {
        font-weight: 700;
        color: #4e4e4e;
    }
    .subscription-p {
    font-size: 40px;
    font-weight: 700;
    color: #d3020e;
}
.paragraph-text {
    font-size: 16px;
    color: #000;
}
.italic {
    font-style: italic;
}
.search-add-btn {
    background: #d3020e;
    border: 0;
    color: #fff;
    padding: 8px 40px;
    border: 2px solid #d2020f;
    border-radius: 30px;
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
    transition: 0.3s;
}
.search-add-btn:hover {
    background: #fff;
    color: #d2020f;
    border: 2px solid #d2020f;
}
    </style>
