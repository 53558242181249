<template>
   <headerSection />
   <div class="simple-content--bg">
      <div class="container">
         <section class="pg">
            <div class="my-acnt-text">
               <h1 class="heading-account-text">Terms and Conditions PinSpy (part of Pinterest Mastery B.V.)</h1>
            </div>
            <!-- <h1 class="main-head"><br/><span>Terms and Conditions PinSpy (part of Pinterest Mastery B.V.)</span><br/></h1><br/> -->
            <h2 class="artiical-heading">Contents</h2>
            <a class="title1 section-title" href="#definitions">ARTICLE 1: DEFINITIONS </a>
            <a class="title1 section-title" href="#identity_company">ARTICLE 2: IDENTITY OF THE COMPANY</a>
            <a class="title1 section-title" href="#employee_work">ARTICLE 3: EMPLOYEE'S WORK </a>
            <a class="title1 section-title" href="#applicability">ARTICLE 4: APPLICABILITY </a>
            <a class="title1 section-title" href="#offer">ARTICLE 5: OFFER</a>
            <a class="title1 section-title" href="#agreements">ARTICLE 6: AGREEMENTS</a>
            <a class="title1 section-title" href="#the_plateform">ARTICLE 7: THE PLATFORM </a>
            <a class="title1 section-title" href="#registration">ARTICLE 8: Registration and Use of Password</a>
            <a class="title1 section-title" href="#payment">ARTICLE 9: PAYMENT</a>
            <a class="title1 section-title" href="#cancellation">ARTICLE 10: CANCELLATION </a>
            <a class="title1 section-title" href="#subscriptions">ARTICLE 11: SUBSCRIPTIONS </a>
            <a class="title1 section-title" href="#privacy_policy">ARTICLE 12: PRIVACY POLICY</a>
            <a class="title1 section-title" href="#engaging_third_parties">ARTICLE 13: ENGAGING THIRD PARTIES</a>
            <a class="title1 section-title" href="#liability">ARTICLE 14: LIABILITY</a>
            <a class="title1 section-title" href="#default_dissolution">ARTICLE 15: DEFAULT AND DISSOLUTION</a>
            <a class="title1 section-title" href="#force_majeure">ARTICLE 16: FORCE MAJEURE </a>
            <a class="title1 section-title" href="#intellectual">ARTICLE 17: INTELLECTUAL PROPERTY AND COPYRIGHT</a>
            <a class="title1 section-title" href="#complaints">ARTICLE 18: COMPLAINTS PROCEDURE</a>
            <a class="title1 section-title" href="#applicable_law">ARTICLE 19: APPLICABLE LAW  </a>
            <a class="title1 section-title" href="#amendment_clause">ARTICLE 20: AMENDMENT CLAUSE</a>
         </section>
         <section class="pg" id="definitions">
            <h2 class="artiical-heading">ARTICLE 1: DEFINITIONS</h2>
            <h3 class="sub-head">Pinterest Mastery B.V. also trading under the name PinSpy</h3>
            <p class="para">The entrepreneur acting in the exercise of a profession or business, further referred to as: the entrepreneur; </p>
            <h3 class="sub-head">Company:</h3>
            <p class="para">The company that acts in the exercise of a profession or business. Further to be called: customer;</p>
            <h3 class="sub-head">Consumer:</h3>
            <p class="para">The natural person who is not acting in the exercise of a profession or business and enters into an 
               agreement (at a distance) with the entrepreneur. Hereinafter to be called: customer; 
            </p>
            
            <h3 class="sub-head">BW:</h3>
            <p class="para">Civil Code of the Netherlands;</p>
            <h3 class="sub-head">Agreement:</h3>
            <p class="para">Offer and acceptance between the entrepreneur and customer;</p>
            <h3 class="sub-head">Agreement:</h3>
            <p class="para">Offer and acceptance between the entrepreneur and customer;</p>
            <h3 class="sub-head">Distance contract:</h3>
            <p class="para">An agreement whereby, in the context of a system organized by the entrepreneur for distance selling of
               products and/or services, up to and including the conclusion of the agreement, exclusive use is made of
               one or more techniques for distance communication; 
            </p>
            <h3 class="sub-head">Right of withdrawal:</h3>
            <p class="para">The possibility for the customer to waive the agreement within the cooling-off period.</p>
            <h3 class="sub-head">Service:</h3>
            <p class="para">All products and services provided to the Customer by PinSpy and/or third parties engaged by it,
               including providing access to Programs purchased on the Platform, as well as all other work performed
            </p>
            <h3 class="sub-head">User:</h3>
            <p class="para">The natural person whether or not in the exercise of a profession or business who visits the Platform,
               uses and/or views content.
            </p>
            <h3 class="sub-head">Account:</h3>
            <p class="para">User must create an account for the purpose of using PinSpy’s platform.</p>
            <h3 class="sub-head">Subscription:</h3>
            <p class="para">The fixed-term subscription which gives
               the User access to the program during
               the subscription period.
            </p>
            <h3 class="sub-head">Program:</h3>
            <p class="para">An online course from PinSpy purchased by Customer under an Agreement.</p>
            <h3 class="sub-head">Offer:</h3>
            <p class="para">any Offer to the User for the provision of services by PinSpy.</p>
            <h3 class="sub-head">Platform:</h3>
            <p class="para">The online platform on which the Customer can find their purchased Programs.</p>
            <!-- <div><a name="link_bookmark_0"><span>&nbsp</span></a></div> -->
         </section>
         <section class="pg" id="identity_company">
            <h2 class="artiical-heading">ARTICLE 2: IDENTITY OF THE COMPANY</h2>
            <h3 class="sub-head">Pinterest Mastery B.V. also trading under the name PinSpy</h3>
            <p class="para">The entrepreneur acting in the exercise of a profession or business, further referred to as: the entrepreneur; </p>
            <h3 class="sub-head">Name of the entrepreneur:</h3>
            <p class="para">Pinterest Mastery B.V. also trading under the name PinSpy </p>
            <h3 class="sub-head">Establishment address:</h3>
            <p class="para">Pinterest Mastery B.V. </p>
            <p class="para">KVK: 81637810</p>
            <p class="para">BTW: NL862165520B01</p>
            <p class="para">Telderslaan 23</p>
            <p class="para">3527KD, Utrecht</p>
            <h3 class="sub-head">Email address:</h3>
            <a href="mailto:info@pin-spy.com" class="para">info@pin-spy.com</a>
            <h3 class="sub-head">Phone number:</h3>
            <p class="para">+31624224616</p> 
            <h3 class="sub-head">Chamber of Commerce number:</h3>
            <p class="para">81637810</p>
            <!-- <div><a name="link_bookmark_0"><span>&nbsp</span></a></div> -->
         </section>
         <section class="pg" id="employee_work">
            <h2 class="artiical-heading">ARTICLE 3: EMPLOYEE'S WORK</h2>
            <p class="para">Showcasing current trends and finding top converting content on Pinterest. </p>
         </section>
         <section class="pg" id="applicability">
            <h2 class="artiical-heading">ARTICLE 4: APPLICABILITY</h2>
            <p class="para"> 1. These Terms and Conditions apply to all offers and to all contracts concluded with the Entrepreneur. </p>
            <p class="para"> 2. General terms and conditions of the client do not apply. In accordance with article 6:225 paragraph 3
               of the Dutch Civil Code, the general terms and conditions of the entrepreneur always apply.  
            </p>
            <p class="para"> 3. Before the remote agreement is concluded, the text of these general conditions will be made
               available to the customer. If this is not reasonably possible, before the (distance) agreement is
               concluded, it will be indicated that the general conditions are available for inspection at the
               entrepreneur's premises and that they will be sent free of charge as soon as possible at the customer's request. 
            </p>
            <p class="para"> 4. If the distance contract is concluded electronically, then, contrary to the previous paragraph,
               and before the contract is concluded, the text of these general terms and conditions may be made
               available to the customer electronically in such a way that the customer can easily store them on a
               durable data carrier. If this is not reasonably possible, before the agreement at a distance is concluded,
               it will be indicated where the general conditions can be consulted electronically and that they will be
               sent to the customer free of charge at his request electronically or in another way. 
            </p>
            <p class="para"> 5. Other terms and conditions will only form part of the agreements concluded between the
               parties if and insofar as both parties have expressly agreed to this in writing. 
            </p>
            <p class="para">6. The acceptance and retention by the customer, without comment, of a quotation or order
               confirmation which refers to these terms and conditions shall be deemed to constitute agreement to
               the application thereof.
            </p>
            <p class="para">7. A client to whom these general terms and conditions have applied is also deemed to have
               agreed to the application of these general terms and conditions to later requests from the client, to
               later offers issued by the Company, to later orders from the client, to later order confirmations from the
               Company, to later contracts to be concluded by the client with the Company and to all other later legal
               relationships between the client and the Company. 
            </p>
            <p class="para">8. Should any provision of these general terms and conditions not be applicable or in conflict with
               public order or the law in the opinion of the competent court, only the provision in question will be
               considered as not written, but these general terms and conditions will remain in full force for the rest. Instead of a possibly invalid provision,
               a provision that comes closest to the intention of the parties will apply.  
            </p>
            <p class="para">9. The possible inapplicability of (part of) a provision of these general conditions does not affect the
               applicability of the other provisions. 
            </p>
            <p class="para">10. Other terms and conditions shall only form part of the agreement concluded between the parties if and
               insofar as both parties have expressly agreed to this in writing. The acceptance and retention by the
               customer, without comment, of a quotation or order confirmation which refers to these terms and
               conditions shall be deemed to constitute agreement to their application. The possible inapplicability of any
               (part of any) provision of these general terms and conditions shall not affect the applicability of the
               remaining provisions.  
            </p>
            <p class="para">11. Contracts are only binding by written confirmation from the entrepreneur. Additions or changes to the
               general terms and conditions or otherwise changes or additions to this agreement only become binding
               after written confirmation by the entrepreneur. 
            </p>
         </section>
         <section class="pg" id="offer">
            <h2 class="artiical-heading">ARTICLE 5: OFFER</h2>
            <p class="para"> 1. The offer is without engagement. The entrepreneur has the right to change and adapt the offer.
               All offers, quotations, price lists, deadlines for execution etc. of the entrepreneur are without
               engagement, unless they contain a deadline for acceptance. If a quotation or offer contains a nonbinding offer and this offer is accepted by the Customer, the Entrepreneur has the right to withdraw the
               offer within five days of receiving the acceptance.  
            </p>
            <p class="para"> 2. If an offer has a limited period of validity or is made subject to conditions, this will be explicitly
               stated in the offer
            </p>
            <p class="para"> 3. The entrepreneur may rely on the accuracy of all the information provided by the customer
               during the application.  
            </p>
            <p class="para">4. The offer contains a complete and accurate description of the services offered. The description is
               sufficiently detailed to enable the customer to make a proper assessment of the offer. If the
               entrepreneur uses images, these are a true reflection of the products and / or services offered.
               Obvious mistakes or obvious errors in the offer are not binding on the entrepreneur. 
            </p>
            <p class="para">5. All images, specifications in the offer are indicative and may not lead to compensation or
               dissolution of the contract. Images are a true representation of the products offered.  
            </p>
            <p class="para">6. Each offer contains such information that it is clear to the consumer what rights and obligations
               are attached to the acceptance of the offer. This concerns in particular: 
               The price excluding taxes;<br/>
               - The way in which the agreement will be concluded and which actions are necessary for this;<br/>
               - Whether or not the right of withdrawal is applicable;<br/>
               - The method of payment and execution of the agreement;<br/>
               - The period for accepting the offer, or the period within which the entrepreneur guarantees the price;<br/>
               - The level of the rate of distance communication if the cost of using the technology for distance
               communication is calculated on a basis other than the regular basic rate for the means of
               communication used;<br/>
               - Whether the agreement is archived after its conclusion, and if so in what way it can be consulted by
               the customer;<br/>
               - The manner in which the customer, before concluding the agreement, can check the data provided by
               him in the context of the agreement and, if desired, correct them;<br/>
               - Any other languages besides Dutch in which the agreement can be concluded;<br/>
               - The codes of conduct to which the entrepreneur is subject and the way the customer can consult
               these codes of conduct electronically;<br/>
               - The minimum duration of the distance contract in the event of an extended transaction. <br/> 
            </p>
            <p class="para">7. A client to whom these general terms and conditions have applied is also deemed to have
               agreed to the application of these general terms and conditions to later requests from the client, to
               later offers issued by the Company, to later orders from the client, to later order confirmations from the
               Company, to later contracts to be concluded by the client with the Company and to all other later legal
               relationships between the client and the Company. 
            </p>
            <p class="para">8. Should any provision of these general terms and conditions not be applicable or in conflict with
               public order or the law in the opinion of the competent court, only the provision in question will be
               considered as not written, but these general terms and conditions will remain in full force for the rest. Instead of a possibly invalid provision,
               a provision that comes closest to the intention of the parties will apply.  
            </p>
            <p class="para">9. The possible inapplicability of (part of) a provision of these general conditions does not affect the
               applicability of the other provisions. 
            </p>
            <p class="para">10. Other terms and conditions shall only form part of the agreement concluded between the parties if and
               insofar as both parties have expressly agreed to this in writing. The acceptance and retention by the
               customer, without comment, of a quotation or order confirmation which refers to these terms and
               conditions shall be deemed to constitute agreement to their application. The possible inapplicability of any
               (part of any) provision of these general terms and conditions shall not affect the applicability of the
               remaining provisions.  
            </p>
            <p class="para">11. Contracts are only binding by written confirmation from the entrepreneur. Additions or changes to the
               general terms and conditions or otherwise changes or additions to this agreement only become binding
               after written confirmation by the entrepreneur. 
            </p>
         </section>
         <section class="pg" id="agreements">
            <h2 class="artiical-heading">ARTICLE 6: AGREEMENTS </h2>
            <p class="para">1. The agreement comes into being at the moment of acceptance by the customer of the offer and
               the fulfilment of the conditions set therein.
            </p>
            <p class="para">2. If the customer has accepted the offer electronically, the entrepreneur shall immediately
               confirm receipt of acceptance of the offer electronically. As long as the receipt of this acceptance has
               not been confirmed by the entrepreneur, the customer may dissolve the agreement.
            </p>
            <p class="para">3. If the agreement is created electronically, the entrepreneur will take appropriate technical and
               organizational measures to secure the electronic transmission of data and will ensure a secure web
               environment. If the client is able to pay electronically, the entrepreneur will take appropriate security
               measures. Please see the Privacy Policy
            </p>
            <p class="para">4. The entrepreneur can - within legal frameworks - inform himself whether the customer can fulfil
               his payment obligations, as well as all those facts and factors that are important for a responsible
               conclusion of the remote agreement. If, on the basis of this investigation, the entrepreneur has good
               reason not to enter into the agreement, he is entitled to refuse an order or application or to attach
               special conditions to the implementation.
            </p>
            <p class="para">5. The entrepreneur will, together with the product or service, send the client the following
               information, in writing or in such a way that the client can store it in an accessible way on a durable data
               carr<br/>
               - The visiting address of the establishment of the entrepreneur where the customer can go with
               complaints;<br/>
               - the conditions on which and the way in which the Customer may exercise the right of withdrawal, or a
               clear statement concerning the exclusion of the right of withdrawal;<br/>
               - the information on guarantees and existing service after purchase;<br/>
               - the data included in article 4, paragraph 3 of these conditions, unless the entrepreneur has already
               provided these data to the customer before the execution of the agreement;<br/>
               - The requirements for terminating the agreement if the agreement has a duration of more than one
               year or is indefinite.<br/>
            </p>
            <p class="para">6. In case of a duration contract, the provision in the previous paragraph only applies to the first
               delivery.
            </p>
            <p class="para">7. Every agreement is entered into under the suspensive conditions of sufficient availability of the
               products concerned.
            </p>
            <p class="para">8. Verbal undertakings are subject to reservation and are not binding, unless subsequently
               confirmed in writing by the Company.
            </p>
            <p class="para">9. Supplements or amendments to the general terms and conditions or otherwise amendments or
               additions to the contract must be agreed with the proprietor and confirmed in writing
            </p>
         </section>
         <section class="pg" id="the_plateform">
            <h2 class="artiical-heading">ARTICLE 7: THE PLATFORM</h2>
            <p class="para">1. When entering into an Agreement for the purchase of one or more Programs, the Customer obtains
               access to the Platform, in which they will find the Programs purchased. The Customer will have access to
               the Platform for as long as they have an account. The right to access ends when: <br/>
               - the Agreement is dissolved or; <br/>
               -PinSpy considers it necessary for any other reason to temporarily or permanently deny the Customer
               access to the Platform. In this case, the Customer will be informed by PinSpy of the reason for denying
               him access.<br/>
            </p>
            <p class="para">2. Some parts of the Programs or the Platform require an additional purchase. If the Customer makes
               purchases within the Platform, a new contract is entered into between him and PinSpy for this
               purpose. 
            </p>
            <p class="para">3.The Platform may offer products and/or services from third parties, allowing visitors to enter into a
               contract with these third parties through the Platform. PinSpy is not responsible for the content
               and/or implementation of these contracts and refers to the General Terms and Conditions of the
               third parties involved. PinSpy in no way becomes a party to the contract between the customer and
               the third party. 
            </p>
         </section>
         <section class="pg" id="registration">
            <h2 class="artiical-heading">ARTICLE 8: Registration and Use of Password</h2>
            <p class="para">- We may require each user to have a unique user name and password combination in order to
               access and use certain features or functions of the Site. Please read our Privacy Policy, which
               describes the personally identifiable information ("Personal Information") we collect, use,
               disclose, manage and store.<br/>
               - As part of the registration process for the feature or function, you will choose a user name and
               password. Your username and password are personal to you and you may not allow any others
               person(s) to use your user name or password under any circumstances.<br/>
               - We are not liable for any harm caused or related to the theft or misappropriation of your
               username or password, disclosure of your username or password, or your authorization of
               anyone else to use your username or password.<br/>
               - You agree to notify us immediately if you become aware of or believe there is or may have been
               any unauthorized use of (or activity using) your username or password or any other need to
               deactivate your username or password due to security concerns.’<br/>
               - Passwords are issued on a personal basis. Accordingly, all content or instructions transmitted by
               or received from anyone presenting your password on the site will be deemed binding on you.
               You agree that you are solely responsible and liable for all actions taken via your password,
               whether or not made with your knowledge or authority. You agree to guard your password
               carefully, with the full awareness that a failure to keep it secure will enable others to engage in
               transactions through the Site for which you will be legally responsible.<br/>
            </p>
         </section>
         <section class="pg" id="payment">
            <h2 class="artiical-heading">ARTICLE 9: PAYMENT</h2>
            <p class="para">1. Payment is made by credit card or iDEAL via the Website. If the Customer pays by credit card
               and reverses the amount paid, this will result in automatic termination of the Agreement and denial
               of access to the purchased Program.
            </p>
            <p class="para">2. Pinspy works with:<br/>
               - Monthly subscription<br/>
               - Subscription per quarter<br/>
               - Annual subscription<br/>
            </p>
            <p class="para">3. PinSpy will charge a credit limitation of € 6.00 per invoice. The customer must pay this if
               payment is not made before the due date.
            </p>
            <p class="para">4. In case of late payment the customer will receive a written or electronic reminder.</p>
            <p class="para">5. In case of non-timely payment, the customer will fail to comply with the agreement and will be
               in default without any notice of default being required.
            </p>
            <p class="para">6. All costs, both judicial and extrajudicial, that may arise from the collection of the amounts owed
               under the contract(s) entered into with the entrepreneur, shall be for the customer's account. 6. The
               customer has the duty to immediately report inaccuracies in payment data provided or mentioned to
               the entrepreneur.
            </p>
            <p class="para">7. In case of non-payment by the customer, the entrepreneur has the right, subject to legal
               restrictions, to charge the reasonable costs made known in advance to the customer.
            </p>
            <p class="para">8. The Entrepreneur hands over the collection of debts to Debtt B.V., De oude Ijssel 3, 8253 PV
               Dronten, Dutch Chamber of Commerce 82929823. 
            </p>
         </section>
         <section class="pg" id="cancellation">
            <h2 class="artiical-heading">ARTICLE 10: CANCELLATION </h2>
            <p class="para">1. Cancellation is possible within fourteen (14) days after purchase, under the express condition that
               Customer has used the application more than 1 time. If the Customer used the application more than time within these 14 days, 
               they expressly waive their right to cancel.
            </p>
            <p class="para">2. PinSpy can check for this purpose whether Customer has used the application or not. If PinSpy
               records show that the Customer has started and used the application, cancellation of the contract is
               no longer possible.
            </p>
            <p class="para">3. Cancellation must be in writing and expressly confirmed by PinSpy.</p>
         </section>
         <section class="pg" id="subscriptions">
            <h2 class="artiical-heading">ARTICLE 11: SUBSCRIPTIONS</h2>
            <p class="para">1. PinSpy offers payment per Subscription for some products. Customer has the choice between a
               monthly subscription (with a duration of 1 month) or if available a quarterly subscription (with a
               duration of 3 months). 
            </p>
            <p class="para">2. The Subscription will automatically renew for the duration of one term upon expiration. </p>
            <p class="para">3. When purchasing the Subscription, payment is made directly via iDEAL or credit card. The
               customer authorizes PinSpy to charge the agreed amount for each automatic renewal of the
               Subscription by means of a direct debit. 
            </p>
         </section>
         <section class="pg" id="privacy_policy">
            <h2 class="artiical-heading">ARTICLE 12: PRIVACY POLICY </h2>
            <p class="para">1. On all our services, in addition to the terms and conditions also the Privacy Policy applies. </p>
            <p class="para">2. Entrepreneur is responsible for the processing of your personal data and attaches great
               importance to the protection of your personal data. 
            </p>
            <p class="para">3. In this Privacy Policy we provide information on how we handle personal data. We do
               everything in our power to guarantee your privacy and therefore handle personal data with care. In all
               cases we comply with the applicable laws and regulations, including the General Data Protection
               Regulation. This implies that we in any case:<br/>
               - Process your personal data in accordance with the purpose for which they were provided, these
               purposes and types of personal data are described in this Privacy Policy;<br/>
               - Limit the processing of your personal data to only those data that are minimally necessary for
               the purposes for which they are processed;<br/>
               - Ask for your explicit permission if we need this for the processing of your personal data;
               - Have taken appropriate technical and organizational measures so that the security of your
               personal data is guaranteed;<br/>
               - Not pass on personal data to other parties, unless this is necessary for the execution of the
               purposes for which they were provided;<br/>
               - Are aware of your rights regarding your personal data, want to point them out to you and
               respect them. <br/> 
            </p>
            <p class="para">3. If after reading our Privacy Policy, or in a more general sense, you have any questions about it or
               wish to contact us, you can do so via the contact details at the bottom of this document. 
            </p>
            <p class="para">4. To review your personal information and have it removed, contact us by EMAIL.
            </p>
            <p class="para">5. To modify or delete your personal data, please justify this desired modification.  </p>
            <p class="para">6. In using this website or sending us email, you are communicating with us electronically. You
               consent to receive communications from us electronically subject to the Privacy Policy. We will
               communicate with you by email or by posting notices on this site. You agree that all agreements,
               notices, disclosures and other communications that we provide to you electronically satisfy any legal
               requirement that such communications be in writing.
            </p>
         </section>
         <section class="pg" id="engaging_third_parties">
            <h2 class="artiical-heading">ARTICLE 13: ENGAGING THIRD PARTIES </h2>
            <p class="para">The entrepreneur has the right to engage third parties for the execution of what has been agreed.  </p>
         </section>
         <section class="pg" id="liability">
            <h2 class="artiical-heading">ARTICLE 14: LIABILITY</h2>
            <p class="para">1. The proprietor does not accept any liability for damage, including consequential loss, resulting
               from his actions or omissions in the broadest sense of the word, except in so far as this is attributable to
               his gross negligence, gross fault and/or intent, or if statutory provisions of mandatory law dictate
               otherwise. The same restriction applies to members of staff and/or other third parties engaged by the
               entrepreneur in carrying out his activities.
            </p>
            <p class="para">2. Without prejudice to the provisions in the other paragraphs of this article, the liability of the
               entrepreneur - for whatever reason - is limited to the amount of the net price of the work carried out.
               Fulfilment of this guarantee is regarded as the only and complete compensation.
            </p>
            <p class="para">3. Without prejudice to the provisions of the previous paragraph of this article, the proprietor is
               never required to pay damages exceeding the sum insured, insofar as the damage is covered by a
               business liability insurance policy taken out by the proprietor.
            </p>
            <p class="para">4. In all cases the period within which the entrepreneur can be sued for compensation for the
               determined damage is limited to six months, counting from the moment that the indebtedness for the
               compensation has been established.
            </p>
            <p class="para">5. If the client is a natural person who is not acting in the course of a profession or business, there
               is a maximum period of 1 (one) year, counting from the moment at which the obligation to pay damages
               has been established, within which the entrepreneur can be called upon to compensate for the
               determined damage.
            </p>
            <p class="para">6. The client loses his rights towards the entrepreneur, is liable for all damage and indemnifies the
               entrepreneur against any claims by third parties regarding compensation of damage if and insofar as -
               the aforementioned damage has arisen as a result of inexpert use and/or use contrary to the
               instructions of the proprietor and/or inexpert storage of the items supplied by the client; <br/>
               - the aforementioned damage has arisen as a result of errors or inaccuracies in data, materials, data
               carriers, etc. which are provided and/or prescribed to the entrepreneur by or on behalf of the client; -
               the aforementioned damage has arisen as a result of instructions given to the entrepreneur by or on
               behalf of the client. 
            </p>
         </section>
         <section class="pg" id="default_dissolution">
            <h2 class="artiical-heading">ARTICLE 15: DEFAULT AND DISSOLUTION </h2>
            <p class="para">1. If the customer defaults in any way, he will already be in default without any notice of default
               being required.
            </p>
            <p class="para">2. Without prejudice to the provisions of the law, the entrepreneur has the right to suspend the
               performances within the concluded agreement or to declare the agreement dissolved, completely or
               partially, without judicial intervention, at his discretion. 
            </p>
            <p class="para">3. Notwithstanding the provisions of the other articles of these terms and conditions, the
               agreement entered into between the client and the entrepreneur will be dissolved without judicial
               intervention and without any notice of default being required, at the time when the client is declared
               bankrupt, is granted a suspension of payments, is liquidated, or is taken over by a third party, the
               customer intends to establish himself/herself outside the Netherlands or is the subject of an attachment
               order, is placed under guardianship or administration or otherwise loses the power of disposition or
               legal capacity with regard to its assets or parts thereof, unless the guardian in the bankruptcy or the
               administrator in the matter of the suspension of payments recognizes the obligations arising from the
               agreement as liabilities of the estate.
            </p>
         </section>
         <section class="pg" id="force_majeure">
            <h2 class="artiical-heading">ARTICLE 16: FORCE MAJEURE</h2>
            <p class="para">1. In the event of force majeure, the entrepreneur has the right to terminate all or part of the
               agreement without being obliged to pay any compensation. 
            </p>
            <p class="para">2. Force majeure is understood to mean: every circumstance independent of the entrepreneur's
               intention which makes proper fulfilment of the contract temporarily or permanently impossible. In the
               event of temporary impossibility, the entrepreneur also has the right to suspend his performance
               without being obliged to pay compensation. 
            </p>
            <p class="para">3. Circumstances in which there is a question of non-attributable non-compliance are deemed to
               include: war, riots, mobilisation, domestic and foreign uprisings, government measures, strikes and
               lockouts by employees or the threat of these and similar circumstances; disruption of the exchange
               rates existing at the time when the contract was entered into; business interruptions as a result of fire,
               accidents or other occurrences and natural phenomena, irrespective of whether or not the
               noncompliance or late compliance takes place at the proprietor's..., its suppliers or third parties
               engaged by it for the purposes of fulfilling the obligations. 
            </p>
            <p class="para">4. In the event that the client, in any way, remains in default with regard to the entrepreneur to
               meet his obligations promptly, in the event of a suspension of payments, application for a (provisional)
               suspension of payments, bankruptcy, seizure, cession of assets or liquidation of the business of the
               client, all that is owed by him to the entrepreneur on account of any contact will become immediately
               due and payable in full. 
            </p>
         </section>
         <section class="pg" id="intellectual">
            <h2 class="artiical-heading">ARTICLE 17: INTELLECTUAL PROPERTY AND COPYRIGHT</h2>
            <p class="para">1.All intellectual property rights and copyrights within and on the services of the entrepreneur,
               including graphic designs, ideas, advice and the like, belong exclusively to the entrepreneur and are
               explicitly not transferred to the client. 
            </p>
            <p class="para">2. The client is forbidden to disclose and/or multiply, modify in the broadest sense of the word, or
               make available in any way to third parties any information that has been shared by the entrepreneur
               without the explicit prior written consent of the entrepreneur. 
            </p>
            <p class="para">3.The client is not permitted to remove or change any indication concerning copyrights, brands,
               trade names or other intellectual property rights from the materials, including indications concerning
               the confidential nature and secrecy of materials.
            </p>
            <p class="para">4. Each customer retains ownership, responsibility for and/or other applicable rights to content
               created by customer. 
            </p>
            <p class="para">5. The parties will inform each other and take joint action if any IP rights are infringed. </p>
         </section>
         <section class="pg" id="complaints">
            <h2 class="artiical-heading">ARTICLE 18: COMPLAINTS PROCEDURE </h2>
            <p class="para">1.The entrepreneur has a sufficiently publicized complaint procedure and handles the complaint
               in accordance with this complaint procedure.  
            </p>
            <p class="para">2. Complaints about the implementation of the agreement must be submitted to the entrepreneur
               within reasonable time, fully and clearly described, after the customer has found the defects.  
            </p>
            <p class="para">3.Complaints submitted to the entrepreneur shall be answered within a period of 14 days,
               calculated from the date of receipt. If a complaint requires a foreseeably longer processing time, the
               entrepreneur will respond within the period of 14 days with a message of receipt and an indication of
               when the customer can expect a more detailed answer. 
            </p>
            <p class="para">4.If the complaint cannot be solved in mutual consultation, a dispute arises that is subject to the
               dispute resolution procedure.  
            </p>
         </section>
         <section class="pg" id="applicable_law">
            <h2 class="artiical-heading">ARTICLE 19: APPLICABLE LAW</h2>
            <p class="para">1.All legal relationships arising from or related to the contract of carriage are governed by Dutch
               law. With the exclusion of the provisions of international conventions including the Vienna Sales
               Convention, in so far as these do not contain mandatory law.  
            </p>
            <p class="para">2. The entrepreneur has the right to unilaterally amend these user conditions. The customer will
               be informed of this as soon as possible. 
            </p>
            <p class="para">3.All disputes arising out of or in connection with the Agreement will be settled by the competent
               court in Central Netherlands, location Utrecht, unless mandatory provisions lead to the jurisdiction of
               another court. 
            </p>
         </section>
         <section class="pg" id="amendment_clause">
            <h2 class="artiical-heading">ARTICLE 20: AMENDMENT CLAUSE</h2>
            <p class="para">1.The entrepreneur reserves the right to change the general terms and conditions unilaterally and
               without the consent of the customer. 
            </p>
            <p class="para">2. Amendments to these terms and conditions will only take effect after they have been published
               in an appropriate manner, on the understanding that, in the event of applicable amendments during the
               term of an offer, the provision that is most favourable for the customer will prevail.  
            </p>
         </section>
      </div>
   </div>
   <footerSection />
</template>
<script>
import axios from 'axios';
import headerSection from "../header/header.vue";
import footerSection from "../footer/footer.vue";
export default {
	components: {
			headerSection,
			footerSection
	}
}
</script>
<style scoped>
   .artiical-heading {
   font-size: 1.75rem;
   color: #4E4E4E;
   font-weight: 700;
   margin: 15px 0px;
   }
   .section-title {
   color: #4E4E4E;
   position: relative;
   overflow: hidden;
   display: block;
   }
   .title1 {
   font-size: 13pt;
   font-weight: 500;
   margin: 0;
   }
   .section-title:after {
   content: '';
   position: absolute;
   bottom: .6rem;
   width: 100%;
   height: 0;
   line-height: 0;
   border-bottom: 2px dotted #4E4E4E;
   margin-left: 8px;
   }
   .section-title:hover {
   color: #E9191F;
   text-decoration: none;
   }
   .pg {
   font-style: normal;
   padding-top: 10px;
   font-weight: normal;
   }
   .sub-head {
   margin: 15px 0px 10px 0px;
   font-weight: 700;
   }
   .para {
   margin: 0;
   }
   .sub-head {
   margin: 15px 0px 10px 0px;
   font-weight: 700;
   }
   .simple-content--bg, body section.simple-content--bg {
   background: url('~@/assets/images/privacy-bg.png');
   background-size: 100%;
   font-size: 18px !important;
   color: #4E4E4E !important;
   line-height: 32px;
   }
</style>