<template>
   <div>
      <div class="pinspy-load" v-if="loading"><span class="load"></span></div>
      <div class="login-form" :style="{ backgroundImage: `url(${backgroundUrl})` }">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-lg-6">
                  <div class="card px-5">
                     <div class="card-header text-center bg-transparent">
                        <router-link :to="{ 'name': 'home' }" style="border:none;">
                           <img :src="logoImage" class="mt-4 logo_image_setting">
                        </router-link>
                        <p class="welcome-heading">Welcome!</p>
                        <p class="welcome-login">Please create your account and activate your 14-Day Free Trial!</p>
                     </div>
                     <div class="card-body py-4 p-0">
                        <Form @submit="handleLogin" autocomplete="off">
                           <div class="alert alert-danger mb-0" v-if="showError">{{ errorMsg }}</div>
                           <!-- <span class="alert alert-success" v-if="showSuccess">{{message}}</span> -->
                           <div class="form-row">
                              <div class="form-group col-md-6">
                                 <label for="name"> First Name</label>
                                 <Field type="text" class="form-control shadow-none" name="first_name"
                                    :rules="first_name" placeholder="First name" />
                                 <ErrorMessage name="first_name" />
                              </div>
                              <div class="form-group col-md-6">
                                 <label for="name"> Last Name</label>
                                 <Field type="text" class="form-control shadow-none" name="last_name" :rules="last_name"
                                    placeholder="Last name" />
                              </div>
                           </div>
                           <div class="form-group">
                              <label for="name"> Email</label>
                              <Field type="email" class="form-control shadow-none" name="email" :rules="email"
                                 placeholder="Enter email" />
                              <ErrorMessage name="email" />
                           </div>
                           <div class="form-group">
                              <label for="name" class="error-mes-color">Country</label>
                              <Field as="select" class="form-control shadow-none error-color-inpt" :rules="country"
                                 name="country" placeholder-title="Country">
                                 <option v-if="country_data.loading" selected="true" value="">Loading...</option>
                                 <option v-for="country in country_data.data" :key="country.id" :value="country.name">
                                    {{ country.name }}
                                 </option>
                              </Field>
                              <ErrorMessage name="country" />
                           </div>
                           <!-- <div class="form-group">
                              <label for="name"> First Name</label>
                              <Field type="text" class="form-control shadow-none" name="first_name" :rules="first_name"
                                 placeholder="First name" />
                              <ErrorMessage name="first_name" />
                           </div>
                           <div class="form-group">
                              <label for="name"> Last Name</label>
                              <Field type="text" class="form-control shadow-none" name="last_name" :rules="last_name"
                                 placeholder="Last name" />
                              <ErrorMessage name="last_name" />
                           </div> -->
                           <!-- <div class="form-group">
                              <label for="name"> Company</label>
                              <Field type="text" class="form-control shadow-none"  name="company" placeholder="Company name (optional)"/>
                              <ErrorMessage name="company" />
                           </div> -->
                           <div class="form-row">
                              <div class="form-group col-md-6">
                                 <label for="name"> Password</label>
                                 <Field type="password" class="form-control shadow-none" name="password"
                                    :rules="password" placeholder="Enter your password" />
                                 <ErrorMessage name="password" />
                              </div>
                              <div class="form-group col-md-6">
                                 <label for="name" class="error-mes-color">Confirm Password</label>
                                 <Field type="password" class="form-control shadow-none error-color-inpt"
                                    :rules="confirm_password" name="confirm_password"
                                    placeholder="Confirm your password" />
                                 <ErrorMessage name="confirm_password" />
                              </div>
                           </div>
                           <!-- <div class="form-group">
                              <label for="name"> Password</label>
                              <Field type="password" class="form-control shadow-none" name="password" :rules="password"
                                 placeholder="Enter your password" />
                              <ErrorMessage name="password" />
                           </div>
                           <div class="form-group">
                              <label for="name" class="error-mes-color">Confirm Password</label>
                              <Field type="password" class="form-control shadow-none error-color-inpt"
                                 :rules="confirm_password" name="confirm_password"
                                 placeholder="Confirm your password" />
                              <ErrorMessage name="confirm_password" />
                           </div> -->
                           <!-- <div class="form-group" >
                              <label for="name" class="error-mes-color">Street Address</label>
                              <Field type="text" class="form-control shadow-none error-color-inpt" :rules="street_address" name="street_address" placeholder="Streetname, House number"/>
                              <ErrorMessage name="street_address" />
                           </div> -->
                           <!-- <div class="form-group" >
                              <label for="name" class="error-mes-color">Town / City</label>
                              <Field type="text" class="form-control shadow-none error-color-inpt" :rules="city" name="city" placeholder="Town / City"/>
                              <ErrorMessage name="city" />
                           </div>
                           <div class="form-group" >
                              <label for="name" class="error-mes-color">State</label>
                              <Field type="text" class="form-control shadow-none error-color-inpt" :rules="state" name="state" placeholder="State"/>
                              <ErrorMessage name="state" />
                           </div>
                           <div class="form-group" >
                              <label for="name" class="error-mes-color">Postcode / Zip</label>
                              <Field type="text" class="form-control shadow-none error-color-inpt" :rules="zip_code" name="zip_code" placeholder="Postcode / Zip"/>
                              <ErrorMessage name="zip_code" />
                           </div> -->
                           <!-- <div class="form-group custom-ns" >
                              <label for="name" class="error-mes-color">Phone number</label>
                              <div class="phone-number">
                                    <vue-tel-input v-model="phone_no" @keypress="onlyNumbers" @input="onInput" @blur="validatePhone" autocomplete="off" @change="validatePhone, onlyNumbers"></vue-tel-input>
                                    <span class="textarea-error" v-if="customValidation"> This is a required field.</span>
                              </div>
                           </div> -->
                           <div class="login-btn">
                              <a class="m-0">
                                 <!-- <button type="submit" class="btn" @click="validatePhone()">
                                    Register
                                    <img :src="require('@/assets/images/arrow-right.svg')">
                                 </button> -->
                                 <button type="submit" class="btn">
                                    Register
                                    <img :src="require('@/assets/images/arrow-right.svg')">
                                 </button>
                              </a>
                           </div>
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import backgroundUrl from "@/assets/images/login-banner.png";
import logoImage from "@/assets/images/logo_red.svg";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { mapActions } from "vuex";
import axios from 'axios';
import authHeader from '../services/auth-header';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default {
   name: 'Register',
   components: {
      Form,
      Field,
      ErrorMessage,
      //VueTelInput
   },
   data() {
      return {
         backgroundUrl,
         logoImage,
         email: yup.string().email().required(),
         first_name: yup.string().required(),
         last_name: yup.string().required(),
         // company: yup.string().required(),
         password: yup.string().required().min(8),
         confirm_password: yup.string().required().min(8),
         street_address: yup.string().required().min(2),
         state: yup.string().required().min(2),
         country: yup.string().required('This input is required'),
         zip_code: yup.string().required(),
         city: yup.string().required(),
         phone_no: '',
         phone_country_code: '',
         showError: false,
         errorMsg: '',
         loading: false,
         message: "",
         customValidation: false,
         formData: {
            amount: "84.70",
            type: 'monthly',
         },
         country_data: {
            loading: true,
            data: []
         },
         country_code_data: {
            loading: true,
            selected: '',
            data: []
         },
         queryParams: {},
         currentDate: new Date().toISOString().split('T')[0],
      }
   },
   computed: {
      isFormDirty() {
         return Object.keys(this.fields).some(key => this.fields[key].dirty);
      },
      loggedIn() {
         return this.$store.state.auth.initialState.status.loggedIn;
      },
   },
   created() {
      this.queryParams = this.$route.query;
      console.log('Query Params:', this.queryParams);
      var subscription = this.$store.state.auth.subscription;
      if (this.loggedIn) {
         if (subscription == 1) {
            this.$router.push("/search-ad");
         } else {
            this.$router.push("/subscription");
         }
      } else {
         this.$router.push("/register");
      }
      this.getCountries();
      this.getCountryCodes();
   },
   watch: {
      $route(to, from) {
         console.log('Navigated from', from.path, 'to', to.path);
      }
   },
   methods: {
      validatePhone() {
         if (this.phone_no == '' || this.phone_no == undefined) {
            this.customValidation = true;
         } else {
            this.customValidation = false;
         }
      },
      onInput(phone, phoneObject, input) {
         if (phoneObject?.formatted) {
            this.phone_country_code = phoneObject.countryCallingCode
            this.phone_no = phoneObject.formatted
         }
      },
      onlyNumbers(event) {
         let keyCode = event.keyCode ? event.keyCode : event.which;
         if (keyCode < 48 || keyCode > 57) {
            // 46 is dot
            event.preventDefault();
         } else {
            var val = event.target.value
            if (val.length == 15) {
               event.preventDefault();
            }
            if (val.length > 15) {
               event.target.value = ''
            }
            this.customValidation = false;
         }
      },
      handleLogin(user) {
         // if (this.phone_no == '' || this.phone_no == undefined) {
         //    this.customValidation = true;
         //    return false;
         // } else {
         //    this.customValidation = false;
         // }
         //user.phone_no = this.phone_no
         //user.phone_country_code = '+' + this.phone_country_code
         user.queryParams = this.queryParams
         user.date_created = this.currentDate;

         const { password, confirm_password, ...userData } = user;
         this.loading = true;
         this.$store.dispatch("auth/register", user).then(
            (response) => {
               if (response.status == true) {
                  this.sendToZapier(userData);
                  if (response.subscription != 1) {
                     this.$router.push("/subscription");
                     return
                  } else {
                     this.$router.push("/search-ad");
                  }
                  this.$router.push("/my-account");
                  this.showError = false;
                  this.errorMsg = '';
                  this.loading = false;
               } else {
                  this.showError = true;
                  this.errorMsg = response.message;
                  this.loading = false;
               }
            }
         );
      },
      sendToZapier(userData) {
         const zapierWebhookUrl = process.env.VUE_APP_API_URL+'zapier'; // Replace with your Zapier webhook URL
         axios.post(zapierWebhookUrl, userData).then(
            (response) => {
               console.log('Zapier Response:', response);
               if (response.status === 200) {
                  //this.$router.push({ path: "/thank-you" }); // Redirect to a thank-you page or wherever you want
                  console.log('Zapier Response:------------>', response);
               } else {
                  this.showError = true;
                  this.errorMsg = 'Error sending data to Zapier';
               }
            }
         ).catch(
            (error) => {
               this.showError = true;
               this.errorMsg = 'Error sending data to Zapier';
               console.error('Zapier Error:', error);
            }
         );
      },
      mollipayment() {
         axios.post('mollie-payment', this.formData, { headers: authHeader() }).then(
            (response) => {
               if (response.status == true) {
                  this.showError = false;
                  this.loading = false;
                  this.errorMsg = '';
                  this.showSuccess = true;
                  this.message = response.message;
                  window.location.href = response.data;
               } else {
                  this.loading = false;
                  this.showSuccess = false;
                  this.showError = true;
                  this.errorMsg = response.message;
                  this.message = ''
               }
            }
         );
      },
      getCountries() {
         axios.get('/all-countries').then((response) => {
            this.country_data.data = response.data;
            this.country_data.loading = false;
         })
      },
      getCountryCodes() {
         axios.get('/country-codes').then((response) => {
            this.country_code_data.selected = response.data[0]?.dial_code || '';
            this.country_code_data.data = response.data;
            this.country_code_data.loading = false;
            // document.querySelector('select option["value='+''''+this.country_code_data.selected+'"]').setAttribute('selected',true)
         })
      }
   },
}
</script>
<style>
.country-code {
   position: absolute;
   left: 0;
   width: 100px;
   border-right: 1px solid #ced4da;
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}

.country-code-input {
   padding-left: 110px;
   border-left: 0 !important;
}

.country-code-input:focus {
   border: 1px solid #ced4da !important;
}

.login-form .card .card-body select span::first-letter {
   text-transform: uppercase;
}

.phone-number .vue-tel-input {
   border: 1px solid #D3D7DF;
   border-radius: 4px;
}

.phone-number .vue-tel-input:focus-within {
   -webkit-box-shadow: none;
   box-shadow: none;
   border-color: #222 !important;
}

.phone-number .vue-tel-input:focus {
   border-color: #222 !important;
}

.phone-number .vti__input {
   padding: 9px 0;
   border: 0 !important;
}

#app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
</style>
