<template>
  <div>
    <headerSection />

    <section class="banner-img-section" :style="{ backgroundImage: `url(${backgroundUrl})` }">
      <div class="container">
        <div class="row"></div>
      </div>
    </section>
    <section class="info-screen-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="for-bg-color">
              <div class="col-12">
                <div class="row back-btn px-4 py-lg-5 py-4">
                  <div class="col-lg-6 col-sm-6 col-12 for-text-backads align-self-center">
                    <router-link class="search-ad-back mx-0" :to="{ name: 'Ad Search' }"><i
                        class="fas fa-arrow-left mr-2"></i>Back
                    </router-link>
                  </div>
                </div>

                <div class="mx-md-5 mx-2 mt-5">
                  <div class="row img-section">
                    <div class="col-lg-6 h-100">
                      <div class="image-setup position-relative h-100">
                        <a class="go-to" :href="'https://www.pinterest.com/pin/' + detail.pinnum
                          " v-if="detail.link != 'None'" target="_blank">
                          <img :src="require('@/assets/images/up-arrow.svg')" />
                        </a>
                        <div class="hero-card-img hero-card-img-css pin-img position-relative"
                          v-if="detail.type == 'image'">
                          <img :src="detail.url" />
                        </div>
                        <div class="hero-card-img hero-card-video-css position-relative"
                          v-if="detail.type == 'video' || detail.type == ''">
                          <vue3-video-player :src="detail.url" preload="auto"></vue3-video-player>
                        </div>
                        <div class="bottom-img d-flex video-button" v-if="detail.type == 'video' || detail.type == ''">
                          <a class="shop-btn" :href="detail.link" v-if="detail.link != 'None'" target="_blank">Shop Now
                          </a>

                          <a :src="detail.url" class="download" @click="forceDownload(detail.url, detail)">
                            <img :src="require('@/assets/images/downl.svg')" v-if="!this.currentId" />
                            <q-circular-progress indeterminate size="22px" color="blue" v-if="this.currentId" />
                          </a>
                        </div>
                        <div class="bottom-img d-flex" v-if="detail.type == 'image'">
                          <a class="shop-btn" :href="detail.link" v-if="detail.link != 'None'" target="_blank">Shop Now
                          </a>
                          <a :src="detail.url" class="download" @click="forceDownload(detail.url, detail)">
                            <img :src="require('@/assets/images/downl.svg')" v-if="!this.currentId" />
                            <q-circular-progress indeterminate size="22px" color="blue" v-if="this.currentId" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 h-100 pr-0">
                      <div class="h-100 position-relative">
                        <div class="main-content">
                          <div class="d-flex flex-wrap text-area">
                            <div class="col-sm-8 col-8 pl-0 ml-auto">
                              <div class="discription info-section info-section-height">
                                <h1 class="Summit-text">{{ detail.title }}</h1>
                                <p v-if="formattedFirstSeen">
                                  <span class="bold-branding">First Seen:</span> {{ formattedFirstSeen }}
                                </p>
                                <p v-if="formattedLastSeen">
                                  <span class="bold-branding">Last Seen:</span> {{ formattedLastSeen }}
                                </p>
                                <p v-if="detail.createdAt != ''">
                                  <span class="bold-branding">Created On:</span> {{ formattedCreatedAt }}
                                </p>
                              </div>
                            </div>
                            <div class="col-sm-4 col-4 pl-0 ml-auto">
                              <div class="pin-int d-flex justify-content-center">
                                <div class="mr-1 align-self-center">
                                  <img :src="require('@/assets/images/pin.svg')" />
                                </div>
                                <div class="align-self-center">
                                  <span>{{ kFormatter(detail.repins) }} Pins</span>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6 col-6 align-self-center text-right">
                                       <button class="small-red-button">Save</button>
                                    </div> -->
                          </div>
                          <div class="main-content-block">
                            <div class="advertiser-section pl-0 pb-0">
                              <div class="avatar">
                                <!-- <img src="https://i.pinimg.com/280x280_RS/d5/51/8f/d5518f3f30178ecc397cf53f7a90f1d6.jpg"
                                  alt="User Avatar"> -->
                                <img :src="detail.creatorAvatarUrl || require('@/assets/images/favicon.png')"
                                  alt="User Avatar">
                              </div>
                              <div class="advertiser-info">
                                <!-- <p class="name">John Doe</p>
                                <p class="followers">Followers: 1,234</p> -->
                                <!-- <p class="name">{{ detail.creatorName || 'Unknown' }}</p> -->
                                <p class="name">
                                  <a class="m-0 text-dark" style="text-decoration: none;"
                                    :href="detail.creatorUrlSlug ? `https://pinterest.com/${detail.creatorUrlSlug}` : null"
                                    target="_blank" v-if="detail.creatorUrlSlug">
                                    {{ detail.creatorName || 'Unknown' }}
                                  </a>
                                  <span v-else>{{ 'Unknown' }}</span>
                                </p>
                                <!-- <p class="name">
                                  <a class="m-0 text-dark" style="text-decoration: none;" :href="`https://pinterest.com/${detail.creatorName}`" target="_blank">
                                    {{ detail.creatorName || 'Unknown' }}
                                  </a>
                                </p> -->
                                <p class="followers">Followers: {{ detail.followerCount || '0' }}</p>
                              </div>
                            </div>
                            <div class="mb-2">
                              <a class="ml-0" @click="applyAdvertiserFilter(detail.creatorName)"
                                style="text-decoration: underline;">Show All ads from the
                                advertiser</a>
                            </div>
                            <p v-if="groups.length > 0">
                              <span class="bold-branding">Category: </span>
                              <span v-for="(grp, ind) in groups" :key="grp">
                                {{ grp
                                }}<span v-if="ind != groups.length - 1">, </span>
                              </span>
                            </p>
                            <p class="" v-if="detail.desc != ''">
                              {{ detail.description }}
                            </p>
                            <p v-if="detail.country != ''">
                              <span class="bold-branding">Country: </span>
                              {{ detail.country || 'Unknown' }}
                            </p>
                            <!-- <p v-if="detail.technology.length > 0">
                              <span class="bold-branding">Technology: </span>
                              {{ detail.technology.join(', ') || 'Unknown' }}
                            </p> -->
                            <p v-if="detail.technology && detail.technology.length > 0">
                              <span class="bold-branding">Technology: </span>
                              {{ detail.technology.join(', ') }}
                            </p>
                            <p v-else>
                              <span class="bold-branding">Technology: </span> Unknown
                            </p>
                            <p v-if="detail.link">
                              <span class="bold-branding">Link to page:</span>
                              <a :href="detail.link" target="_blank" rel="noopener noreferrer">
                                {{ truncateLink(detail.link) }}
                              </a>
                            </p>
                            <p v-else>
                              <span class="bold-branding">Link to page: </span> Unknown
                            </p>
                          </div>
                        </div>
                        <div class="position-absolute bottom-0 right-0">
                          <div class="pin-int-large d-flex">
                            <div class="stats">
                              <!-- <div class="pins">📌 1.1k Pins</div>
                              <div class="reactions">💔😢😲 12.3k</div>
                              <div class="comments">💬 534</div> -->
                              <div class="stat-item pins">
                                <div class="icon">📌</div>
                                <div class="text">{{ detail.repins || '0' }} Pins</div>
                              </div>
                              <hr>
                              <div class="stat-item reactions">
                                <div class="icon">💔😢😲</div>
                                <div class="text">{{ detail.reactions || '0' }}</div>
                              </div>
                              <hr>
                              <div class="stat-item comments">
                                <div class="icon">💬</div>
                                <div class="text">{{ detail.comments || '0' }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-5">
                    <div class="col-md-12">
                      <div class="box p-20">
                        <h2 class="search-heading">Pins Over Time</h2>
                        <highcharts :options="graphData"></highcharts>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footerSection />
  </div>
</template>
<script>
import backgroundUrl from "@/assets/images/banner-image.png";
import logoImage from "@/assets/images/logo.png";
import backArrow from "@/assets/images/back_arrow.svg";
import { Chart } from "highcharts-vue";
import * as yup from "yup";
import headerSection from "./header/header.vue";
import footerSection from "./footer/footer.vue";
import axios from "axios";
import authHeader from "../services/auth-header";
import dayjs from 'dayjs';

export default {
  name: "SearchAd",
  components: {
    highcharts: Chart,
    headerSection,
    footerSection,
  },

  data() {
    return {
      pinTimes: {
        firstSeen: '',
        lastSeen: '',
      },
      graph_dates: [],
      commentsgraph: [],
      savesgraph: [],
      repingraph: [],
      groups: [],
      graph: [],
      page: 10,
      correct: false,
      currentPage: 0,
      perPage: 0,
      total: 0,
      backgroundUrl,
      detail: [],
      //  current:6,
      logoImage,
      backArrow,
      currentId: false,
      chartOptions: {
        chart: {
          type: "spline",
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: "%e. %b",
            year: "%b",
          },
          title: {
            text: "Date",
          },
        },
        series: [],
      },
      password: yup.string().required().min(8),
      confirm_password: yup.string().required().min(8),
    };
  },
  methods: {
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    truncateLink(link) {
      const maxLength = 30; // Set the maximum length for the displayed link text
      return link.length > maxLength ? link.substring(0, maxLength) + '...' : link;
    },
    async forceDownload(url, data) {
      const responseUrl = url;
      this.setCurrentItemId(1);
      try {
        const resp = await fetch(
          process.env.VUE_APP_DOWNLOAD_URL + "download/?url=" + responseUrl
        );
        const res = await resp.json();
        const newUrl = process.env.VUE_APP_DOWNLOAD_URL + res.file;
        fetch(newUrl)
          .then((response) => {
            return response.blob().then((raw) => ({
              contentType: response.headers.get("Content-Type"),
              raw,
            }));
          })
          .then((data) => {
            let blobUrl = window.URL.createObjectURL(data.raw);
            let a = document.createElement("a");
            a.download = res.file;
            a.href = blobUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
            this.setCurrentItemId(null);
          });
      } catch (e) {
        console.log("error", e);
      }
      return;
    },
    onSubmit() {
      this.$router.push({ name: "Login" });
    },
    setCurrentItemId(val) {
      this.currentId = val;
    },
    onResize() {
      if (window.innerWidth > 992 && window.innerWidth <= 1024) {
        this.pieChartOptions.chart.width = 200;
        this.genderChartOptions.chart.width = 200;
        this.countriesChartOptions.chart.width = 200;
        this.pieChartOptions.chart.height = 160;
        this.genderChartOptions.chart.height = 160;
        this.countriesChartOptions.chart.height = 160;
      }
      if (window.innerWidth < 992) {
        this.pieChartOptions.chart.width = 235;
        this.genderChartOptions.chart.width = 235;
        this.countriesChartOptions.chart.width = 235;
        this.pieChartOptions.chart.height = 160;
        this.genderChartOptions.chart.height = 160;
        this.countriesChartOptions.chart.height = 160;
      }
    },

    get_pins() {
      var self = this;
      var arr = [];
      axios
        .get("graph-data/" + this.detail.id, { headers: authHeader() })
        .then((response) => {
          if (response && response.data) {
            this.graph = response.data;
            response.data.repins.forEach((element) => {
              var dt = Date.UTC(element.year, element.month - 1, element.day);

              var rep = [dt, parseInt(element.repins)];
              self.repingraph.push(rep);
            });
            response.data.save.forEach((element) => {
              var dt = Date.UTC(element.year, element.month - 1, element.day);

              var saves = [dt, parseInt(element.saves)];
              self.savesgraph.push(saves);
            });
            response.data.comments.forEach((element) => {
              var dt = Date.UTC(element.year, element.month - 1, element.day);

              var comm = [dt, parseInt(element.comments)];
              self.commentsgraph.push(comm);
            });

            setTimeout(() => {
              self.correct = true;
            }, 1000);
          }
        });
    },
    applyAdvertiserFilter(advertiserName) {
      localStorage.setItem('selectedAdvertiser', advertiserName);
      this.$router.push('/search-ad');
    },
    async fetchPinTimes(pinNumber) {
      try {
        const response = await axios.get(process.env.VUE_APP_FILTER_URL +`pin/${pinNumber}/times`);
        console.log("Here's the response", response);
        if (response.data) {
          this.pinTimes.firstSeen = response.data.timestamp;
          this.pinTimes.lastSeen = response.data.lastSeen;
        } else {
          console.error('Failed to fetch pin times:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching pin times:', error);
      }
    },
  },

  mounted() {
    var self = this;
    this.currentPage = 1;
    this.detail = JSON.parse(localStorage.getItem("singlePost"));
    if (this.detail && this.detail.pinnum) {
      this.fetchPinTimes(this.detail.pinnum);
    }
    console.log("Complete Data", this.detail);
    this.detail.groups.forEach((el) => {
      self.groups.push(el);
    });
    console.log(this.detail, "Details here:>>>>>")
    window.addEventListener("resize", this.onResize);
    this.onResize();
    setTimeout(() => {
      self.get_pins();
    }, 500);

    let videoTagsInterval = setInterval(() => {
      const videoElements = document.getElementsByTagName('video');

      videoElements.forEach(element => {
        element.setAttribute('preload', 'auto');
      });

      if (videoElements.length > 0) {
        clearInterval(videoTagsInterval);
      }
    }, 100);
  },
  computed: {
    graphData: function () {
      const chartOptions = {
        chart: {
          type: "spline",
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: "%e. %b",
            year: "%b",
          },
          title: {
            text: "Date",
          },
        },
        series: [
          {
            name: "pins",
            data: this.repingraph,
          },
          {
            name: "saves",
            data: this.savesgraph,
          },
          {
            name: "comments",
            data: this.commentsgraph,
          },
        ],
      };

      return chartOptions;
    },
    formattedCreatedAt() {
      return this.detail.createdAt ? dayjs(this.detail.createdAt).format('MMM D, YYYY') : '';
    },
    formattedFirstSeen() {
      return this.pinTimes.firstSeen ? dayjs(this.pinTimes.firstSeen).format('MMM D, YYYY') : 'N/A';
    },
    formattedLastSeen() {
      return this.pinTimes.lastSeen ? dayjs(this.pinTimes.lastSeen).format('MMM D, YYYY') : 'N/A';
    },
  },
};
</script>
<style scoped>
.info-section {
  width: 300px;
}

/* .info-section h1 {
  font-size: 20px;
  margin: 0;
} */

.info-section p {
  margin: 5px 0;
}

/* .advertiser {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.advertiser img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.advertiser span {
  font-weight: bold;
  margin-right: 5px;
} */
.advertiser-section {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

.avatar {
  flex-shrink: 0;
  margin-right: 20px;
}

.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.advertiser-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.followers {
  color: #777;
  margin: 0;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
}

.stats div {
  display: flex;
  align-items: center;
}

.stats .pins,
.stats .reactions,
.stats .comments {
  font-size: 14px;
  margin: 10px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.stat-item .icon {
  font-size: 24px;
}

.stat-item .text {
  font-size: 14px;
  margin-top: 5px;
}

.img-section {
  @media (min-width: 992px) {
    height: 625px;
  }
}

.img-section .main-content-block {
  padding-right: 124px;
}

.hero-card-img-css,
.hero-card-video-css,
.hero-card-video-css video {
  height: 100%;
}

.hero-card-img-css img {
  height: 100%;
  object-fit: cover;
}

.custom-pl-15 {
  padding-left: 15px !important;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.info-section-height {
  @media (max-width: 992px) {
    min-height: 310px;
  }
}

.main-content {
  height: 625px;
  overflow: scroll;
}
</style>
