<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import './assets/css/style.css';
@import './assets/css/responsive.css';
@import './assets/css/bootstrap.min.css';
</style>
