import axios from 'axios';
const httpOptions = {
    headers:{
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
}

class AuthService {
  login(user) {
    return axios
      .post('user-login', {
        username: user.username,
        password: user.password
      }, httpOptions)
      .then(response => {
        if (response.status==true) {
          localStorage.clear();
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem('jwtToken', response.data.jwtToken);
          if(response.data.subscription==1){
            localStorage.setItem('subscription',1);
          }
        }
        return response;
      })
  }

  register(user) {

    return axios
      .post('register', {email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        company: user.company,
        password: user.password,
        confirm_password: user.confirm_password}, httpOptions)
      .then(response => {
        if (response.status==true) {
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem('jwtToken', response.data.jwtToken);
          localStorage.setItem('popup-login','')
          localStorage.setItem('new-login','yes')
          if(response.data.subscription==1){
            localStorage.setItem('subscription',1);
          }
        }
        return response;
      })
  }

  logout() {
    localStorage.clear();
  }

  forgotpassword(data) {
    return axios
      .post('forgot-password', {
        email: data.email,
      }, httpOptions)
      .then(response => {
        return response;
      })
  }

  resetpassword(data) {
    return axios
      .post('reset-password', {
        token: data.token,
        password: data.password,
        confirm_password: data.confirm_password,
      }, httpOptions)
      .then(response => {
        return response;
      })
  }

}

export default new AuthService();