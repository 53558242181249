<template>
<div>
    <div class="pinspy-load" v-if = "loading"><span class="load"></span></div>
    <!-- header  -->
    <headerSection />
    <section class="help-wrap">
         <div class="container">
            <div class="row">
               <div class=" col-12 mb-5">
                  <h1 class="help-header text-center">Help Center</h1>
               </div>
               <div class="col-12 col-xl-10 m-auto help-form login-form">
                  <div class="card help-card">
                     <div class="card-body">
                        <div class="row ">
                           <div class="col-12 col-md-2 align-self-center">
                              <!-- <router-link :to="{'name': 'home'}" class="back m-0 back-img"><img :src="require('@/assets/images/back-arrow.svg')"/> Back </router-link> -->
                           </div>
                           <div class="col-sm-12 text-sm-center col-md-8 align-self-center">
                              <h2 class="help-status">Send us a message</h2>
                           </div>
                           <div class="col-sm-3 col-md-2 align-self-center">
                           </div>
                        </div>
                        <hr class="help-border">
                        <div class="col-12 my-3">
                        <div class="alert alert-danger" v-if="showError">{{errorMsg}}</div>
                    <div class="alert alert-success" v-if="showSuccess">Request submitted successfully</div>
                    </div>
                        <Form @submit="handleHelp" enctype="multipart/form-data" class="row mt-5" id="form_id">

                    
                           <div class="col-md-6 mb-2">
                              <div class="form-group" >
                                 <label for="exampleInputEmail1">Name</label>
                                 <Field type="text" class="form-control shadow-none error-color-inpt"  name="name"  id="exampleInputEmail1"  :rules="name" required  aria-describedby="emailHelp" placeholder="Full name"/>
                                 <ErrorMessage name="name" />
                              </div>
                           </div>
                           <div class="col-md-6 mb-2">
                              <div class="form-group" >
                                 <label for="exampleInputEmail1">Email</label>
                                 <Field type="email" class="form-control shadow-none error-color-inpt"  name="email"  id="exampleInputEmail1" :rules="email" required  aria-describedby="emailHelp" placeholder="E-mail address"/>
                                 <ErrorMessage name="email" />
                              </div>

                           </div>
                           <div class="col-md-12 mb-3">
                              <div class="form-group " >
                                 <label for="exampleInputEmail1">Message</label>
                                 <Field as="textarea" placeholder="Add text..."  name="message"  style="width: 100%" class="form-control shadow-none error-color-inpt" :rules="message" required   rows="8"/>
                                 <p class="textarea-error">
                                 <ErrorMessage name="message"/>
                                 </p>
                              </div>
                           </div>
                           <div class="col-12">
                               <input type="file" hidden name="attachment" id="attachments" @change="selectFile"/>
                           <button type="submit" class="theme-btn button-red mr-2">Submit</button>
                                 <button type="button" class="theme-btn  btn-attach mr-4" @click = "selectfile"><img :src="require('@/assets/images/attach.svg')"/> Attach file</button>
                                 <span v-if="!attachment">No files selected</span>
                                 <span  v-else><i class="fa fa-upload" aria-hidden="true"></i>
 {{attachment.name}}
                                 </span>
                           </div>
                           
                        </Form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   
      <footerSection />
  </div>
</template>

<script>
import * as yup from 'yup';
import moment from 'moment';
import headerSection from "./header/header.vue";
import footerSection from "./footer/footer.vue";
import axios from 'axios';
import { Form, Field, ErrorMessage  } from 'vee-validate';

import authHeader from '../services/auth-header';
export default {
  components: {
        headerSection,
        footerSection,
        Form,
        Field,
        ErrorMessage,
  },
  data() {
    return {
        name: yup.string().required(),
        email: yup.string().email().required(),
        message: yup.string().required(),
        showError: false,
        showSuccess: false,
        errorMsg: '',
        loading: false,
        result: "",
        attachment: '' 
    }
  },
  computed: {
   
  },
  mounted(){
   this.$gtag.pageview('/helpcenter');
  },
  created() {
     localStorage.removeItem("filters");
  },
  methods: {
        selectfile(){
            document.getElementById('attachments').click();
        },
        selectFile(event) {
            // `files` is always an array because the file input may be in multiple mode
            this.attachment = event.target.files[0];
        },

        handleHelp(data) {
            this.loading = true;
            var self = this
            const formData = new FormData();
            if(this.attachment!=''){
                formData.append("attachment", this.attachment);
            }
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("message", data.message);
            axios.post('help-center', formData, { headers: authHeader() }).then(
            (response) => {
                if(response.status==true){
                    this.showError = false;
                    this.loading = false;
                    this.errorMsg = '';
                    this.showSuccess = true;
                    this.result = response.message; 
                    document.getElementById("form_id").reset();
                    setTimeout(function(){ self.showSuccess=false; }, 3000);
                }else{
                    this.loading = false;
                    this.showSuccess = false;
                    this.showError = true;
                    this.errorMsg = response.message; 
                    this.result = ''
                }
            });
        },
        
  }
}
</script>

<style scoped>

</style>
