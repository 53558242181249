<template>
   <html>
      <body>
         <section class="sticky-header" id="dynamic">
            <div class="navigation-wrap bg-light-1 start-header ">
               <div class="container-fluid">
                  <div class="row">
                     <div class="col-12 offset-0 col-lg-10 offset-lg-1">
                        <div class="row wider-navbar align-items-center">
                           <!-- <div class="col-xl-2 col-lg-2 col-md-2 col-6  d-none d-md-block">
                              <div class="site-logo site-top-logo">
                                <div class="logo-icon">
                                  <router-link :to="{'name': 'home'}" style="border:none;">
                                    <img :src="require('@/assets/images/PinSpy-logi.svg')"
                                  /></router-link>
                                </div>
                              </div>
                              </div> -->
                           <div class=" col-12">
                              <nav class="navbar navbar-expand-md navbar-light px-0">
                                 <router-link :to="{'name': 'home'}" style="border:none;">
                                    <img :src="require('@/assets/images/logo-white.svg')" class="logo-white" />
                                 </router-link>
                                 <button
                                    class="navbar-toggler bg-transparent border-0 ml-auto"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    >
                                 <span class="navbar-toggler-icon"></span>
                                 </button>
                                 <div
                                    class="collapse navbar-collapse"
                                    id="navbarSupportedContent"
                                    >
                                    <ul class="navbar-nav top-menu">
                                       <li class="nav-item" v-if="!loggedIn">
                                          <router-link class="nav-link" :to="{'name': 'Login'}">Login</router-link>
                                       </li>
                                       <li class="nav-item" v-else-if="loggedIn">
                                          <router-link class="nav-link" :to="{'name': 'Login'}" @click.prevent="logout()">Logout</router-link>
                                       </li>
                                       <li class="nav-item logout-btn">
                                          <router-link class="nav-link mx-0 ml-3 button-white btn-large theme-btn" :to="{'name': 'register'}">Get started</router-link>
                                       </li>
                                    </ul>
                                 </div>
                              </nav>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section
            class="banner-img-section-ss-ipad">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="heading-homepage">
                        <h1 class="heading-text">welcome to pinspy</h1>
                        <p class="description-home">
                           Find top performing pins, spy on competitors and take your
                           business to the next level thank to the ultimate Pinterest spy
                           tool!
                        </p>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12 get-started-btn">
                     <div class="btn-after-content-home">
                        <router-link class="button-white btn-large theme-btn" :to="{'name': 'register'}">Get started</router-link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="ipad-section pb-0">
            <div class="container">
               <div class="row">
                  <div class="col-md-12 img-ss">
                     <div class="ipad-screen-ss">
                        <img :src="require('@/assets/home/images/ipad-ss-1.png')" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <!-- <section class="theme-border-bottom pb-5">
            <div class="container">
               <div class="row counter pt-0">
                  <div class="col-10 offset-1">
                     <div class="row">
                        <div class="col-md-4 col-12">
                           <div class="count-popular">
                              <p class="im-millions mb-0">100M+</p>
                              <p class="ads-total">Ads</p>
                           </div>
                        </div>
                        <div class="col-md-4 col-12">
                           <div class="count-popular">
                              <p class="im-millions  mb-0">198</p>
                              <p class="ads-total">Countries</p>
                           </div>
                        </div>
                        <div class="col-md-4 col-12">
                           <div class="count-popular">
                              <p class="im-millions  mb-0">078M+</p>
                              <p class="ads-total">Advertisers</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> -->
         <section class="what-pinspy-area position-relative" style="">
            <img  :src="require('@/assets/images/shape-half-circle.svg')" class="area-right" />
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="descr-what-pinspy">
                        <h2 class="heading-what-pinspy">
                           What <span>pinspy</span> can do for you
                        </h2>
                        <p class="pinspy-research">
                           Researching your competitors and finding new top performing
                           pins or products can take hours. This unique tool helps
                           collect all the comprehensive data and let’s access this
                           within seconds. Exhaustive search and filter features will
                           uncover the ads that you and your company need to know about,
                           and the simple interface will help you discover them with
                           ease.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="what-pinspy-area position-relative " style="">
            <img  :src="require('@/assets/images/shape-triangle.svg')" class="area-left" />
            <div class="container">
               <!--  -->
               <div class="row after-what-pinspy ">
                  <div class="col-md-4 mb-4">
                     <div class="descover-area">
                        <img :src="require('@/assets/home/images/tick.png')" />
                        <h3 class="monitor">
                           Discover new pins and products for your business
                        </h3>
                     </div>
                  </div>
                  <div class="col-md-4 mb-4">
                     <div class="descover-area">
                        <img :src="require('@/assets/home/images/tick.png')" />
                        <h3 class="monitor">
                           Monitor competitors and stay up-to-date in your market
                        </h3>
                     </div>
                  </div>
                  <div class="col-md-4 mb-4">
                     <div class="descover-area">
                        <img :src="require('@/assets/home/images/tick.png')" />
                        <h3 class="monitor">
                           Save a ton of money on testing bad campaigns
                        </h3>
                     </div>
                  </div>
               </div>
               <div class="row after-what-pinspy for-border-line">
                  <div class="col-md-4 mb-4">
                     <div class="descover-area">
                        <img :src="require('@/assets/home/images/tick.png')" />
                        <h3 class="monitor">Specify on what market you want to see</h3>
                     </div>
                  </div>
                  <div class="col-md-4 mb-4">
                     <div class="descover-area">
                        <img :src="require('@/assets/home/images/tick.png')" />
                        <h3 class="monitor">
                           Uncover new winning products and strategies
                        </h3>
                     </div>
                  </div>
                  <div class="col-md-4 mb-4">
                     <div class="descover-area">
                        <img :src="require('@/assets/home/images/tick.png')" />
                        <h3 class="monitor">
                           Save searches and filter what works for you
                        </h3>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12 bottom-line-section">
                     <div class="line-cls">
                        <hr class="bottom-line" />
                     </div>
                  </div>
               </div>
               <!--  -->
            </div>
         </section>
         <section class="more-than-simple-spy">
            <div class="container tool-for-spacing">
               <div class="row more-than-spacing">
                  <div class="col-lg-6">
                     <div class="spy-tool">
                        <h3 class="more-than-text massive">
                           More than a simple <span>spy tool</span>
                        </h3>
                        <p class="more-than-text-desc">
                           Pin Spy offers the most search options of any Pinterest spy
                           tool, so you can find the perfect data for your business.
                           Search for keywords, demographics, affiliate platforms,
                           landing page websites and so on. Everything you need to find
                           the information that will help you.
                        </p>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="spy-toll-image">
                        <img :src="require('@/assets/home/images/spy-tool.png')" />
                     </div>
                  </div>
               </div>
               <div class="row more-than-spacing">
                  <div class="col-lg-6">
                     <div class="work-smart-image">
                        <img :src="require('@/assets/home/images/work-smart.png')" />
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="spy-tool">
                        <h3 class="more-than-text massive">
                           <span>work smart,</span> not hard
                        </h3>
                        <p class="more-than-text-desc">
                           All the information you need in one place. Pin Spy is the only
                           tool that gives you instant access to data you need to be
                           successful on this undiscovered advertising platform. Don’t
                           spend hours searching and testing what works, simply copy and
                           improve campaign of other successful brands.
                        </p>
                     </div>
                  </div>
               </div>
               <div class="row more-than-spacing">
                  <div class="col-lg-6">
                     <div class="spy-tool">
                        <h3 class="more-than-text massive">
                           <span>massive</span> data
                        </h3>
                        <p class="more-than-text-desc">
                           Move mountains: our comprehensive spy tool gives you access to
                           a ginormous amount of data. Where other tools are trying to
                           save money by not showing you everything you want to see, Pin
                           Spy gives you access to all the juicy data in our quick and
                           intelligent interface.
                        </p>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="spy-toll-image">
                        <img :src="require('@/assets/home/images/massive-data.png')" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="pinspy-features-area">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="descr-what-pinspy">
                        <h2 class="heading-what-pinspy">
                           <span>pinspy</span> Features
                        </h2>
                        <p class="pinspy-research">
                           We’ve created the most comprehensively featured Advertising
                           Intelligence Tool on the market. We push ourselves to always
                           stay ahead of the game in collecting data and implementing
                           unique features.
                        </p>
                     </div>
                  </div>
               </div>
               <div class="row features">
                  <div class="col-md-6 col-12 col-lg-4 mt-4 mt-sm-5">
                     <div class="features-all">
                        <img :src="require('@/assets/home/images/basic-search.png')" />
                        <h3 class="basic-search-heading">BASIC SEARCH</h3>
                        <p class="basic-search-discription">
                           All the features you need, executed to perfection! The
                           multiple search option gives you all the insights you are
                           looking for. Whether you want to search by ad text or by
                           popularity; just use the clever filters to get your data
                           right! Pin Spy is as powerful as you want to make it.
                        </p>
                     </div>
                  </div>
                  <div class="col-md-6 col-12 col-lg-4 mt-4 mt-sm-5">
                     <div class="features-all">
                        <img :src="require('@/assets/home/images/search-comment.png')" />
                        <h3 class="basic-search-heading">SEARCH THROUGH ADCOPY</h3>
                        <p class="basic-search-discription">
                           Get to know your customers’ needs! Using our adcopy search, you can easily see which words resonate well with your target audience. Search for ads with the keywords you think are important: monitor competitors’ brands, your own brands, or see what’s had positive or negative feedback.
                        </p>
                     </div>
                  </div>
                  <div class="col-md-6 col-12 col-lg-4 mt-4 mt-sm-5">
                     <div class="features-all">
                        <img :src="require('@/assets/home/images/accurate.png')" />
                        <h3 class="basic-search-heading">ACCURATE DEMOGRAPHICS</h3>
                        <p class="basic-search-discription">
                           Accurate data is important in order to find your audience! Pin
                           Spy can assess who an ad is trying to target based on their
                           location, gender, and age range. Your competitors may be
                           dominating in regions and across markets you haven’t yet
                           considered: Pin Spy will open up all this data for you!
                        </p>
                     </div>
                  </div>
                  <div class="col-md-6 col-12 col-lg-4 mt-4 mt-sm-5">
                     <div class="features-all">
                        <img :src="require('@/assets/home/images/data.png')" />
                        <h3 class="basic-search-heading">BIG DATA</h3>
                        <p class="basic-search-discription">
                           Pin Spy simply has the largest pool of data available to you.
                           If a hot product is kicking off and dropshippers need to know,
                           you can be sure that Pin Spy knows already.
                        </p>
                     </div>
                  </div>
                  <div class="col-md-6 col-12 col-lg-4 mt-4 mt-sm-5">
                     <div class="features-all">
                        <img :src="require('@/assets/home/images/calling.png')" />
                        <h3 class="basic-search-heading">CALLING AFFILIATES</h3>
                        <p class="basic-search-discription comming-soon">
                           Comming Soon.
                        </p>
                     </div>
                  </div>
                  <div class="col-md-6 col-12 col-lg-4 mt-4 mt-sm-5">
                     <div class="features-all">
                        <img :src="require('@/assets/home/images/basic-search.png')" />
                        <h3 class="basic-search-heading">RAPID INTERFACE</h3>
                        <p class="basic-search-discription">
                           Time is money! To get all the data as quick as possible to
                           your feed we use fast servers, efficient programming, clear
                           interfaces, and well presented data to ensure that nothing
                           slows down your search!
                        </p>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12 bottom-line-section">
                     <div class="line-cls">
                        <hr class="bottom-line" />
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12">
                     <div class="descr-what-pinspy pt-3">
                        <h2 class="heading-what-pinspy">
                           Watch Video Of <span>pinspy</span>
                        </h2>
                        <p class="pinspy-research">
                           Check out our demo of what features we offer and how you can
                           get the most out of the software.
                        </p>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12 btn-start">
                     <div class="video-img">
                        <!-- <img :src="require('@/assets/home/images/video-img.png')" /> -->
                        <iframe class="responsive-iframe" src="https://player.vimeo.com/video/691964158?h=6cb548c92a"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                     </div>
                     <div class="button-started">
                        <!-- <a class="get-started-button white-button" href=""
                           >get started</a
                           > -->
                        <router-link class="button-red btn-large theme-btn" :to="{'name': 'register'}">Get started</router-link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="simple-fair-pricing pb-md-4 pb-lg-5">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="descr-what-pinspy">
                        <h2 class="heading-what-pinspy">
                           <span>simple</span> and <span>fair</span> Pricing
                        </h2>
                        <p class="pinspy-research">
                           We offer more search features and more data than all our rivals... Not only that, but we're cheaper too! We offer virtually unlimited usage for just $49 per month. Be quick though, this is an introductory offer and will not be the same for long!
                        </p>
                     </div>
                     <div class="button-started">
                        <router-link class="button-red btn-large theme-btn" :to="{'name': 'register'}">Get started</router-link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section class="save-time">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="saving-area">
                        <h2 class="save-time-save-money">
                           Save <span>time</span> Save <span>money</span>
                        </h2>
                        <p class="save-time-save-money-desc">
                           Why waste thousands of dollars testing ads blindly? Find
                           proven ads, innovate, profit.
                        </p>
                     </div>
                     <div class="button-started-white">
                        <router-link class="theme-btn button-white-outline btn-large" :to="{'name': 'register'}">Start Now</router-link>
                        <!-- <a class="get-started-buttons start-now-btn" href=""
                           >Start Now</a
                           > -->
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <footerSection />
      </body>
   </html>
</template>
<script>
   import axios from 'axios';
   import authHeader from '../../services/auth-header';
   import Swal from 'sweetalert2'
   import footerSection from "../footer/footer.vue";

   export default {
   components: {
           footerSection
     },
     mounted() {
       let externalScript = document.createElement('script')
       externalScript.setAttribute('src', 'assets/js/script.js')
       document.head.appendChild(externalScript)

     },
     created() {
       if (this.loggedIn) {
         if(this.subscription ==1 ){
             this.$router.push("/search-ad");
         }else{
             this.$router.push("/subscription");
         }
       }else{
         this.$router.push("/");
       }
         this.get_status();
     },

     computed: {
       loggedIn() {
         return this.$store.state.auth.initialState.status.loggedIn;
       },
       subscription() {
         return this.$store.state.auth.subscription;
       },
     },


     methods: {
         moveUp(){
              window.scrollTo(0,0);
         },
         get_status(data) {
           var self = this;
           var sub = localStorage.getItem('subscription');
           if(sub!=1){
             const urlSearchParams = new URLSearchParams(window.location.search);
             const params = Object.fromEntries(urlSearchParams.entries());
             if(params && params.order_id!=undefined){
               axios.get('get-status?order_id='+params.order_id, { headers: authHeader() }).then(
                 (response) => {
                   if(response && response.data && response.data.status =='paid'){
                       localStorage.setItem('subscription', 1);
                       this.loading = true;
                       self.$store.commit('updateSubscriptionStatus', 1);
                       this.successalert()
                   }else if(response && response.data && response.data.status!='paid'){
                       this.erroralert()
                   }
                 }
               );
             }
           }
         },
         logout() {
           this.$store.dispatch('auth/logout').then(() => this.$router.push("/login"));

         },
         erroralert(){
           Swal.fire({
                 title: 'OPPS',
                 text:   "Payment is not completed.",
                 icon: 'error',
             });
         },
         successalert(){
           var self = this;
           Swal.fire({
                 title: 'Success',
                 text:   "Payment successfully completed.",
                 icon: 'success',
             }).then(function() {
                 self.$router.push("/search-ad");
             });
         }

     }

   }
</script>
