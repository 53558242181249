<template>
  <div class="pinspy-load" v-if="loading"><span class="load"></span></div>
  <div>
    <headerSection />
    <section class="banner-img-section" :style="{ backgroundImage: `url(${backgroundUrl})` }">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="heading-text pb-4 mt-3 mt-sm-5">
              <h1 class="mb-3 mb-sm-5">Search for Ads</h1>
            </div>
            <form class="expanding-search-form">
              <div class="search-dropdown">
                <div class="comments-wrapper">
                  <label @click.stop="openCommets" class="trigger">{{
                    currentComments
                  }}</label>
                </div>
              </div>
              <div class="search-icon position-relative">
                <img class="fa-search" :src="require('@/assets/images/Search.svg')" />
                <input class="search-input" id="global-search" type="search" v-model="search"
                  placeholder="  Search ad.." @input="filterAdvertisers" />
                <!-- New code for search dropdown -->
                <div v-if="filteredAdvertisers.length > 0" class="dropdown">
                  <ul>
                    <li v-for="advertiser in filteredAdvertisers" :key="advertiser.creatorName"
                      @click="selectAdvertiser(advertiser)">
                      <img :src="advertiser.creatorAvatarUrl" alt="Avatar" class="avatar" />
                      {{ advertiser.creatorName }} ({{ advertiser.followerCount }})
                    </li>
                  </ul>
                </div>
                <!-- New code for search dropdown -->
              </div>
            </form>
            <div class="text-white text-center" v-if="showsearchError && search == ''">
              {{ errorsearchMsg }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="btns-after-searchbar">
              <button class="btn-clear mr-2" v-if="searchArray.length > 0 ||
                gender ||
                currentadvertiser ||
                currentmedia ||
                currentTechnology ||
                selectedCountries.length > 0 ||
                currentLanguage ||
                age.min != 20 ||
                age.max != 60 ||
                repins.min > 0 ||
                repins.max < 10000000 ||
                (created_between &&
                  created_between.from != '' &&
                  created_between.to != '') ||
                search != '' ||
                selectedCategories.length > 0
              " @click="clear_all" style="background: white">
                Clear All
              </button>
              <button class="btn-clear mr-2" v-else>Clear All</button>
              <button class="btn-preview mr-2" v-if="search != ''" @click="addToFilter">
                Add to Filter
              </button>
              <button class="btn-preview mr-2" disabled v-else>
                Add to Filter
              </button>
              <button class="btn-preview" v-if="search != ''" @click="preview_filter">
                Preview
              </button>
              <button class="btn-preview" disabled v-else>Preview</button>
            </div>
          </div>
        </div>
        <div class="search-form">
          <form action="" method="GET" name="search" role="search">
            <div class="row for-align-form">
              <div class="col-lg-4">
                <div class="inp-wrap for-select-color gender-popup-wrapper position-relative">
                  <label for="categories" class="grid-20">Gender</label>
                  <div class="dropdown-input-wrapper">
                    <input type="text" placeholder="Select Gender" readonly name="categories" v-model="gender"
                      @click.stop="openGender" id="categories" class="grid-80 dropdown-input" />
                    <img :src="require('@/assets/images/Left.svg')" class="down-arrow-search" />
                    <ul class="gender-list dropdown-list h-auto" v-click-outside="hideGenderPopup" v-show="genderPopup">
                      <li class="list-item">
                        <div class="radio">
                          <input type="radio" v-model="gender" name="gender" value="Male" id="male" />
                          <label for="male" class="radio-label">Male</label>
                        </div>
                      </li>
                      <li class="list-item">
                        <div class="radio">
                          <input type="radio" v-model="gender" name="gender" value="Female" id="female" />
                          <label for="female" class="radio-label">Female</label>
                        </div>
                      </li>
                      <li class="list-item">
                        <div class="radio">
                          <input type="radio" v-model="gender" name="gender" value="Other" id="other" />
                          <label for="other" class="radio-label">Other</label>
                        </div>
                      </li>
                      <li class="list-footer">
                        <a href="javascript:void(0)" class="save-btn" @click="filterdata('gender')">OK</a>
                        <a href="javascript:void(0)" class="reset-btn" @click="reset_value('gender')">Reset</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="col-lg-4 theme-dialog">
                <q-dialog v-model="alert">
                  <q-card>
                    <q-card-section>
                      <div class="text-h6">Age Range</div>
                      {{ age.min }} to {{ age.max }}
                    </q-card-section>
                    <q-card-section class="q-pt-none">
                      <div class="q-pa-md">
                        <q-badge color="secondary">
                          Age: {{ age.min }} to {{ age.max }}
                        </q-badge>
                        <q-range v-model="age" :min="20" :max="60" :age="5" label color="deep-orange"
                          @change="changeAge" />
                      </div>
                    </q-card-section>
                    <q-card-actions align="right">
                      <q-btn flat label="OK" color="primary" class="btn-filter" @click="filterdata('age')"
                        v-close-popup />
                    </q-card-actions>
                  </q-card>
                </q-dialog>
                <q-dialog v-model="likealert">
                  <q-card>
                    <q-card-section>
                      <div class="text-h6">Repins</div>
                    </q-card-section>
                    <q-card-section class="q-pt-none">
                      <div class="q-pa-md">
                        <div class="d-flex">
                          <span class="mr-3 font-weight-500 align-self-center">From</span>
                          <input type="number" v-model="repins.min" @change="setSteps"
                            class="country-search-input grid-80 form-control" min="0" @input="handleInput"
                            @keydown="checkDigitOnly($event)" />
                          <span class="mx-3 font-weight-500 align-self-center">To</span>
                          <input @change="setSteps" type="number" v-model="repins.max"
                            class="country-search-input grid-80 form-control" min="0" @input="handleInput"
                            @keydown="checkDigitOnly($event)" />
                          <!-- <q-badge color="secondary">
                                        From: {{ repins.min }} to {{ repins.max }}
                                     </q-badge> -->
                        </div>
                        <div>
                          <q-range v-model="repins" :min="repinMin" :max="repinMax" :repins="5" label :step="customStep"
                            color="deep-orange" @change="filterdata('repins')" />
                        </div>
                      </div>
                    </q-card-section>
                    <q-card-actions align="right">
                      <q-btn flat label="OK" color="primary" class="btn-filter" @click="filterdata('repins')"
                        v-close-popup />
                    </q-card-actions>
                  </q-card>
                </q-dialog>
                <div @click="alert = true"
                  class="inp-wrap for-select-color gender-popup-wrapper position-relative age-input">
                  <label for="age" class="grid-20">Age</label>
                  <div class="q-mb-sm">
                    <q-badge color="" v-if="isAgeSelected">
                      {{ age.min }} to {{ age.max }}
                    </q-badge>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="col-lg-4" @click="likealert = true">
                <div class="inp-wrap for-select-color gender-popup-wrapper position-relative age-input">
                  <label for="dailyLikes" class="grid-20">Repins</label>
                  <div class="q-mb-sm">
                    <q-badge color="" v-if="repins.min > 0 && repins.max < 10000000">
                      {{ repins.min }} to {{ repins.max }}
                    </q-badge>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
            <div class="row for-align-form">
              <div class="col-lg-4">
                <div class="inp-wrap cat-wrap">
                  <label for="media" class="grid-20">Media type</label>
                  <div class="dropdown-input-wrapper">
                    <input type="text" placeholder="Select media" v-model="currentmedia" readonly name="media"
                      @click.stop="openmedia" id="media" class="country-search-input grid-80 dropdown-input" />
                    <img :src="require('@/assets/images/Left.svg')" class="down-arrow-search" />
                    <ul class="media-list dropdown-list h-auto" v-click-outside="hidemediaPopup" v-show="mediaPopup">
                      <li class="list-search"></li>
                      <li class="list-item" v-for="media in mediaFilter" :key="media.name">
                        <label :for="media.name" @click="changemedia($event, media.name)" class="country-name">{{
                          media.name }}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="inp-wrap cat-wrap">
                  <label for="technology" class="grid-20">Technologies</label>
                  <div class="dropdown-input-wrapper">
                    <input type="text" placeholder="Select technology" v-model="currentTechnology" readonly
                      name="categories" @click.stop="openTechPopup" id="categories"
                      class="country-search-input grid-80 dropdown-input" />
                    <img :src="require('@/assets/images/Left.svg')" class="down-arrow-search" />
                    <ul class="technology-list dropdown-list" v-click-outside="hideTechPopup" v-show="technologyPopup">
                      <li class="list-search"></li>
                      <li class="list-item" v-for="tech in technologiesFilter" :key="tech.name">
                        <label :for="tech.name" @click="changetechnology($event, tech.name)" class="country-name">{{
                          tech.name }}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="inp-wrap cat-wrap">
                  <label for="countries" class="grid-20">Countries</label>
                  <div class="dropdown-input-wrapper">
                    <input type="text" :placeholder="countryPlaceholder" readonly name="categories"
                      @click.stop="openCountry" id="categories" class="country-search-input grid-80 dropdown-input" />
                    <img :src="require('@/assets/images/Left.svg')" class="down-arrow-search" />
                    <ul class="country-list dropdown-list" v-click-outside="hideCountryPopup" v-show="countriesPopup">
                      <li class="list-search"></li>
                      <li class="list-item" v-for="(country, index) in countryFilter" :key="country.name">
                        <label class="country-checkbox">
                          <input type="checkbox" v-model="selectedCountries" name="country" :value="country.name"
                            :id="`country-${index}`" />
                          <span class="checkmark"></span>
                        </label>
                        <label :for="`country-${index}`" class="country-name">{{
                          country.name
                        }}</label>
                      </li>
                      <li class="list-footer">
                        <a href="javascript:void(0)" class="save-btn" @click="openCountry">OK</a>
                        <a href="javascript:void(0)" class="reset-btn" @click="reset_value('country')">Reset</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row for-align-form">
              <div class="col-lg-4">
                <div class="inp-wrap cat-wrap">
                  <label for="language" class="grid-20">Language</label>
                  <div class="dropdown-input-wrapper">
                    <input type="text" placeholder="Select language" v-model="currentLanguage" readonly
                      name="categories" @click.stop="openLanguage" id="categories"
                      class="country-search-input grid-80 dropdown-input" />
                    <img :src="require('@/assets/images/Left.svg')" class="down-arrow-search" />
                    <ul class="language-list dropdown-list" v-click-outside="hideLanguagePopup" v-show="languagePopup">
                      <li class="list-search"></li>
                      <li class="list-item" v-for="language in languageFilter" :key="language.name">
                        <label :for="language.name" @click="changeLanguage($event, language.name)"
                          class="country-name">{{
                            language.name }}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 theme-date-picker">
                <div class="cat-wrap">
                  <div
                    class="inp-wrap calender-event for-select-color gender-popup-wrapper position-relative age-input">
                    <label for="age" class="grid-20">Created between</label>
                    <div class="q-date-fixes">
                      <div class="q-mb-sm">
                        <q-badge color="" v-if="created_between &&
                          created_between.from != '' &&
                          created_between.to != ''
                        ">
                          {{ created_between.from }} to {{ created_between.to }}
                        </q-badge>
                      </div>
                      <q-btn icon="event">
                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                          <q-date v-model="created_between" class="custom_calander_css" range mask="YYYY-MM-DD">
                            <q-btn class="btn-ok" label="OK" color="primary" flat @click="filterdata('created_between')"
                              v-close-popup hidden />
                            <div class="d-flex items-center justify-end q-gutter-sm">
                              <q-btn class="btn-cancel" id="close_date_popup_button" label="Close" color="primary" flat
                                v-close-popup />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="inp-wrap cat-wrap">
                  <label for="category" class="grid-20">Category</label>
                  <div class="dropdown-input-wrapper">
                    <input type="text" :placeholder="categoryPlaceholder" v-model="currentCategory" readonly
                      name="categories" @click.stop="openCategory" id="categories"
                      class="country-search-input grid-80 dropdown-input" />
                    <img :src="require('@/assets/images/Left.svg')" class="down-arrow-search" />
                    <ul class="country-list dropdown-list" v-click-outside="hideCategoryPopup" v-show="categoryPopup">
                      <li class="list-search">
                        <div class="input-container">
                          <img :src="require('@/assets/images/search-grey.svg')" class="list-search-icon" />
                          <input placeholder="Search..." type="text" v-model="categorySearch" />
                        </div>
                      </li>
                      <li class="list-item" v-for="(cat, index) in categoryFilter" :key="cat.name">
                        <label class="country-checkbox">
                          <input type="checkbox" v-model="selectedCategories" name="country" :value="cat.name"
                            :id="`cat-${index}`" />
                          <span class="checkmark"></span>
                        </label>
                        <label :for="`cat-${index}`" class="country-name">{{
                          cat.name
                        }}</label>
                      </li>
                      <li class="list-footer">
                        <a href="javascript:void(0)" class="save-btn" @click="openCategory">OK</a>
                        <a href="javascript:void(0)" class="reset-btn" @click="reset_value('category')">Reset</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div id="selected_values" class="mt-2">
            <span class="tags_value" v-for="(item, index) in searchArray" :key="item.id" :value="item.id">
              <span class="alert alert-label mr-2" role="alert" v-if="item.technologies">
                {{ item.technologies }}
                <button type="button">
                  <span @click="reset_value('technologies')">×</span>
                </button>
              </span>
              <span class="alert alert-label mr-2" role="alert" v-if="item.language">
                {{ item.language }}
                <button type="button">
                  <span @click="reset_value('language')">×</span>
                </button>
              </span>
              <span v-if="item.country">
                <span class="alert alert-label mr-2" role="alert" v-if="item.country.length > 0">
                  <span v-for="(con, index) in item.country" :key="con.id" :value="con.id">{{ con
                    }}<span v-if="index != Object.keys(item.country).length - 1">
                      ,</span></span>
                  <button type="button">
                    <span @click="reset_value('country')">×</span>
                  </button>
                </span>
              </span>
              <span class="alert alert-label mr-2" role="alert" v-if="item.advertise">
                {{ item.advertise }}
                <button type="button">
                  <span @click="reset_value('advertise')">×</span>
                </button>
              </span>
              <span class="alert alert-label mr-2" role="alert" v-if="item.media">
                {{ item.media }}
                <button type="button">
                  <span @click="reset_value('media')">×</span>
                </button>
              </span>
              <span class="alert alert-label mr-2" role="alert" v-if="item.Everything">
                Everything: {{ item.Everything }}
                <button type="button">
                  <span @click="reset_value('Everything', index)">×</span>
                </button>
              </span>

              <span v-if="item.category">
                <span class="alert alert-label mr-2" role="alert" v-if="item.category.length > 0">
                  <span v-for="(ct, index) in item.category" :key="ct.id" :value="ct.id">{{ ct
                    }}<span v-if="index != Object.keys(item.category).length - 1">
                      ,</span></span>
                  <button type="button">
                    <span @click="reset_value('category')">×</span>
                  </button>
                </span>
              </span>
            </span>
            <span class="alert alert-label mr-2" role="alert" v-if="isGenderSelected">
              {{ selectedGender }}
              <button type="button">
                <span @click="reset_value('gender')">×</span>
              </button>
            </span>
            <span class="alert alert-label mr-2" role="alert" v-if="isAgeSelected">
              Age: {{ age.min }} to {{ age.max }}
              <button type="button">
                <span @click="reset_value('age')">×</span>
              </button>
            </span>
            <span class="alert alert-label mr-2" role="alert" v-if="isRepinsSelected">
              Repins: {{ selectedPinMin }} to {{ selectedPinMax }}
              <button type="button">
                <span @click="reset_value('repins')">×</span>
              </button>
            </span>
            <span class="alert alert-label mr-2" role="alert" v-if="created_between &&
              created_between.from != '' &&
              created_between.to != ''
            ">
              Created between: {{ created_between.from }} to
              {{ created_between.to }}
              <button type="button">
                <span @click="reset_value('created_between')">×</span>
              </button>
            </span>
            <span class="alert alert-label mr-2" role="alert" v-if="selectedAdvertiser">
              Selected Advertiser: {{ selectedAdvertiser }}
              <button type="button">
                <span @click="reset_value('selectedAdvertiser')">×</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="second-screen-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="for-bg-color">
              <div class="col-12">
                <div class="d-flex justify-content-center align-items-center pt-5 pb-2">
                  <div class="results" v-if="isAnyFilterApplied && total_records !== null">
                    {{ total_records }} Results
                  </div>
                </div>
                <div class="d-md-flex align-rw pb-5">
                  <div class="col-md-6 col-lg-4">
                    <div class="dropdown-input-wrapper search-dropdown-theme position-relative"
                      @click.stop="openSelect">
                      <span class="position-absolute search-icon-red">
                        <img class="fa-search" :src="require('@/assets/images/red-search.svg')" /></span>
                      <input type="text" placeholder="Select Search" v-model="currentSelect" readonly name="categories"
                        id="categories" class="country-search-input grid-80 dropdown-input padding-left-24" />
                      <img :src="require('@/assets/images/Left.svg')" class="down-arrow-search" />
                      <ul class="language-list dropdown-list" v-click-outside="hideSelectPopup" v-show="selectPopup">
                        <li class="list-item" v-if="filters.length > 0">
                          <label @click="changeSelect($event, '', '')" class="country-name">Select Search</label>
                        </li>
                        <span v-if="filters.length > 0">
                          <li class="list-item" v-for="filter in filters" :key="filter.name">
                            <label :for="filter.name" @click="
                              changeSelect($event, filter.name, filter.id)
                              " class="country-name">{{ filter.name }}</label>
                          </li>
                        </span>
                        <span v-else>
                          <li class="list-item">
                            <label class="country-name">No searches found.
                            </label>
                          </li>
                        </span>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-8 d-flex justify-content-lg-end">
                    <div class="save-current">
                      <button data-toggle="modal" data-target="#exampleModalCenter1" data-backdrop="static"
                        data-keyboard="false" v-if="searchArray.length > 0 ||
                          gender ||
                          currentadvertiser ||
                          currentmedia ||
                          currentTechnology ||
                          selectedCountries.length > 0 ||
                          currentLanguage ||
                          age.min != 20 ||
                          age.max != 60 ||
                          repins.min > 0 ||
                          repins.max < 10000000 ||
                          (created_between &&
                            created_between.from != '' &&
                            created_between.to != '') ||
                          search != '' ||
                          selectedCategories.length > 0">
                        Save Current Search
                      </button>
                      <button data-toggle="modal" data-target="#exampleModalCenter1" data-backdrop="static"
                        data-keyboard="false" v-else disabled>
                        Save Current Search
                      </button>
                    </div>
                    <!--  -->
                    <div class="modal fade manage-search-pupop" id="exampleModalCenter" tabindex="-1" role="dialog"
                      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <!--  -->
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">
                              Manage Search
                            </h5>
                          </div>
                          <!--  -->
                          <div class="modal-body">
                            <div class="scrollbar" id="style-1">
                              <div class="force-overflow">
                                <!--  -->
                                <div class="row manage-search-title">
                                  <div class="col-3">
                                    <p class="manage-name">Name</p>
                                  </div>
                                  <div class="col-7">
                                    <p class="manage-perameters">Parameters</p>
                                  </div>
                                </div>
                                <!--  -->
                                <span v-if="filters.length > 0">
                                  <div class="row manage-title py-2" v-for="filter in filters" :key="filter.name">
                                    <div class="col-3">
                                      <p class="word-break mr-3 text-left font-weight-bold">
                                        {{ filter.name }}
                                      </p>
                                    </div>
                                    <div class="col-8 perameters-deatil" @click="fetch_single_filter(filter.id)">
                                      <!--  -->
                                      <span v-if="filter.json_data">
                                        <span v-for="val in jsonToArray(filter.json_data)" :key="val">
                                          <span v-if="val.media" class="mr-2"><span class="font-w-semi-bold mr-2">Media:
                                            </span><span>{{ val.media }}</span><span>,</span></span>
                                          <span v-if="val.language" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Language:</span>
                                            <span>{{ val.language }}</span><span>,</span></span>

                                          <span v-if="val.gender" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Gender:</span>
                                            <span>{{ val.gender }}</span><span>,</span></span>
                                          <span v-if="val.technologies" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Technology:</span>
                                            <span>{{ val.technologies }},</span></span>
                                          <span v-if="val.age" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Age:</span>
                                            <span>{{ val.age.min }} to
                                              {{ val.age.max }},</span></span>
                                          <span v-if="val.repins" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Repins:</span>
                                            <span>{{ val.repins.min }} to
                                              {{ val.repins.max }},</span></span>
                                          <span v-if="val.created_between" class="mr-2">
                                            <span class="font-w-semi-bold mr-2">Created between:</span>
                                            <span>{{ val.created_between.from }} to {{ val.created_between.to }},
                                            </span>
                                          </span>
                                          <span v-if="val.Products" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Products:</span>
                                            <span>{{
                                              val.Products
                                            }}</span></span>
                                          <span v-if="val.Everything" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Everything:</span>
                                            <span>{{
                                              val.Everything
                                            }}</span></span>
                                          <span v-if="val.Blog" class="mr-2"><span
                                              class="font-w-semi-bold mr-2">Blog:</span>
                                            <span>{{ val.Blog }}</span></span>
                                          <span v-if="val.country" class="mr-2 d-block">
                                            <span><span class="font-w-semi-bold mr-2"> Countries:</span>
                                              <span v-for="(count, index ) in val.country" :key="count">{{ count }}<span
                                                  v-if="index != Object.keys(val.country).length - 1">
                                                  ,</span></span></span>
                                          </span>
                                          <span v-if="val.category" class="mr-2 d-block">
                                            <span><span class="font-w-semi-bold mr-2">
                                                Categories:</span>
                                              <span v-for="(ctgry, index) in val.category" :key="ctgry">{{ ctgry }}<span
                                                  v-if="index != Object.keys(val.category).length - 1">,</span></span></span>
                                          </span>
                                        </span>
                                      </span>
                                      <!--  -->
                                    </div>
                                    <div class="col-1">
                                      <span class="close-btn-icon" @click="
                                        removeItem(
                                          $event,
                                          filter.id,
                                          filter.name
                                        )
                                        ">&times;</span>
                                    </div>
                                  </div>
                                </span>
                                <span v-else>
                                  <p>You don't have any saved searches yet.</p>
                                </span>
                                <!--  -->
                              </div>
                            </div>
                          </div>
                          <!--  -->
                          <div class="modal-footer">
                            <button type="button" v-if="filters.length > 0" class="modal-btn-link mr-3"
                              @click="delete_all_filters()">
                              Clear All
                            </button>
                            <button type="button" v-else class="modal-btn-link mr-3" disabled>
                              Clear All
                            </button>
                            <button type="button" class="modal-red-btn" id="close_pop" data-dismiss="modal">
                              Ok
                            </button>
                          </div>
                          <!--  -->
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div class="edit-icon ml-4 d-flex">
                      <img class="img-fluid" data-toggle="modal" data-target="#exampleModalCenter" @click="manageFilter"
                        :src="require('@/assets/images/pencil-icon.svg')" />
                    </div>
                    <div class="modal fade serach-popup-save" id="exampleModalCenter1" tabindex="-1" role="dialog"
                      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <Form @submit="saveFilter" v-slot="{ errors }" id="form_id">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLongTitle">
                                Save Search
                              </h5>
                            </div>
                            <div class="modal-body">
                              <div class="alert alert-danger" v-if="showError">
                                {{ errorMsg }}
                              </div>
                              <div class="alert alert-success" v-if="showSuccess">
                                Filter saved successfully
                              </div>
                              <div class="form-group" :class="errors.name ? 'field-error' : ''">
                                <label for="exampleInputEmail1">Enter Name</label>
                                <Field type="text" class="form-control shadow-none error-color-inpt" maxlength="50"
                                  name="name" id="exampleInputEmail1" :rules="nameRules" required
                                  aria-describedby="emailHelp" placeholder="Full name" />
                                <ErrorMessage name="name" />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="modal-btn-link" id="close_save" data-dismiss="modal">
                                Cancel
                              </button>
                              <button type="submit" class="modal-red-btn">
                                Add
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- {{ pinterest_data.length }} -->
                <span v-if="pinterest_data.length > 0">
                  <div class="main-cardpin">
                    <q-card class="my-card card-pin" v-for="(data, index) in pinterest_data" :key="index">
                      <div class="row align-items-center pt-2">
                        <div class="col-sm-8 col-8 d-flex header-img align-items-center">
                          <div class="advertiser-section pl-0">
                            <div class="avatar">
                              <!-- <img src="https://i.pinimg.com/280x280_RS/d5/51/8f/d5518f3f30178ecc397cf53f7a90f1d6.jpg"
                                alt="User Avatar"> -->
                              <img :src="data.creatorAvatarUrl || require('@/assets/images/favicon.png')"
                                alt="User Avatar">
                            </div>
                            <div class="advertiser-info">
                              <!-- <p class="name">John Doe</p>
                              <p class="followers">Followers: 1,234</p> -->
                              <!-- <p class="name">{{ data.creatorName || 'Unknown' }}</p> -->
                              <p class="name">
                                <a class="m-0 text-dark" style="text-decoration: none; overflow-wrap: break-word; word-break: break-word;" 
                                :href="data.creatorUrlSlug ? `https://pinterest.com/${data.creatorUrlSlug}` : null"
                                  target="_blank" v-if="data.creatorUrlSlug">
                                  {{ data.creatorName || 'Unknown' }}
                                </a>
                                <span v-else>{{ 'Unknown' }}</span>
                              </p>
                              <p class="followers">Followers: {{ data.followerCount || '0' }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 col-4 pl-0">
                          <div class="pin-int d-flex">
                            <div class="mr-1 align-self-center">
                              <img :src="require('@/assets/images/pin.svg')" />
                            </div>
                            <div class="align-self-center">
                              <span class="pin-txt">{{ kFormatter(data.repins) }} Pins</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-text">
                        <a href="#" style="
                                 text-decoration: none !important;
                                 color: inherit;
                               ">
                          <h5 @click="getindex(index)">
                            {{ data.title }}
                          </h5>
                        </a>
                        <p v-if="data.updatedAt != ''">
                          Created On: {{ data.updatedAt }}
                        </p>
                      </div>
                      <p class="mb-2 pt-2">{{ data.desc }}</p>
                      <div class="image-setup position-relative">
                        <a class="go-to" :href="'https://www.pinterest.com/pin/' + data.pinnum"
                          v-if="data.link != 'None'" target="_blank">
                          <img :src="require('@/assets/images/up-arrow.svg')" />
                        </a>
                        <a href="#" style="text-decoration: none">
                          <div class="hero-card-img pin-img position-relative" v-if="data.type == 'image'"
                            @click="getindex(index)">
                            <img :src="data.url" />
                          </div>
                        </a>
                        <div class="hero-card-img position-relative" v-if="data.type == 'video' || data.type == ''">
                          <vue3-video-player id="test" :src="data.url" preload="auto"></vue3-video-player>
                        </div>
                        <div class="bottom-img d-flex video-button" v-if="data.type == 'video' || data.type == ''">
                          <a class="shop-btn" :href="data.link" v-if="data.link != 'None'" target="_blank">Shop Now
                          </a>
                          <a :src="data.url" class="download" @click="forceDownload(data.url, data, index)">
                            <img :src="require('@/assets/images/downl.svg')" v-if="this.currentId != index" />
                            <q-circular-progress indeterminate size="22px" color="blue"
                              v-if="this.currentId == index" />
                          </a>
                        </div>
                        <div class="bottom-img d-flex" v-if="data.type == 'image'">
                          <a class="shop-btn" :href="data.link" v-if="data.link != 'None'" target="_blank">Shop Now
                          </a>
                          <a :src="data.url" class="download" @click="forceDownload(data.url, data, index)">
                            <img :src="require('@/assets/images/downl.svg')" v-if="this.currentId != index" />
                            <q-circular-progress indeterminate size="22px" color="blue"
                              v-if="this.currentId == index" />
                          </a>
                        </div>
                      </div>
                    </q-card>
                  </div>

                  <!-- <div class="" id="listingTable" v-if="total_pages != '' || total_pages != 1"></div> -->
                  <!-- <div class="text-center">
                               <a class="btn-pag  d-inline-flex align-self-center"  v-if="current_page==1" disabled id="btn_prev">Prev</a>
                               <a class="btn-pag  d-inline-flex align-self-center" @click="prevPage()" v-else  id="btn_prev">Prev</a>
                               <span class="current-page">Page <span  class="align-self-center bold" id="pageVal">{{current_page}}</span> of <span  class="align-self-center" id="page">{{total_pages}}</span></span>
                               <a class="btn-pag d-inline-flex align-self-center" disabled id="btn_next" v-if="current_page==total_pages">Next</a>
                               <a class="btn-pag d-inline-flex align-self-center" @click="nextPage()" id="btn_next" v-else>Next</a>
                            </div> -->
                </span>

                <div class="mx-md-5 mx-2 mt-5" v-else>
                  <div class="row text-center">
                    <p style="text-center: center; width: 100%">
                      No result found
                    </p>
                  </div>
                </div>

                <div class="q-pa-lg flex flex-center row-padding">
                  <q-pagination v-if="total_pages" v-model="current" color="primary" :max="total_pages" :max-pages="6"
                    boundary-numbers boundary-links @click="changePage()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footerSection />
  </div>
</template>
<script>
import backgroundUrl from "@/assets/images/bg-banner.png";
import logoImage from "@/assets/images/logo.png";
import backArrow from "@/assets/images/back_arrow.svg";
import * as yup from "yup";
import headerSection from "./header/header.vue";
import footerSection from "./footer/footer.vue";
import axios from "axios";
import authHeader from "../services/auth-header";
import authHeaderScrapper from "../services/auth-header-scrapper";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2";
import { ref } from "vue";

export default {
  name: "SearchAd",
  components: {
    headerSection,
    footerSection,
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    return {
      current: 1,
    };
  },
  watch: {
    repinMin() {
      if (this.repinMin >= 100 && this.repinMax <= 1000) {
        this.customStep = 100;
      } else if (this.repinMin >= 1000 && this.repinMax <= 10000) {
        this.customStep = 1000;
      } else if (this.repinMin >= 10000 && this.repinMax <= 100000) {
        this.customStep = 5000;
      } else if (this.repinMin >= 100000 && this.repinMax <= 300000) {
        this.customStep = 25000;
      } else if (this.repinMin >= 300000 && this.repinMax <= 1000000) {
        this.customStep = 100000;
      } else {
        this.customStep = 5;
      }
    },
    repinMax() {
      if (this.repinMin >= 100 && this.repinMax <= 1000) {
        this.customStep = 100;
      } else if (this.repinMin >= 1000 && this.repinMax <= 10000) {
        this.customStep = 1000;
      } else if (this.repinMin >= 10000 && this.repinMax <= 100000) {
        this.customStep = 5000;
      } else if (this.repinMin >= 100000 && this.repinMax <= 300000) {
        this.customStep = 25000;
      } else if (this.repinMin >= 300000 && this.repinMax <= 1000000) {
        this.customStep = 100000;
      } else {
        this.customStep = 5;
      }
    },
    selectedCountries() {
      var check = false;
      var self = this;

      if (this.selectedCountries.length > 0) {
        var myObj = { country: this.selectedCountries };
        this.searchArray.forEach((el) => {
          if (typeof el.country !== "undefined") {
            check = true;
            el.country = self.selectedCountries;
          }
        });
        if (check == false) {
          this.searchArray.push(myObj);
        }
      } else {
        this.searchArray.forEach((el) => {
          if (typeof el.country !== "undefined") {
            delete el.country;
          }
        });
      }
      this.countryPlaceholder =
        this.selectedCountries.length > 0
          ? this.selectedCountries.length + " Selected"
          : "Select Country";
      this.filterdata();
    },
    selectedCategories() {
      var check = false;
      var self = this;
      if (this.selectedCategories.length > 0) {
        var myObj = { category: this.selectedCategories };
        this.searchArray.forEach((el) => {
          if (typeof el.category !== "undefined") {
            check = true;
            el.category = self.selectedCategories;
          }
        });
        if (check == false) {
          this.from = 0;
          this.searchArray.push(myObj);
        }
      } else {
        this.searchArray.forEach((el) => {
          if (typeof el.category !== "undefined") {
            delete el.category;
          }
        });
      }
      this.categoryPlaceholder =
        this.selectedCategories.length > 0
          ? this.selectedCategories.length + " Selected"
          : "Select Category";
      this.filterdata();
    },

    created_between() {
      var t = this;
      if (typeof this.created_between.from == "undefined") {
        this.created_between = {
          from: this.created_between,
          to: this.created_between,
        };
      }
      setTimeout(function () {
        t.filterdata();

        document.getElementById('close_date_popup_button') ? document.getElementById('close_date_popup_button').click() : '';

        this.current = 1;
      }, 200);
    },
    categorySearch() {
      let result = this.categories.filter((obj) => {
        return obj.name
          .toLowerCase()
          .trim()
          .startsWith(this.categorySearch.toLowerCase().trim());
      });
      if (this.categorySearch == "") {
        this.categoryFilter = this.categories;
      } else {
        this.categoryFilter = result;
      }
    },

    date() {
      console.log('');
    },

  },
  data() {
    return {
      subs: "",
      age: {
        min: 20,
        max: 60,
      },
      repins: {
        min: 0,
        max: 10000000,
      },
      selectedAgeMin: 0,
      selectedAgeMax: 0,
      selectedPinMin: 0,
      selectedPinMax: 0,
      isRepinsSelected: false,
      isGenderSelected: false,
      isAgeSelected: false,
      currentId: null,
      created_between: { from: "", to: "" },
      countryPlaceholder: "Select Country",
      date: new Date(),
      backgroundUrl,
      logoImage,
      countryFilter: [],
      languageFilter: [],
      technologiesFilter: [],
      mediaFilter: [],
      categorySearch: "",
      backArrow,
      currentmedia: "",
      currentnetwork: "",
      currentLanguage: "",
      currentCategory: "",
      currentTechnology: "",
      currentpins: "",
      currentComments: "Everything",
      currentSelect: "Select Search",
      genderPopup: false,
      countriesPopup: false,
      languagePopup: false,
      mediaPopup: false,
      technologyPopup: false,
      commetsPopup: false,
      selectPopup: false,
      datePopup: false,
      categoryPopup: false,
      selectedCountries: [],
      selectedCategories: [],
      technologies: [],
      countries: [],
      language: [],
      categories: [],
      category: "",
      gender: "",
      selectedGender: "",
      listSearch: "",
      searchArray: [],
      showError: false,
      showSuccess: false,
      errorMsg: "",
      loading: false,
      message: "",
      filters: [],
      alert: false,
      likealert: false,
      showsearchError: false,
      errorsearchMsg: "",
      dynamicSearch: "",
      search: "",
      repinMin: 0,
      repinMax: 10000000,
      pinterest_data: [],
      current_page: 1,
      total_records: 0,
      from: 0,
      total_pages: "",
      visible: false,
      preview: {},
      customStep: 5,
      mediaTypes: [
        {
          val: 1,
          name: "Photo",
        },
        {
          id: 2,
          name: "Video",
        },
      ],
      advertiserSuggestions: [], // To store fetched advertiser data
      filteredAdvertisers: [], // To store filtered advertiser data for suggestions
      searchQuery: "", // To store the current search input
      selectedAdvertiser: null // To store the selected advertiser
    };
  },
  computed: {
    subscription() {
      return localStorage.getItem('subscription');
      // return this.$store.state.auth.subscription;
    },
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.records.slice(startIndex, endIndex);
    },
    isAnyFilterApplied() {
      return (
        this.searchArray.length > 0 ||
        this.selectedCountries.length > 0 ||
        this.gender !== '' ||
        this.currentTechnology !== '' ||
        this.currentLanguage !== '' ||
        this.currentmedia !== '' ||
        (this.age.min !== 20 || this.age.max !== 60) ||
        (this.repins.min !== 0 || this.repins.max !== 10000000) ||
        this.created_between.from !== '' ||
        this.created_between.to !== '' ||
        this.selectedCategories.length > 0 ||
        this.selectedAdvertiser !== null
      );
    },
  },
  methods: {
    changePage() {
      this.from = 12 * this.current - 12;
      this.from = !this.from ? 0 : this.from;

      localStorage.setItem("page", this.current);
      localStorage.setItem("from", this.from);

      this.filterdata();
    },
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    setCurrentItemId(id) {
      this.currentId = id;
    },
    onSubmit() {
      this.$router.push({ name: "Login" });
    },
    changeAge() {
      this.current = 1;
      this.filterdata();
    },
    openGender() {
      this.hideCountryPopup();
      this.hideLanguagePopup();
      this.hideCategoryPopup();
      this.hideTechPopup();
      this.hidemediaPopup();
      this.genderPopup = !this.genderPopup;
    },
    openCountry() {
      this.hideGenderPopup();
      this.hideLanguagePopup();
      this.hideCategoryPopup();
      this.hideTechPopup();
      this.hidemediaPopup();
      this.countriesPopup = !this.countriesPopup;
    },
    openLanguage() {
      this.hideGenderPopup();
      this.hideCountryPopup();
      this.hideCategoryPopup();
      this.hideTechPopup();
      this.hidemediaPopup();
      this.languagePopup = !this.languagePopup;
    },
    openCategory() {
      this.hideGenderPopup();
      this.hideCountryPopup();
      this.hideTechPopup();
      this.hidemediaPopup();
      this.categoryPopup = !this.categoryPopup;
    },
    openCommets() {
      this.commetsPopup = !this.commetsPopup;
    },
    openSelect() {
      this.loading = true;
      var self = this;
      this.get_filters();
      setTimeout(function () {
        self.loading = false;
        self.selectPopup = !self.selectPopup;
      }, 1000);
    },
    manageFilter() {
      this.loading = true;
      var self = this;
      this.get_filters();
      setTimeout(function () {
        self.loading = false;
      }, 1000);
    },
    openDate() {
      this.datePopup = !this.datePopup;
    },
    openTechPopup() {
      this.hideGenderPopup();
      this.hideCountryPopup();
      this.hideCategoryPopup();
      this.hideTechPopup();
      this.hidemediaPopup();
      this.technologyPopup = !this.technologyPopup;
    },

    openmedia() {
      this.hideGenderPopup();
      this.hideCountryPopup();
      this.hideLanguagePopup();
      this.hideCategoryPopup();
      this.hideTechPopup();
      this.mediaPopup = !this.mediaPopup;
    },

    changeLanguage(e, val) {
      this.currentLanguage = val;
      var myObj = { language: val };
      var check = false;
      this.searchArray.forEach((el) => {
        if (typeof el.language !== "undefined") {
          check = true;
          el.language = val;
        }
      });
      if (check == false) {
        this.from = 0;
        this.searchArray.push(myObj);
      }
      if (document.querySelectorAll(".language-list li.active").length > 0) {
        document.querySelectorAll(".language-list li.active").forEach((el) => {
          el.classList.remove("active");
        });
      }
      e.target.parentNode.classList.add("active");
      this.languageSearch = "";
      this.languagePopup = false;
      this.current = 1;
      this.filterdata();
    },
    changetechnology(e, val) {
      var myObj = { technologies: val };
      var check = false;
      this.searchArray.forEach((el) => {
        if (typeof el.technologies !== "undefined") {
          check = true;
          el.technologies = val;
        }
      });
      if (check == false) {
        this.from = 0;
        this.searchArray.push(myObj);
      }
      this.currentTechnology = val;
      if (document.querySelectorAll(".technology-list li.active").length > 0) {
        document
          .querySelectorAll(".technology-list li.active")
          .forEach((el) => {
            el.classList.remove("active");
          });
      }
      e.target.parentNode.classList.add("active");
      this.technologysearch = "";
      this.technologyPopup = false;
      this.filterdata();
      this.current = 1;
    },
    changeSelect(e, val, id) {
      if (
        document.querySelectorAll(".select-search-popup li.active").length > 0
      ) {
        document
          .querySelectorAll(".select-search-popup li.active")
          .forEach((el) => {
            el.classList.remove("active");
          });
      }
      this.currentSelect = val;
      e.target.classList.add("active");
      this.selectPopup = !this.selectPopup;
      this.fetch_single_filter(id);
    },
    changeCommets(e, val) {
      if (document.querySelectorAll(".comments-data li.active").length > 0) {
        document.querySelectorAll(".comments-data li.active").forEach((el) => {
          el.classList.remove("active");
        });
      }
      this.currentComments = val;
      e.target.classList.add("active");
      this.commetsPopup = !this.commetsPopup;
    },

    changemedia(e, val) {
      this.currentmedia = val;
      this.filterdata();
      var myObj = { media: this.currentmedia };
      var check = false;
      this.searchArray.forEach((el) => {
        if (typeof el.media !== "undefined") {
          check = true;
          el.media = val;
        }
      });
      if (check == false) {
        this.from = 0;
        this.searchArray.push(myObj);
      }
      if (document.querySelectorAll(".media-list li.active").length > 0) {
        document.querySelectorAll(".media-list li.active").forEach((el) => {
          el.classList.remove("active");
        });
      }
      e.target.parentNode.classList.add("active");
      this.mediaSearch = "";
      this.mediaPopup = false;
      this.current = 1;
      this.filterdata();
    },

    removeItem(e, id, name) {
      e.target.parentNode.parentNode.remove();
      if (id != "" || id !== undefined) {
        this.delete_filters(id, name);
      }
    },
    hidePopupComment() {
      this.commetsPopup = false;
    },
    hideGenderPopup() {
      this.genderPopup = false;
    },
    hideCountryPopup() {
      this.countriesPopup = false;
    },
    hideLanguagePopup() {
      this.languagePopup = false;
    },
    hideCategoryPopup() {
      this.categoryPopup = false;
    },
    hideTechPopup() {
      this.technologyPopup = false;
    },
    hideSelectPopup() {
      this.selectPopup = false;
    },
    hideDateFilterPopup() {
      this.datePopup = false;
    },
    hidemediaPopup() {
      this.mediaPopup = false;
    },
    clear_all() {
      this.loading = true;
      this.currentmedia = "";
      this.currentTechnology = "";
      this.currentadvertiser = "";
      this.currentLanguage = "";
      this.selectedCountries = [];
      this.gender = "";
      (this.age = {
        min: 20,
        max: 60,
      }),
        (this.repins = {
          min: 0,
          max: 10000000,
        });
      this.created_between = { from: "", to: "" };
      this.search = "";
      this.currentComments = "Everything";
      this.currentLanguage = "";
      this.isRepinsSelected = false;
      this.isAgeSelected = false;
      this.isGenderSelected = false;
      this.selectedCategories = [];
      this.preview = {};
      this.selectedAdvertiser = null;
      localStorage.removeItem('selectedAdvertiser');
      localStorage.removeItem('selectedAdvertiserData');
      this.filterdata();

      if (document.querySelectorAll(".language-list li.active").length > 0) {
        document.querySelectorAll(".language-list li.active").forEach((el) => {
          el.classList.remove("active");
        });
      }
      if (document.querySelectorAll(".advertiser-list li.active").length > 0) {
        document
          .querySelectorAll(".advertiser-list li.active")
          .forEach((el) => {
            el.classList.remove("active");
          });
      }
      if (document.querySelectorAll(".technology-list li.active").length > 0) {
        document
          .querySelectorAll(".technology-list li.active")
          .forEach((el) => {
            el.classList.remove("active");
          });
      }
      if (document.querySelectorAll(".media-list li.active").length > 0) {
        document.querySelectorAll(".media-list li.active").forEach((el) => {
          el.classList.remove("active");
        });
      }
      if (document.querySelectorAll(".category-list li.active").length > 0) {
        document.querySelectorAll(".category-list li.active").forEach((el) => {
          el.classList.remove("active");
        });
      }
      this.loading = false;
      this.searchArray = [];
      this.searchArray.length = 0;

      localStorage.removeItem('selectedAgeData');
      localStorage.removeItem('selectedRepinsData');
      //localStorage.removeItem('selectedAdvertiser');

    },
    reset_value(value, ind) {
      if (value == "country") {
        this.selectedCountries = [];
        this.searchArray.forEach((el) => {
          if (typeof el.country !== "undefined") {
            delete el.country;
          }
        });
      } else if (value == "gender") {
        this.gender = "";
        this.searchArray.forEach((el) => {
          if (el.gender != "") {
            delete el.gender;
          }
        });
      } else if (value == "technologies") {
        this.currentTechnology = "";
        this.searchArray.forEach((el) => {
          if (typeof el.technologies !== "undefined") {
            delete el.technologies;
          }
        });
        if (
          document.querySelectorAll(".technology-list li.active").length > 0
        ) {
          document
            .querySelectorAll(".technology-list li.active")
            .forEach((el) => {
              el.classList.remove("active");
            });
        }
      } else if (value == "language") {
        this.currentLanguage = "";
        this.searchArray.forEach((el) => {
          if (typeof el.language !== "undefined") {
            delete el.language;
          }
        });
        if (document.querySelectorAll(".language-list li.active").length > 0) {
          document
            .querySelectorAll(".language-list li.active")
            .forEach((el) => {
              el.classList.remove("active");
            });
        }
      } else if (value == "media") {
        this.currentmedia = "";
        this.searchArray.forEach((el) => {
          if (typeof el.media !== "undefined") {
            delete el.media;
          }
        });
        if (document.querySelectorAll(".media-list li.active").length > 0) {
          document.querySelectorAll(".media-list li.active").forEach((el) => {
            el.classList.remove("active");
          });
        }
      } else if (value == "age") {
        localStorage.removeItem('selectedAgeData');
        this.isAgeSelected = false;
        (this.age = {
          min: 20,
          max: 60
        }),
          this.searchArray.forEach((el) => {
            if (typeof el.age !== "undefined") {
              delete el.age;
            }
          });
      } else if (value == "repins") {

        localStorage.removeItem('selectedRepinsData');
        this.isRepinsSelected = false;
        (this.repins = {
          min: 0,
          max: 10000000,
        }),
          this.searchArray.forEach((el) => {
            if (typeof el.repins !== "undefined") {
              delete el.repins;
            }
          });

      } else if (value == "created_between") {
        this.searchArray.forEach((el) => {
          if (typeof el.created_between !== "undefined") {
            delete el.created_between;
          }
        });
        this.created_between = { from: "", to: "" };
      } else if (value == "Everything") {
        this.searchArray.forEach((el, i) => {
          if (typeof el.Everything !== "undefined") {
            if (i == ind) {
              delete el.Everything;
            }
          }
        });
        this.currentComments = "Everything";
      } else if (value == "category") {
        this.selectedCategories = [];
        this.searchArray.forEach((el) => {
          if (typeof el.category !== "undefined") {
            delete el.category;
          }
        });
      }
      else if (value == "selectedAdvertiser") {
        localStorage.removeItem('selectedAdvertiser');
        localStorage.removeItem('selectedAdvertiserData');
        this.selectedAdvertiser = null;
        this.searchArray.forEach((el) => {
          if (typeof el.advertiser !== "undefined") {
            delete el.advertiser;
          }
        });
      }


      this.filterdata();
    },

    get_countries() {
      axios.get("countries", { headers: authHeader() }).then((response) => {
        if (response && response.data) {
          this.countries = response.data;
          this.countryFilter = response.data;
        }
      });
    },
    get_languages() {
      axios.get("languages", { headers: authHeader() }).then((response) => {
        if (response && response.data) {
          this.language = response.data;
          this.languageFilter = response.data;
        }
      });
    },
    get_categories() {
      var result = axios
        .get(process.env.VUE_APP_FILTER_URL + "category", {
          headers: authHeaderScrapper(),
        })
        .then((response) => {
          if (response && response.data) {
            var catArr = response.data;
            catArr.sort((a, b) => {
              let nameA = a.name.toLowerCase();
              let nameB = b.name.toLowerCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            });

            this.loading = false;
            this.categories = catArr;
            this.categoryFilter = response.data;
          }
        });
    },
    get_technologies() {
      axios.get("technologies", { headers: authHeader() }).then((response) => {
        if (response && response.data) {
          this.technologies = response.data;
          this.technologiesFilter = response.data;
        }
      });
    },
    get_filters() {
      axios.get("filters", { headers: authHeader() }).then((response) => {
        if (response && response.data) {
          this.filters = response.data;
        }
      });
    },

    delete_filters(id, name) {
      this.loading = true;
      axios
        .delete("filters/" + id, { headers: authHeader() })
        .then((response) => {
          if (response && response.data) {
            if (this.currentSelect == name) {
              this.currentSelect = "";
            }
            this.loading = false;
          }
          this.loading = false;
        });
    },
    delete_all_filters() {
      this.loading = true;
      axios.get("delete-all", { headers: authHeader() }).then((response) => {
        this.loading = false;
        if (response && response.data) {
          this.filters = [];
          this.searchArray = [];
          this.currentSelect = "";
          setTimeout(function () {
            document.getElementById("close_pop").click();
            this.searchArray = [];
            localStorage.removeItem("filters");
          }, 1000);
        }
      });
    },
    fetch_single_filter(id) {
      this.searchArray = [];
      this.loading = true;
      this.age = {
        min: 20,
        max: 60,
      };
      (this.repins = {
        min: 0,
        max: 10000000,
      }),
        (this.gender = "");
      this.created_between = { from: "", to: "" };
      axios.get("filters/" + id, { headers: authHeader() }).then((response) => {
        if (response && response.data) {
          this.loading = false;
          if (response.data.json_data.length > 0) {
            this.searchArray = this.jsonToArray(response.data.json_data);

            this.searchArray.forEach((el) => {
              if (typeof el.technologies !== "undefined") {
                this.currentTechnology = el.technologies;
              }
              if (typeof el.country !== "undefined") {
                this.selectedCountries = el.country;
              }
              if (typeof el.language !== "undefined") {
                this.currentLanguage = el.language;
              }
              if (typeof el.media !== "undefined") {
                this.currentmedia = el.media;
              }
              if (typeof el.age !== "undefined") {
                this.age = el.age;
              }
              if (typeof el.created_between !== "undefined") {
                this.created_between = el.created_between;
              }
              if (typeof el.repins !== "undefined") {
                this.repins = el.repins;
              }
              if (typeof el.gender !== "undefined") {
                this.gender = el.gender;
              }
              if (typeof el.category !== "undefined") {
                this.selectedCategories = el.category;
              }
              this.filterdata();
              setTimeout(function () {
                document.getElementById("close_pop").click();
              }, 1000);
            });
          }
        }
      });
    },
    saveFilter(data) {
      this.loading = true;
      var self = this;
      if (this.gender != "") {
        var myObj = { gender: this.gender };
        this.searchArray.forEach((el) => {
          if (typeof el.gender !== "undefined") {
            delete el.gender;
          }
        });
        this.from = 0;
        this.searchArray.push(myObj);
      }
      if (this.created_between.from != "" && this.created_between.to != "") {
        var date_created = { created_between: this.created_between };
        this.searchArray.forEach((el) => {
          if (typeof el.created_between !== "undefined") {
            delete el.created_between;
          }
        });
        this.from = 0;
        this.searchArray.push(date_created);
      }
      if (this.age.min != 20 || this.age.max != 60) {
        var age = { age: this.age };
        this.searchArray.forEach((el) => {
          if (typeof el.age !== "undefined") {
            delete el.age;
          }
        });
        this.from = 0;
        this.searchArray.push(age);
      }
      if (this.repins.min != 0 || this.repins.max < 10000000) {
        var repins = { repins: this.repins };
        this.searchArray.forEach((el) => {
          if (typeof el.repins !== "undefined") {
            delete el.repins;
          }
        });
        this.from = 0;
        this.searchArray.push(repins);
      }

      data.json_data = JSON.stringify(this.searchArray);
      axios
        .post("filters", data, { headers: authHeader() })
        .then((response) => {
          if (response.status == true) {
            data = "";
            this.showError = false;
            this.loading = false;
            this.errorMsg = "";
            this.showSuccess = true;
            this.message = response.message;
            this.get_filters();
            document.getElementById("form_id").reset();
            setTimeout(function () {
              self.showSuccess = false;
              document.getElementById("close_save").click();
            }, 1000);
          } else {
            data = "";
            this.loading = false;
            this.showSuccess = false;
            this.showError = true;
            this.errorMsg = response.message;
            this.message = "";
          }
        });
    },
    jsonToArray(json) {
      return JSON.parse(json);
    },
    addToFilter() {
      var search = document.getElementById("global-search").value;
      if (search == "" || search == undefined) {
        this.showsearchError = true;
        this.errorsearchMsg = "Enter search keyword";
        return;
      }
      this.showsearchError = false;
      this.search = search;

      var exists = false;
      this.dynamicSearch = { [this.currentComments]: search };
      var val = this.currentComments;

      this.searchArray.forEach((el) => {
        if (el[val] == this.search) {
          exists = true;
        }
      });
      if (exists != true) {
        this.from = 0;
        this.searchArray.push(this.dynamicSearch);
        this.search = "";
        this.currentComments = "Everything";
      }
      this.preview = {};
      this.filterdata();
      if (document.querySelectorAll(".comments-data li.active").length > 0) {
        document.querySelectorAll(".comments-data li.active").forEach((el) => {
          el.classList.remove("active");
        });
      }
    },

    preview_filter() {
      var search = document.getElementById("global-search").value;
      if (search == "" || search == undefined) {
        this.showsearchError = true;
        this.errorsearchMsg = "Enter search keyword";
        return;
      }
      this.showsearchError = false;
      this.search = search;
      this.preview = { [this.currentComments]: search };
      var self = this;
      this.filterdata();
    },

    async filterdata(type = '') {

      this.checkPlan();
      this.loading = true;
      var searchelement = "";
      this.total_records = null;
      var media = "";
      if (this.currentmedia == "Photo") {
        media = "image";
      } else if (this.currentmedia == "Video") {
        media = "video";
      }
      var customfilter = [];
      var customfilterVal = [];
      var countries = [];
      if (Object.keys(this.preview).length != 0) {
        customfilter.push("Everything");
        searchelement = this.preview[this.currentComments];
        customfilterVal.push(this.preview[this.currentComments]);
      } else {
        this.searchArray.forEach((el) => {
          if (typeof el.Everything !== "undefined") {
            customfilter.push("Everything");
            searchelement = el.Everything;
            customfilterVal.push(el.Everything);
          }
        });
      }

      //set value for UK and Us
      this.selectedCountries.forEach((el) => {
        countries.push(el);
        var index = countries.indexOf("United Kingdom");
        if (index !== -1) {
          countries[index] = "UK";
        }
        var indexsec = countries.indexOf("United States");
        if (indexsec !== -1) {
          countries[indexsec] = "US";
        }
      });
      if (this.created_between == null) {
        this.created_between = { from: "", to: "" };
      }

      //cache filters

      this.selectedGender = this.gender;

      if (this.gender != "") {
        this.isGenderSelected = true;
      } else {
        this.isGenderSelected = false;
      }

      this.genderPopup = false;
      if (this.gender != "") {
        var myObj = { gender: this.gender };
        this.searchArray.forEach((el) => {
          if (typeof el.gender !== "undefined") {
            delete el.gender;
          }
        });

        this.searchArray.push(myObj);
      }

      if (this.created_between.from != "" && this.created_between.to != "") {
        var date_created = { created_between: this.created_between };
        this.searchArray.forEach((el) => {
          if (typeof el.created_between !== "undefined") {
            delete el.created_between;
          }
        });

        this.searchArray.push(date_created);
      }

      this.selectedAgeMin = this.age.min;
      this.selectedAgeMin = this.age.max;


      if (type == 'age') {
        this.isAgeSelected = true;
        localStorage.setItem('selectedAgeData', true);
        var age = { age: this.age };
        this.searchArray.forEach((el) => {
          if (typeof el.age !== "undefined") {
            delete el.age;
          }
        });

        this.searchArray.push(age);
      } else {
        let selectedAgeData = localStorage.getItem('selectedAgeData');
        if (selectedAgeData) {
          this.isAgeSelected = true;

        } else {
          this.isAgeSelected = false;
        }
      }

      // if (this.age.min != 20 && this.age.max != 60) {

      // }

      this.selectedPinMin = this.repins.min;
      this.selectedPinMax = this.repins.max;

      if (type == 'repins') {

        this.isRepinsSelected = true;
        localStorage.setItem('selectedRepinsData', true);
        var repins = { repins: this.repins };
        this.searchArray.forEach((el) => {
          if (typeof el.repins !== "undefined") {
            delete el.repins;
          }
        });

        this.searchArray.push(repins);
      } else {
        let isRepinsThere = localStorage.getItem('selectedRepinsData');
        if (isRepinsThere) {
          this.isRepinsSelected = true;

        } else {
          this.isRepinsSelected = false;
        }

      }

      // Handle advertiser filter
      if (this.selectedAdvertiser) {
        this.isAdvertiserSelected = true;
        localStorage.setItem('selectedAdvertiserData', this.selectedAdvertiser);
        var advertiserObj = { advertiser: this.selectedAdvertiser };
        this.searchArray.forEach((el) => {
          if (typeof el.advertiser !== "undefined") {
            delete el.advertiser;
          }
        });
        this.searchArray.push(advertiserObj);
      } else {
        let selectedAdvertiserData = localStorage.getItem('selectedAdvertiserData');
        if (selectedAdvertiserData) {
          this.isAdvertiserSelected = true;
          this.selectedAdvertiser = selectedAdvertiserData; // Restore the selected advertiser if previously set
        } else {
          this.isAdvertiserSelected = false;
        }
      }

      if (type) {
        this.from = 1;
      } else {
        this.from = !this.from ? 0 : this.from;
      }

      this.searchArray = this.searchArray.filter(obj => Object.keys(obj).length !== 0);
      localStorage.removeItem("filters");
      localStorage.setItem("filters", JSON.stringify(this.searchArray));
      // localStorage.setItem("page", 1);

      //searching params
      var params = {
        repinTo: this.repins.max,
        repinFrom: this.repins.min,
        technology: this.currentTechnology,
        media: media,
        language: this.currentLanguage,
        country: countries,
        customFilterType: customfilter,
        customFilterVal: customfilterVal,
        createdFrom: this.created_between.from,
        createdTo: this.created_between.to,
        size: 12,
        from: this.from,
        groups: this.selectedCategories,
        gender: this.gender,
        advertiser: this.selectedAdvertiser,
      };
      if (this.isAgeSelected) {
        params.ageTo = this.age.max;
        params.ageFrom = this.age.min;
      }
      this.pinterest_data = [];

      var result = axios
        .get(process.env.VUE_APP_FILTER_URL + "fetch-data", {
          params: params,
          headers: authHeaderScrapper(),
        })
        .then((response) => {
          if (response && response.data) {
            this.loading = false;
            console.log("Data from page", response.data);
            this.pinterest_data = [];
            this.pinterest_data = response.data;
            this.total_records = response.total;
            // if (this.isAnyFilterApplied) {
            //   this.total_records = response.total;
            // }
            this.numPages();
            // this.current = 1;
          } else {
            this.loading = false;
          }

          this.videoThumbnails();
        });
    },
    async forceDownload(url, data, id) {
      const responseUrl = url;
      this.setCurrentItemId(id);
      try {
        const resp = await fetch(
          process.env.VUE_APP_DOWNLOAD_URL + "download/?url=" + responseUrl
        );
        const res = await resp.json();
        const newUrl = process.env.VUE_APP_DOWNLOAD_URL + res.file;
        fetch(newUrl)
          .then((response) => {
            return response.blob().then((raw) => ({
              contentType: response.headers.get("Content-Type"),
              raw,
            }));
          })
          .then((data) => {
            let blobUrl = window.URL.createObjectURL(data.raw);
            let a = document.createElement("a");
            a.download = res.file;
            a.href = blobUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
            this.setCurrentItemId(null);
          });
      } catch (e) {
        console.log("error", e);
      }
      return;
    },
    numPages() {
      this.total_pages = Math.ceil(this.total_records / 12);

      if (this.current > this.total_pages) {
        this.current = 1;
      }

      return this.total_pages;
    },
    prevPage() {
      if (this.current_page > 1) {
        document.getElementById("selected_values").scrollIntoView();
        // window.scrollTo(0,0);
        this.current_page--;
        this.from = this.from - 12;
        this.filterdata();
      }
    },
    nextPage() {
      if (this.current_page < this.numPages()) {
        document.getElementById("selected_values").scrollIntoView();
        //   window.scrollTo(0,0);
        this.current_page++;
        this.from = this.from + 12;
        this.filterdata();
      }
    },
    get_profile(data) {
      this.loading = true;
      axios.get("get-profile", { headers: authHeader() }).then((response) => {
        this.loading = false;
        if (response && response.data) {
          this.profile = response.data;
          if (response.data && response.data.subscription.date != undefined) {
            this.subs = response.data.subscription;
          } else {
            this.subs = "";
          }
        }
      });
    },
    checkPlan() {
      axios
        .get("check-subscription", { headers: authHeader() })
        .then((response) => {

          console.log('*****************');
          console.log(response);
          console.log('*****************');

          if (response.status == true) {
            if (response.data.subscription != 1) {
              this.$router.push("/subscription");
            }
          }
        });
    },
    getindex(ind) {
      var postDetail = this.pinterest_data[ind];
      localStorage.setItem("singlePost", JSON.stringify(postDetail));
      localStorage.setItem("page", this.current);

      console.log(this.current, "set current item : ");
      console.log(this.from, "set from item : ");

      this.from = !this.from ? 0 : this.from
      localStorage.setItem("from", this.from);
      this.$router.push("/search-ad-info");
    },
    applyFilters() {
      var arr = this.jsonToArray(localStorage.getItem("filters"));
      if (arr) {
        this.searchArray = [];
        this.loading = true;
        this.age = {
          min: 20,
          max: 60,
        };
        (this.repins = {
          min: 0,
          max: 10000000,
        }),
          (this.gender = "");
        this.created_between = { from: "", to: "" };
        this.searchArray = arr;

        this.searchArray.forEach((el) => {
          if (typeof el.technologies !== "undefined") {
            this.currentTechnology = el.technologies;
          }
          if (typeof el.country !== "undefined") {
            this.selectedCountries = el.country;
          }
          if (typeof el.language !== "undefined") {
            this.currentLanguage = el.language;
          }
          if (typeof el.media !== "undefined") {
            this.currentmedia = el.media;
          }
          if (typeof el.age !== "undefined") {
            this.age = el.age;
          }
          if (typeof el.created_between !== "undefined") {
            this.created_between = el.created_between;
          }
          if (typeof el.repins !== "undefined") {
            this.repins = el.repins;
          }
          if (typeof el.gender !== "undefined") {
            this.gender = el.gender;
          }
          if (typeof el.category !== "undefined") {
            this.selectedCategories = el.category;
          }
        });
        this.filterdata();
      } else {
        this.filterdata();
      }
    },
    videoThumbnails() {
      let videoTagsInterval = setInterval(() => {
        const videoElements = document.getElementsByTagName('video');

        videoElements.forEach(element => {
          element.setAttribute('preload', 'auto');
        });

        if (videoElements.length > 0) {
          clearInterval(videoTagsInterval);
        }
      }, 100);
    },

    handleInput() {

      this.repins.min = (this.repins.min).toString().replace(/[^0-9]/g, '');
      this.repins.max = (this.repins.max).toString().replace(/[^0-9]/g, '');

      if (this.repins.min < 0 || this.repins.min == '') {
        this.repins.min = ''; // Clear the input value if it's negative
      } else {
        this.repinMin = this.repins.min;
      }

      if (this.repins.max < 0 || this.repins.max == '') {
        this.repins.max = '';
      } else {
        this.repinMax = this.repins.max;
      }

    },

    checkDigitOnly(event) {
      if (event.key === 'Backspace' || event.key.startsWith('Arrow')) {
        return;
      }

      // Prevent non-digit characters
      if (!/^\d$/.test(event.key)) {
        event.preventDefault();
      }
    },
    fetchAdvertisers() {
      console.log("Fetch Advertiser Call ----->");
      axios.get(`${process.env.VUE_APP_FILTER_URL}fetch-advertiser`, { headers: authHeaderScrapper() })
        .then(response => {
          if (response && response.data) {
            this.advertiserSuggestions = response.data;
          }
        })
        .catch(error => {
          console.error("Error fetching advertisers:", error);
        });
    },
    filterAdvertisers() {
      console.log("Fetch Advertiser Call ----->")
      if (this.search.length > 0) {
        this.filteredAdvertisers = this.advertiserSuggestions
          .filter(advertiser =>
            advertiser.creatorName.toLowerCase().includes(this.search.toLowerCase())
          );
        // .slice(0, 10); // Limit to 10 suggestions
      } else {
        this.filteredAdvertisers = [];
      }
    },
    selectAdvertiser(advertiser) {
      this.selectedAdvertiser = advertiser.creatorName;
      this.searchQuery = ""; // Clear the search input
      this.filteredAdvertisers = []; // Clear the suggestions
      this.filterdata();

      // Update the URL with the selected advertiser
      //this.$router.push({ query: { ...this.$route.query, advertiser: advertiser.creatorName } });

      // Update the selected values display
      //document.getElementById('selected_values').innerText = `Advertiser: ${advertiser.creatorName}`;
    },
    applyAdvertiserFilter() {
      // Clear previous filters
      this.searchArray = this.searchArray.filter(el => typeof el.advertiser === 'undefined');
      localStorage.removeItem('selectedAdvertiser');
      // Apply advertiser filter
      if (this.selectedAdvertiser) {
        this.isAdvertiserSelected = true;
        localStorage.setItem('selectedAdvertiserData', this.selectedAdvertiser);
        var advertiserObj = { advertiser: this.selectedAdvertiser };
        this.searchArray.push(advertiserObj);
        this.filterdata(); // Trigger the filtering process
      }
    },
  },
  created() {
    // this.applyFilters();
  },

  async mounted() {

    this.$gtag.pageview('/search-ad');

    this.subscription = localStorage.getItem('subscription');

    console.log(this.subscription, 'check susbscription while search add page');

    if (!this.subscription) {
      this.$router.push('/subscription');
      return;
    } else {

      let currentPageViaLocalStorage = localStorage.getItem("page");
      this.current = currentPageViaLocalStorage ? parseInt(currentPageViaLocalStorage) : 1;

      let fromIndexlocalStorage = localStorage.getItem("from");
      this.from = fromIndexlocalStorage ? parseInt(fromIndexlocalStorage) : 1;

      var self = this;
      document
        .getElementById("global-search")
        .addEventListener("keypress", function (event) {
          if (event.keyCode === 13) {
            event.preventDefault();
            self.preview_filter();
          }
        });
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params && params.order_id != undefined) {
        this.get_status();
      } else {
        this.checkPlan();
      }
      this.get_countries();
      this.get_languages();
      this.get_categories();
      this.get_technologies();
      // this.get_filters();
      this.applyFilters();
      this.advertiserFilter = this.demo;
      this.mediaFilter = this.mediaTypes;
      this.pinsFilter = this.demo;
      this.networkFilter = this.demo;

      this.videoThumbnails();
    }
    this.fetchAdvertisers();
    //Retrieve advertiser filter from localStorage
    const advertiserSelected = localStorage.getItem('selectedAdvertiser');
    if (advertiserSelected) {
      this.selectedAdvertiser = advertiserSelected;
      this.applyAdvertiserFilter();
    }
  },
  setSteps() {
    this.repinMax = this.repins.max;
    this.repinMin = this.repins.min;
  },
};
</script>
<style scoped>
.advertiser-section {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

.avatar {
  flex-shrink: 0;
  margin-right: 20px;
}

.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.advertiser-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
}

.name {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
}

.followers {
  color: #777;
  margin: 0;
  font-size: 12px;
}

.pin-txt {
  font-size: 12px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  /* Ensure the dropdown is above other content */
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown li:hover {
  background-color: #f0f0f0;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.img-section {
  height: 500px;
}

.hero-card-img-css img {
  height: 100%;
  object-fit: cover;
}

.results {
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
}
</style>
