<template>
   <div>
      <div class="pinspy-load" v-if = "loading"><span class="load"></span></div>
      <!-- header  -->
      <headerSection />
      <section class="drop-my-accnt pt-100">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="my-acnt-text">
                     <h1 class="heading-account-text">My Account</h1>
                  </div>
               </div>
            </div>
            <div class="col-lg-11 col-xl-10 cls-dropdown">
               <div id="accordion">
                  <div class="row">
                     <div class="card-1">
                        <div class="card-header-1" id="headingOne" @click.prevent="resetValues()">
                           <h2 class="mb-0 d-flex justify-content-between align-items-center pr-2" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              <button class="btn btn-link" >
                              info
                              </button>
                              <i class="fas fa-chevron-down down-arrow"></i>
                           </h2>
                        </div>
                        <div id="collapseOne" class="collapse show mx-5" aria-labelledby="headingOne" data-parent="#accordion">
                           <div class="alert alert-success" v-if="showSuccess" >Profile Information saved successfully.</div>
                           <i class="fas fa-chevron-up collapse-arrow" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i>
                           <div class="row pt-20 ">
                              <div class="col-md-4">
                                 <div class="form-data form-group">
                                    <label class="form-label" >First Name</label>
                                    <input type="text" class="form-control-data shadow-none" placeholder="First name" v-model="formData.first_name">
                                 </div>
                              </div>
                              <div class="col-md-4">
                                 <div class="form-data form-group">
                                    <label class="form-label">Last Name</label>
                                    <input type="text" class="form-control-data shadow-none" placeholder="Last name" v-model="formData.last_name">
                                 </div>
                              </div>
                              <div class="col-md-4">
                                 <div class="form-data form-group">
                                    <label class="form-label">Company</label>
                                    <input type="text" class="form-control-data shadow-none" placeholder="Company name" v-model="formData.company">
                                 </div>
                              </div>
                           </div>
                           <div class="row mt-3">
                              <div class="col-md-6 col-lg-4">
                                 <div class="form-data form-group">
                                    <label class="form-label">Email</label>
                                    <input type="email" class="form-control-data shadow-none" readonly v-model="formData.email">
                                 </div>
                              </div>
                              <!--  -->  
                              <div class="col-md-6 col-lg-8">
                                 <div class="form-data form-group">
                                    <label class="d-block form-label">
                                    &nbsp;</label>
                                    <a class="button-red d-inline-flex m-0 theme-btn btn-xs"  @click.prevent="onBasicSubmit()">Save Changes</a>
                                 </div>
                              </div>
                              <!--  -->
                           </div>
                           <hr class="theme-hr">
                           <div class="row ">
                              <div class="col-md-6 col-lg-4">
                                 <div class="form-data form-group">
                                    <label class="form-label">New Email</label>
                                    <input type="email" class="form-control-data shadow-none" v-model="verify.email" id="new_email" placeholder="Enter new email">
                                    <span class="welcome-login" style= "color:red; font-size:16px;" v-if="showNewEmailError">{{errorverifyMsg}}</span>
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-8">
                                 <div class="form-data form-group">
                                    <label class="d-block form-label">
                                    &nbsp;</label>
                                    <button class="button-grey-light theme-btn btn-xs d-inline-flex m-0" href="javascript:void(0)" v-if="verify.email" @click.prevent="verify_email()">Verify Email</button>
                                    <button class="button-grey-light theme-btn btn-xs d-inline-flex m-0" href="javascript:void(0)" v-else disabled>Verify Email</button>
                                 </div>
                              </div>
                           </div>
                           <div class="row my-3">
                              <div class="col-12">
                                 <div class="alert alert-danger" v-if="showError">{{errorMsg}}</div>
                                 <div class="alert alert-success" v-if="showVerifySuccess">Email has been sent your email address. Please verify.</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="card-1">
                        <div class="card-header-1" id="headingTwo" @click.prevent="resetValues()">
                           <h2 class="mb-0 d-flex justify-content-between align-items-center pr-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              <button class="btn btn-link collapsed" >
                              Subscription
                              </button>
                              <i class="fas fa-chevron-down down-arrow" ></i>
                           </h2>
                        </div>
                        <div id="collapseTwo" class="collapse  mx-5" aria-labelledby="headingTwo" data-parent="#accordion">
                           <i class="fas fa-chevron-up collapse-arrow" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"></i>
                           <div class="row subscription-wrapper" v-if="subs" >
                              <div class="col-md-6 col-lg-4 start-end-date">
                                 <div class="date-subscription">
                                    <p class="start-date">Start Date : <span class="start-date-spn">  {{ profile.subscription.date }}</span></p>
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-8 start-end-date">
                                 <div class="date-subscription">
                                    <p class="start-date">End Date : <span class="start-date-spn"> {{ profile.subscription.expired_date }}</span></p>
                                 </div>
                              </div>
                           </div>
                           <!-- <div class="row mb-4 mb-lg-0 subscription-wrapper">
                              <div class="col-md-4 start-end-date">
                                <div class="date-subscription">
                                  <p class="start-date">Analysis :</p>
                                </div>
                              </div>
                              <div class="col-md-3 analysis-cls">
                                <div class="analysis">
                                  <p class="total-num">100000</p>
                                  <span class="total">Total</span>
                                </div>
                              </div>
                              <div class="col-md-2 analysis-cls">
                                <div class="analysis">
                                  <p class="total-num">17,800</p>
                                  <span class="total">Used</span>
                                </div>
                              </div>
                              <div class="col-md-3 analysis-cls">
                                <div class="analysis">
                                  <p class="total-num">82,200</p>
                                  <span class="total">Left</span>
                                </div>
                              </div>
                              </div> -->
                           <div class="row subscription-btn-wrapper" v-if="subs">
                              <!-- <div class="col-lg-4 text-center">
                                 <div class="coupon-section">
                                   <div class="form-data apply-coupon-btn">
                                     <label class="form-label">
                                       &nbsp;
                                     </label>
                                     <a href="javascript:void(0)" class="apply-coupon"> Apply Coupon</a>
                                   </div>
                                 </div>
                                 </div> -->
                              <div class="col-lg-12 text-center">
                                 <div v-if="profile.subscription.status==1" class="manage-btn"><button class="button-red d-inline-flex m-0 theme-btn btn-xs" data-toggle="modal" data-target="#exampleModal">Cancel Subscription</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="card-1">
                        <div class="card-header-1" id="headingThree" @click.prevent="resetValues()">
                           <h2 class="mb-0 d-flex justify-content-between align-items-center pr-2" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                              <button class="btn btn-link">
                              Security
                              </button>
                              <i class="fas fa-chevron-down down-arrow"></i>
                           </h2>
                        </div>
                        <div id="collapseThree" class="collapse mx-5 mb-3" aria-labelledby="headingThree" data-parent="#accordion">
                           <div class="alert alert-danger" v-if="showError">{{errorMsg}}</div>
                           <div class="alert alert-success" v-if="showSuccess">Password changed successfully</div>
                           <i class="fas fa-chevron-up collapse-arrow" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"></i>
                           <div class="row pt-20">
                              <div class="col-md-6 col-lg-4">
                                 <div class="form-data form-group">
                                    <label class="form-label">Current Password</label>
                                    <input type="password" class="form-control-data shadow-none" placeholder="......." v-model="passwordformData.current_password">
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-4">
                                 <div class="form-data form-group">
                                    <label class="form-label">New</label>
                                    <input type="password" class="form-control-data shadow-none" placeholder="Enter New Password"  v-model="passwordformData.password">
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-4">
                                 <div class="form-data form-group">
                                    <label class="form-label">Confirm</label>
                                    <input type="password" class="form-control-data shadow-none" placeholder="Confirm Password"  v-model="passwordformData.confirm_password">
                                 </div>
                              </div>
                           </div>
                           <div class="row my-4">
                              <div class="col-lg-12 text-center">
                                 <a class="button-red d-inline-flex m-0 theme-btn btn-xs" @click.prevent="change_password()">Save Password</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- <div class="row">
                     <div class="card-1">
                       <div class="card-header-1" id="headingFour" @click.prevent="resetValues()">
                         <h5 class="mb-0 d-flex justify-content-between align-items-center pr-2" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                           <button class="btn btn-link">
                             Affiliate
                           </button>
                           <i class="fas fa-chevron-down down-arrow"></i>
                         </h5>
                       </div>
                       <div id="collapseFour" class="collapse mx-5" aria-labelledby="headingFour" data-parent="#accordion">
                         <i class="fas fa-chevron-up collapse-arrow" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour"></i>
                         <div class="row affiliate-wrapper no-gutters">
                           <div class="col-lg-5 col-12 affiliate">
                             <div class="date-subscription">
                               <p class="start-date">Your Affiliate code :<span class="start-date-spn"> 50B879410</span></p>
                             </div>
                           </div>
                           <div class="col-lg-4 col-12 affiliate">
                             <div class="date-subscription">
                               <p class="start-date">Countable revenue :<span class="start-date-spn"> 587$</span></p>
                             </div>
                           </div>
                           <div class="col-lg-3 col-12 text-center">
                             <div class="btn-affiliare">
                               <a href="javascript:void(0)" class="with-draw"> Withdraw</a>
                             </div>
                           </div>
                         </div>
                         <div class="row affiliate-wrapper no-gutters mt-3">
                           <div class="col-md-3">
                             <div class="urls-part">
                               <p class="url">URLs :</p>
                             </div>
                           </div>
                           <div class="col-md-8">
                             <div class="https-link">
                               <ul>
                                 <li>1. https://app.pinspy.com/.?07154d4</li>
                                 <li>2.  https://app.pinspy.com/registration_35416521/45>_page</li>
                                 <li>3.  https://app.pinspy.com/.?verify-email</li>
                                 <li>4.  https://app.pinspy.com/.?login_page</li>
                     
                               </ul>
                             </div>
                           </div>
                         </div>
                         <div class="row mb-4 mb-lg-5">
                           <div class="col-lg-2 col-12 start-end-date">
                             <div class="date-subscription">
                               <p class="start-date">Statistics :</p>
                             </div>
                           </div>
                           <div class="col-lg-2 col-6 analysis-cls">
                             <div class="analysis">
                               <p class="total-num">056</p>
                               <span class="total">Clicks</span>
                             </div>
                           </div>
                           <div class="col-lg-3 col-6 analysis-cls">
                             <div class="analysis">
                               <p class="total-num">04</p>
                               <span class="total">Registration</span>
                             </div>
                           </div>
                           <div class="col-lg-3 col-6 analysis-cls">
                             <div class="analysis">
                               <p class="total-num">02</p>
                               <span class="total">With trial coupon</span>
                             </div>
                           </div>
                           <div class="col-lg-2 col-6 analysis-cls">
                             <div class="analysis">
                               <p class="total-num">04</p>
                               <span class="total">Subscriptions</span>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     </div> -->
               </div>
            </div>
         </div>
         <!-- Modal -->
      </section>
      <footerSection />
   </div>
   <div class="modal fade theme-modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h2 class="modal-title" id="exampleModalLabel">Cancel Subscription</h2>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body">
               <div class="col-12">
                  <div class="alert alert-danger" v-if="showError">{{errorMsg}}</div>
                  <div class="alert alert-success" v-if="showSuccess">Subscription cancelled successfully.</div>
               </div>
               <div class="col-12">
                  <p class="body-text mb-0"> Are you sure you want to cancel the subscription?</p>
               </div>
            </div>
            <div class="modal-footer ">
               <button type="button" class="theme-btn button-white modal-btn" data-dismiss="modal" id="close_pop">No</button>
               <button type="button" class="theme-btn button-red modal-btn" @click = "cancel_subscription">Yes</button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>

   import backgroundUrl from "@/assets/images/banner-image.png";
   import logoImage from "@/assets/images/logo.png";
   import * as yup from 'yup';
   import moment from 'moment';
   import headerSection from "./header/header.vue";
   import footerSection from "./footer/footer.vue";
   import axios from 'axios';
   import authHeader from '../services/auth-header';
   export default {
     name: 'Subscription',
     components: {
           headerSection,
           footerSection
     },
     data() {
       return {
         backgroundUrl,
         logoImage,
         current_password: yup.string().required().min(8),
         password: yup.string().required().min(8),
         confirm_password: yup.string().required().min(8),
         profile:[],
         showError: false,
         showSuccess: false,
         errorMsg: '',
         loading: false,
         message: "", 
         subs:'',
         canceled: false,
         verify: {
           email:''
         },
         formData: {
                       first_name: '',
                       last_name: '',
                       company: '',
                       email: ''
                   },
         passwordformData: {
                       current_password: '',
                       password: '',
                       confirm_password: ''
                   },
       }
     },
     computed: {
       subscription() {
         return this.$store.state.auth.subscription;
       }
     },
     mounted() {

         this.$gtag.pageview('/search-ad');
        localStorage.removeItem("filters");
       this.checkPlan()  
       this.get_profile();
     },
     methods: {
       get_profile(data) {
         this.loading = true;
          axios.get('get-profile', { headers: authHeader() }).then(
             (response) => {
                this.loading = false;
               if(response && response.data){
                 this.profile = response.data
                 if(response.data && response.data.subscription.date){
                     this.subs = response.data.subscription;
                 }
                 this.formData = {
                           first_name: response.data.first_name,
                           last_name: response.data.last_name,
                           company: response.data.company,
                           email: response.data.email
                        };
               }
             }
          );
       },
       async checkPlan(){
         //  await axios.get('check-subscription', { headers: authHeader() }).then(
         //     (response) => {
         //        if(response.status==true){
         //             if(response.data.subscription!=1 ){   
         //                this.$router.push("/subscription");
         //             }
         //        }
         //     }
         // );
       },
       onBasicSubmit() {
         this.loading = true;
         var self = this
         axios.post('update-profile', this.formData, { headers: authHeader() }).then(
             (response) => {
                if(response.status==true){
                  this.showError = false;
                  this.loading = false;
                  this.errorMsg = '';
                  this.showSuccess = true;
                  this.message = response.message; 
                  setTimeout(function(){ self.showSuccess=false; }, 1500);
   
                }else{
                  this.loading = false;
                  this.showSuccess = false;
                  this.showError = true;
                  this.errorMsg = response.message; 
                  this.message = ''
                }
             }
          );
       }, 
       change_password() {
         this.loading = true;
         var self = this
         axios.post('change-password',this.passwordformData, { headers: authHeader() }).then(
             (response) => {
                if(response.status==true){
                  this.showError = false;
                  this.loading = false;
                  this.errorMsg = '';
                  this.showSuccess = true;
                  this.message = response.message; 
                  this.passwordformData= {
                       current_password: '',
                       password: '',
                       confirm_password: ''
                   },
                  setTimeout(function(){ self.showSuccess=false; }, 3000);
   
                }else{
                   this.passwordformData= {
                       current_password: '',
                       password: '',
                       confirm_password: ''
                   },
                  this.loading = false;
                  this.showSuccess = false;
                  this.showError = true;
                  this.errorMsg = response.message; 
                  this.message = ''
                }
             }
          );
       },
       
       verify_email() {
         this.loading = true;
         var self = this
         axios.post('verify', this.verify, { headers: authHeader() }).then(
             (response) => {
                if(response.status==true){
                  this.loading = false;
                  this.showNewEmailError = false;
                  this.showVerifySuccess = true;
                  this.verifymessage = response.message; 
                  setTimeout(function(){ self.showVerifySuccess=false; self.verifymessage='' }, 1000);
                }else{
                  this.loading = false;
                  this.showVerifySuccess = false;
                  this.verifymessage = ''
                  this.showNewEmailError = true;
                  
                  this.errorverifyMsg = response.message; 
                  setTimeout(function(){ self.showNewEmailError=false; }, 3000);
                }
             }
          );
       },
   
       cancel_subscription() {
         this.loading = true;
         var self = this
         var url ='';
         if(this.subs.payment_method=='paypal'){
             url = 'paypal-sub-cancel';
         }else{
             url = 'stripe-sub-cancel';
         }
         axios.get(url, { headers: authHeader() }).then(
             (response) => {
                if(response.status==true){
                  this.showError = false;
                  this.loading = false;
                  this.errorMsg = '';
                  this.showSuccess = true;
                  this.message = response.message;
                  this.canceled = true
                  this.get_profile();
                  setTimeout(function(){ self.showSuccess=false; self.resetValues(); document.getElementById('close_pop').click() }, 3000);
   
                }else{
                  this.loading = false;
                  this.showSuccess = false;
                  this.showError = true;
                  this.errorMsg = response.message; 
                  this.message = ''
                  setTimeout(function(){self.resetValues(); document.getElementById('close_pop').click() }, 3000);
                }
             }
          );
       },
       resetValues(){
         this.loading = false;
         this.showSuccess = false;
         this.showError = false;
       }
     },
   }
</script>
<style scoped>
   .navbar-toggler-icon:after, .navbar-toggler-icon:before {
   background: #E9191F;
   }
   .navbar-toggler-icon {
   border-color: #E9191F;
   }
   a.my-account-logout.red-button:hover {
   color: #E9191F !important;
   }
</style>