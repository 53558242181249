import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };
const subscription = JSON.parse(localStorage.getItem('subscription'));
const postDetail='';
export const auth = {
  namespaced: true,
  state: {initialState: initialState ,subscription : subscription, postDetail : postDetail},

  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },

    forgotpassword({ commit }, data) {
      return AuthService.forgotpassword(data).then(
        response => {
          return response;
          //return Promise.resolve(response.data);
        },
        error => {
          return error;
          //return Promise.reject(error);
        }
      );
    },
    
    resetpassword({ commit }, data) {
      return AuthService.resetpassword(data).then(
        response => {
          return response;
          //return Promise.resolve(response.data);
        },
        error => {
          return error;
          //return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },

    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          if(response.status==true){
            commit('registerSuccess');
          }else{
            commit('registerFailure');
          }
          
          return response;
          //return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return error;
          //return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.initialState.status.loggedIn = true;
      state.subscription = JSON.parse(localStorage.getItem('subscription'));
      state.user = user;
    },
    loginFailure(state) {
      state.initialState.status.loggedIn = false;
      state.subscription = '';
      state.user = null;
    },
    logout(state) {
      state.initialState.status.loggedIn = false;
      state.subscription = '';
      state.user = null;
    },
    registerSuccess(state) {
      state.initialState.status.loggedIn = true;
      state.subscription = JSON.parse(localStorage.getItem('subscription'));
      state.user = user;
    },
    registerFailure(state) {
      state.initialState.status.loggedIn = false;
      state.subscription = '';
      state.user = null;
    },
    updateSubscriptionStatus(state, val) {
      state.subscription = val;
    },   
    postDetail(state, val) {
      state.postDetail = val;
    },
  }
};