<template>
   <div>
      <div class="pinspy-load" v-if = "loading"><span class="load"></span></div>
      <!-- header  -->
      <headerSection />
      <section class="help-wrap examples">
         <div class="help-center">
            <h1 class="help-header text-center">Examples</h1>
         </div>
         <div class="example-list">
            <div class="container">
               <div class="list-text purple-ls mb-4">
                  <div class="list-title">If you’re an Agency/Business : </div>
                  <ul class="list-ul">
                     <li class="d-flex justify-content-between align-items-center">
                        <span></span>
                        <div class="list-cont">Search for rival names. Here we use Target, but you can use anything else.</div>
                        <a href="#">
                           <div class="explain-btn"><img :src="require('@/assets/images/white-arrow.svg')" /></div>
                        </a>
                     </li>
                     <li class="d-flex justify-content-between align-items-center">
                        <span></span>
                        <div class="list-cont">Look for a product/name in the landing page URL. </div>
                        <a href="#">
                           <div class="explain-btn"><img :src="require('@/assets/images/white-arrow.svg')" /></div>
                        </a>
                     </li>
                     <li class="d-flex justify-content-between align-items-center">
                        <span></span>
                        <div class="list-cont">Look for other big brands, who often outsource their ads. </div>
                        <a href="#">
                           <div class="explain-btn"><img :src="require('@/assets/images/white-arrow.svg')"/></div>
                        </a>
                     </li>
                  </ul>
               </div>
               <div class="list-text blue-ls mb-4">
                  <div class="list-title">2. If you have an E-commerce store : </div>
                  <ul class="list-ul">
                     <li class="d-flex justify-content-between align-items-center">
                        <span></span>
                        <div class="list-cont">Select Shopify as technology used and find the best performing pins that lead to Shopify stores.</div>
                        <a href="#">
                           <div class="explain-btn"><img :src="require('@/assets/images/white-arrow.svg')"/></div>
                        </a>
                     </li>
                     <li class="d-flex justify-content-between align-items-center">
                        <span></span>
                        <div class="list-cont">Filter by last week only and find new hot winning products to sell on your store. </div>
                        <a href="#">
                           <div class="explain-btn"><img :src="require('@/assets/images/white-arrow.svg')"/></div>
                        </a>
                     </li>
                  </ul>
               </div>
               <div class="list-text green-ls mb-4">
                  <div class="list-title">3. If you’re an affiliate : </div>
                  <ul class="list-ul">
                     <li class="d-flex justify-content-between align-items-center">
                        <span></span>
                        <div class="list-cont">Search by 'Any' affiliate network and add a country see what's being promoted in your target region.</div>
                        <a href="#">
                           <div class="explain-btn"><img :src="require('@/assets/images/white-arrow.svg')"/></div>
                        </a>
                     </li>
                     <li class="d-flex justify-content-between align-items-center">
                        <span></span>
                        <div class="list-cont">Search for words used in the ad copy similar to what you are selling. </div>
                        <a href="#">
                           <div class="explain-btn"><img :src="require('@/assets/images/white-arrow.svg')"/></div>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="find-ads text-center">
            <h2 class="title-btm ">Can't find the ads?</h2>
            <p>If you can't find the ads</p>
            <router-link type="button" :to="{ path: 'pinspy.com/help-center/' }" target="_blank" class="theme-btn button-white-outline">Email US</router-link>
         </div>
      </section>
      <footerSection />
   </div>
</template>
<script>
   import headerSection from "./header/header.vue";
   import footerSection from "./footer/footer.vue";
   export default {
     components: {
           headerSection,
           footerSection
     },
     data() {
       return { 
       }
     },
     computed: {
      
     },
     created() {
        localStorage.removeItem("filters");
     },
     methods: {
          
     }
   }
</script>
<style scoped>
</style>